import addOrEditQuestionnaireStyles from "./addOrEditQuestionnaire.module.css";
import styles from "../forms.module.css";
import formStyles from "../forms.module.css";
import Select, { ActionMeta, SingleValue } from "react-select";
import {
  addOrEditQuestionnaireInitialState,
  statusOptions,
} from "../../../utils/constants";
import { TLabeledItem, TQuestionnaireTypeOption } from "../../../types/types";
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { IQuestionnaireData } from "../../../services/types/utils.types";
import { useAddOrEditQuestionnaireMutation } from "../../../services/api/utils.api";
import { useAppSelector } from "../../../services/hooks";
import { adminSelector } from "../../../services/selectors/user.selectors";
import Modal from "../../modals/modal/Modal";
import GeneralSuccessPopup from "../../modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../../modals/errorPopup/errorPopup";
import QFactorsPopup from "../../modals/qFactors/qFactorsPopup";
import { useNavigate } from "react-router-dom";
import useAutosizeTextArea from "../../../hooks/useAutosizeTextarea";

interface IAddOrEditQuestionnaire {
  questionnaire: IQuestionnaireData | undefined;
}
function AddOrEditQuestionnaireForm({
  questionnaire,
}: IAddOrEditQuestionnaire) {
  const navigate = useNavigate();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const admin = useAppSelector(adminSelector);

  const [form, setForm] = useState(addOrEditQuestionnaireInitialState);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [openQFactorsModal, setOpenQFactorsModal] = useState(false);
  const [touched, setTouched] = useState(false);

  const [editQuestionnaire, { isLoading }] =
    useAddOrEditQuestionnaireMutation();

  useAutosizeTextArea(textAreaRef.current, form.startQuestion || "");

  // Update form state with values from questionnaire
  useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      ...questionnaire, // This will update all the corresponding fields
    }));
  }, [questionnaire]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTouched(true);
      const { id, value } = e.target;
      setForm((prev) => ({ ...prev, [id]: value }));
    },
    []
  );

  const onSelectChange = useCallback(
    (
      value: SingleValue<TLabeledItem | TQuestionnaireTypeOption>,
      { name }: ActionMeta<TLabeledItem>
    ) => {
      setTouched(true);
      if (name && value) setForm((prev) => ({ ...prev, [name]: value.value }));
    },
    []
  );

  const isFormValid = () => {
    // Check if required fields are filled
    return form.desc && form.symbol;
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    editQuestionnaire({
      companyId: admin.companyId,
      body: { ...form, companyId: admin.companyId },
    })
      .then((res) => {
        if (res && "data" in res) {
          // @ts-ignore
          if (res.data?.success === "false") {
            console.log(res.data);
          }
          setOpenModalSuccess(true);
          setTouched(false);
        }
      })
      .catch((err) => {
        setOpenModalError(true);
        console.log(err);
      });
  };

  return (
    <form className={styles.form}>
      <ul
        className={`${styles.form__list} ${addOrEditQuestionnaireStyles.form__list_four_columns} ${addOrEditQuestionnaireStyles.form__list}`}
      >
        <li>
          <label htmlFor="desc" className={styles.form__label}>
            שם שאלון
          </label>
          <input
            type="text"
            className={`${formStyles.form__input}`}
            name="desc"
            id="desc"
            value={form.desc}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="symbol" className={styles.form__label}>
            סימן
          </label>
          <input
            type="text"
            className={`${formStyles.form__input}`}
            name="symbol"
            id="symbol"
            value={form.symbol}
            onChange={handleChange}
          />
        </li>
        <li>
          <label
            htmlFor="factorQuestionnaire"
            className={`${styles.form__label} ${addOrEditQuestionnaireStyles.label}`}
          >
            משוקלת RRM
            <p
              className={addOrEditQuestionnaireStyles.form__link}
              onClick={() => {
                setOpenQFactorsModal(true);
              }}
            >
              משקולות שאלונים
            </p>
          </label>
          <input
            className={`${formStyles.form__input}`}
            name="factorQuestionnaire"
            id="factorQuestionnaire"
            type={"number"}
            value={form.factorQuestionnaire}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="statusId" className={styles.form__label}>
            סטטוס
          </label>
          <Select
            name={"statusId"}
            options={statusOptions}
            value={
              statusOptions.find(
                (o) => o.value === form?.statusId?.toString()
              ) || undefined
            }
            placeholder={"אנא בחרסוג סטטוס"}
            className={styles.select}
            onChange={onSelectChange}
          />
        </li>
        <li>
          <label htmlFor="startQuestion" className={styles.form__label}>
            שאלה ראשונה
          </label>
          <textarea
            ref={textAreaRef}
            rows={1}
            className={`${styles.form__input} ${styles.form__textArea}`}
            name="startQuestion"
            id="startQuestion"
            value={form.startQuestion || ""}
            onChange={handleChange}
          />
        </li>
      </ul>
      <button
        type={"submit"}
        className={`button ${styles.form__btn} ${
          (isLoading || !touched || !isFormValid()) && styles.form__btn_disabled
        } `}
        disabled={isLoading || !touched || !isFormValid()}
        onClick={onSubmit}
      >
        {isLoading ? "שליחה" : questionnaire ? "לערוך שאלון" : "ליצור שאלון"}
      </button>
      {openQFactorsModal && (
        <Modal onClose={() => setOpenQFactorsModal(false)}>
          <QFactorsPopup onClose={() => setOpenQFactorsModal(false)} />
        </Modal>
      )}
      {openModalSuccess && (
        <Modal
          onClose={() => {
            setOpenModalSuccess(false);
            navigate(-1);
          }}
        >
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModalSuccess(false);
              navigate(-1);
            }}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
    </form>
  );
}

export default AddOrEditQuestionnaireForm;
