import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "../api/auth.api";
import { RootState } from "../types";
import { IAdmin, IAdminState } from "../types/auth.types";
import { deleteCookie, getCookie, setCookie } from "../../utils/token";
import { userApi } from "../api/user.api";

const accessToken = getCookie("accessToken");
const refreshToken = getCookie("refreshToken");

const initialUser: IAdmin = {
  id: "",
  firstName: "",
  lastName: "",
  fullName: "",
  account: "",
  avatar: "",
  email: "",
  // users: [],
  // hosenUsers: [],
  // hosenUsersWithRecommendations: [],
  // therapists: [],
  companyId: 0,
};

const initialState: IAdminState = {
  admin: initialUser,
  accessToken: accessToken,
  refreshToken: refreshToken,
  error: null,
  isAuth: false,
};

const storeUser = (
  state: IAdminState,
  { payload }: { payload: { user: IAdmin } }
) => {
  for (let key in payload.user) {
    state.admin[key.charAt(0).toLowerCase() + key.slice(1)] = payload.user[key];
  }
};
const storeTokens = (state: IAdminState, { payload }: any) => {
  const { accessToken, refreshToken, user } = payload;
  if (!accessToken) {
    return;
  }
  state.accessToken = accessToken;
  state.refreshToken = refreshToken;
  if (user) {
    storeUser(state, { payload });
  }
  state.error = payload.success ? null : payload.message;
  state.isAuth = payload.success ? payload.success : false;
  if (state.accessToken) {
    setCookie("accessToken", state.accessToken, { expires: 5000 });
  }
  if (state.refreshToken) {
    setCookie("refreshToken", state.refreshToken);
  }
};

const storeErrors = (state: IAdminState, { payload }: any) => {
  const { data } = payload;
  state.error =
    typeof data?.message === "string"
      ? data?.message
      : "משהו השתבש, אנא נסה שוב מאוחר יותר";
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setPhone: (state, { payload }) => {
      return { ...state, user: { ...state.admin, phone: payload } };
    },
    logout: () => {
      setCookie("accessToken", "");
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, storeTokens)
      .addMatcher(authApi.endpoints.login.matchRejected, storeErrors)
      .addMatcher(authApi.endpoints.loginGoogle.matchFulfilled, storeTokens)
      .addMatcher(authApi.endpoints.loginGoogle.matchRejected, storeErrors)
      .addMatcher(
        authApi.endpoints.loginWithCode.matchFulfilled,
        (state, action) => {
          state.error = action.payload.success ? null : action.payload.message;
        }
      )
      .addMatcher(authApi.endpoints.loginWithCode.matchRejected, storeErrors)
      .addMatcher(authApi.endpoints.sendCode.matchFulfilled, storeTokens)
      .addMatcher(authApi.endpoints.sendCode.matchRejected, storeErrors)
      .addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, action) => {
        storeUser(state, action);
        state.error = action.payload.success ? null : action.payload.message;
        state.isAuth = true;
      })
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state, action) => {
        deleteCookie("refreshToken");
        deleteCookie("accessToken");
        state.admin = initialUser;
        state.accessToken = "";
        state.refreshToken = "";
        state.error = action.payload.success ? null : action.payload.message;
        state.isAuth = false;
      });
    // .addMatcher(
    //   userApi.endpoints.getAllUsersWithRecommendations.matchFulfilled,
    //   (
    //     state: IAdminState,
    //     { payload }: { payload: { Users: TBaseUser[] } }
    //   ) => {
    //     let allUsers = payload.Users.map((user) =>
    //       Object.keys(user).reduce((acc, key) => {
    //         // Cast 'key' to keyof TBaseUser to satisfy TypeScript
    //         const lowerCaseKey = (key.charAt(0).toLowerCase() +
    //           key.slice(1)) as keyof TBaseUser;
    //         // @ts-ignore
    //         acc[lowerCaseKey] = user[key as keyof TBaseUser];
    //         return acc;
    //       }, {} as TBaseUser)
    //     );
    //     const users = allUsers.filter((u) => u.roleId === 1);
    //     const hosenUsers = users.filter((u) => u.fakeCompanyId);
    //     const hosenUsersWithRecommendations = hosenUsers.filter(
    //       (u) => u.recommends
    //     );
    //     const nonHosenUsers = users.filter((u) => !u.fakeCompanyId);
    //     const therapists = allUsers.filter((u) => u.roleId === 2);
    //     state.admin.users = nonHosenUsers;
    //     state.admin.hosenUsers = hosenUsers;
    //     state.admin.hosenUsersWithRecommendations =
    //       hosenUsersWithRecommendations;
    //     state.admin.therapists = therapists;
    //   }
    // );
    // .addMatcher(userApi.endpoints.updateUser.matchRejected, storeErrors);
  },
});

export const { actions, reducer } = userSlice;
export default userSlice.reducer;
export const selectUser = (state: RootState) => state.user;
