import styles from "./addExerciseToUser.module.css";
import BackArrowButton from "../backArrowButton/backArrowButton";
import Select from "react-select";
import { useEffect, useState } from "react";
import { TExercise } from "../../utils/constants";
import { useGetExercisesMutation } from "../../services/api/utils.api";
import { useAppSelector } from "../../services/hooks";
import { adminSelector } from "../../services/selectors/user.selectors";
import {
  exercisesCategoriesSelectOptionsSelector,
  exercisesCategoriesSelector,
  exercisesSelector,
} from "../../services/selectors/utils.selectors";
import { TLabeledItemWithId } from "../../types/types";

function AddExerciseToUser({ onClose }: { onClose: () => void }) {
  const admin = useAppSelector(adminSelector);
  const exercises = useAppSelector(exercisesSelector);
  const exercisesCategoriesSelectOptions: TLabeledItemWithId[] = useAppSelector(
    exercisesCategoriesSelectOptionsSelector
  );

  const [chosen, setChosen] = useState<TExercise[]>([]);
  const [getExercises] = useGetExercisesMutation();

  useEffect(() => {
    if (admin) {
      getExercises({ companyId: admin.companyId });
    }
  }, [admin]);

  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        position={"center"}
        onClick={onClose}
      />
      <h3 className={`title`}>תרגילים</h3>
      <div className={styles.header}>
        <Select
          placeholder={"סנן לפי..."}
          options={exercisesCategoriesSelectOptions}
          // defaultValue={exerciseCategories[0]}
        />
        <input type={"text"} className={`input`} placeholder={"חיפוש"} />
      </div>
      <div className={styles.exercises}>
        <ul className={`${styles.list} ${styles.list_all}`}>
          {exercises.map((e) => (
            <li>{e.title}</li>
          ))}
        </ul>
        <ul className={`${styles.list} ${styles.list_chosen}`}>
          {chosen.length > 0
            ? chosen.map((c) => <li>{c?.title}</li>)
            : "יש לגרור לכאן תרגילים מתאימים"}
        </ul>
      </div>
    </section>
  );
}

export default AddExerciseToUser;
