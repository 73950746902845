import { api } from "./api";
import {
  IAppointment,
  IAppointmentsProgram,
  IAppointmentsProgramsMetaData,
  ICompanyGroupsRRM,
  ICompanyGroupsRRMHeaders,
  IQuestion,
  IQuestionnaireCompanies,
  IQuestionnaireCompaniesHeaders,
  IQuestionnaireCompaniesTypes,
  IQuestionnaireData,
  TAppointmentsProgramsData,
  TAppointmentsProgramsTherapist,
  TGeneralRRM,
  TGroup,
  TQuestionOption,
} from "../types/utils.types";
import { TExercise, TExercisesCategory } from "../../utils/constants";

export const utilsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getQuestionnaires: build.mutation<
      IQuestionnaireData[],
      { companyId: number }
    >({
      query: ({ companyId }) => ({
        url: `Admintech/Admin_Questionnaire_DML/1/${companyId}`,
        method: "POST",
        body: { questionnaire: true },
      }),
    }),
    addOrEditQuestionnaire: build.mutation<
      IQuestionnaireData[],
      { companyId: number; body: Partial<IQuestionnaireData> }
    >({
      query: ({ companyId, body }) => ({
        url: `Admintech/Admin_Questionnaire_DML/2/${companyId}`,
        method: "POST",
        body,
      }),
    }),
    deleteQuestionnaire: build.mutation<
      IQuestionnaireData[],
      { companyId: number; questionnaire: IQuestionnaireData }
    >({
      query: ({ companyId, questionnaire }) => ({
        url: `Admintech/Admin_Questionnaire_DML/3/${companyId}`,
        method: "POST",
        body: questionnaire,
      }),
    }),
    getQuestions: build.mutation<IQuestion[], { questionnaireId: string }>({
      query: ({ questionnaireId }) => ({
        url: `Admintech/Admin_Questions_DML/1/${questionnaireId}`,
        method: "POST",
        body: [],
      }),
    }),
    addOrEditQuestions: build.mutation<
      IQuestion[],
      { questionnaireId: string; questions: Partial<IQuestion>[] }
    >({
      query: ({ questionnaireId, questions }) => ({
        url: `Admintech/Admin_Questions_DML/2/${questionnaireId}`,
        method: "POST",
        body: questions,
      }),
    }),
    deleteQuestions: build.mutation<
      IQuestion[],
      { questionnaireId: string | number; question: IQuestion }
    >({
      query: ({ questionnaireId, question }) => ({
        url: `Admintech/Admin_Questions_DML/3/${questionnaireId}`,
        method: "POST",
        body: [question],
      }),
    }),
    getQuestionsOptions: build.mutation<
      TQuestionOption[],
      { questionsId: number | string }
    >({
      query: ({ questionsId }) => ({
        url: `Admintech/Admin_QuestionsOptions_DML/1/${questionsId}`,
        method: "POST",
        body: [],
      }),
    }),
    addOrEditQuestionsOptions: build.mutation<
      TQuestionOption[],
      { questionsId: number | string; options: TQuestionOption[] }
    >({
      query: ({ questionsId, options }) => ({
        url: `Admintech/Admin_QuestionsOptions_DML/2/${questionsId}`,
        method: "POST",
        body: options,
      }),
    }),
    deleteQuestionsOptions: build.mutation<
      TQuestionOption[],
      { questionsId: number | string; option: TQuestionOption }
    >({
      query: ({ questionsId, option }) => ({
        url: `Admintech/Admin_QuestionsOptions_DML/3/${questionsId}`,
        method: "POST",
        body: [option],
      }),
    }),
    getQuestionsGroups: build.mutation<
      TGroup[],
      { questionsId: number | string }
    >({
      query: ({ questionsId }) => ({
        url: `Admintech/Admin_Groups_DML/1/${questionsId}`,
        method: "POST",
        body: [],
      }),
    }),
    addOrEditQuestionsGroups: build.mutation<
      TGroup[],
      { questionsId: string | number; groups: TGroup[] }
    >({
      query: ({ questionsId, groups }) => ({
        url: `Admintech/Admin_Groups_DML/2/${questionsId}`,
        method: "POST",
        body: groups,
      }),
    }),
    deleteQuestionsGroups: build.mutation<
      TGroup[],
      { questionsId: string | number; group: TGroup }
    >({
      query: ({ questionsId, group }) => ({
        url: `Admintech/Admin_Groups_DML/3/${questionsId}`,
        method: "POST",
        body: [group],
      }),
    }),
    getCompanyGroupsRRMHeaders: build.mutation<
      ICompanyGroupsRRMHeaders[],
      { companyId: number }
    >({
      query: ({ companyId }) => ({
        url: `Admintech/Admin_CompanyGroupsRRMHeaders_DML/1/${companyId}`,
        method: "POST",
        body: {},
      }),
    }),
    addOrEditCompanyGroupsRRMHeaders: build.mutation<
      ICompanyGroupsRRMHeaders[],
      { companyId: string | number; RRMHeaders: ICompanyGroupsRRMHeaders }
    >({
      query: ({ companyId, RRMHeaders }) => ({
        url: `Admintech/Admin_CompanyGroupsRRMHeaders_DML/2/${companyId}`,
        method: "POST",
        body: RRMHeaders,
      }),
    }),
    deleteCompanyGroupsRRMHeaders: build.mutation<
      ICompanyGroupsRRMHeaders[],
      { companyId: string | number; RRMHeader: ICompanyGroupsRRMHeaders }
    >({
      query: ({ companyId, RRMHeader }) => ({
        url: `Admintech/Admin_CompanyGroupsRRMHeaders_DML/3/${companyId}`,
        method: "POST",
        body: RRMHeader,
      }),
    }),
    getCompanyGroupsRRM: build.mutation<
      ICompanyGroupsRRM[],
      { companyGroupsRRMHeadersId: number }
    >({
      query: ({ companyGroupsRRMHeadersId }) => ({
        url: `Admintech/Admin_CompanyGroupsRRM_DML/1/${companyGroupsRRMHeadersId}`,
        method: "POST",
        body: [{ id: companyGroupsRRMHeadersId }],
      }),
    }),
    addOrEditCompanyGroupsRRM: build.mutation<
      ICompanyGroupsRRM[],
      {
        companyGroupsRRMHeadersId: string | number;
        RRMGroup: ICompanyGroupsRRM[];
      }
    >({
      query: ({ companyGroupsRRMHeadersId, RRMGroup }) => ({
        url: `Admintech/Admin_CompanyGroupsRRM_DML/2/${companyGroupsRRMHeadersId}`,
        method: "POST",
        body: RRMGroup,
      }),
    }),
    deleteCompanyGroupsRRM: build.mutation<
      ICompanyGroupsRRM[],
      {
        companyGroupsRRMHeadersId: string | number;
        RRMGroupsId: string | number;
      }
    >({
      query: ({ companyGroupsRRMHeadersId, RRMGroupsId }) => ({
        url: `Admintech/Admin_CompanyGroupsRRM_DML/3/${companyGroupsRRMHeadersId}`,
        method: "POST",
        body: [{ id: RRMGroupsId }],
      }),
    }),
    getCompanyGeneralRRM: build.mutation<TGeneralRRM[], { companyId: number }>({
      query: ({ companyId }) => ({
        url: `Admintech/Admin_CompanyGeneralRRM_DML/1/${companyId}`,
        method: "POST",
        body: [{ id: companyId }],
      }),
    }),
    addOrEditCompanyGeneralRRM: build.mutation<
      TGeneralRRM[],
      {
        companyId: string | number;
        rrm: TGeneralRRM[];
      }
    >({
      query: ({ companyId, rrm }) => ({
        url: `Admintech/Admin_CompanyGeneralRRM_DML/2/${companyId}`,
        method: "POST",
        body: rrm,
      }),
    }),
    deleteCompanyGeneralRRM: build.mutation<
      TGeneralRRM[],
      {
        companyId: string | number;
        RRMGroupsId: string | number;
      }
    >({
      query: ({ companyId, RRMGroupsId }) => ({
        url: `Admintech/Admin_CompanyGeneralRRM_DML/3/${companyId}`,
        method: "POST",
        body: [{ id: RRMGroupsId }],
      }),
    }),
    getMessages: build.mutation<ICompanyGroupsRRM[], { companyId: number }>({
      query: ({ companyId }) => ({
        url: `Admintech/Admin_Messages_DML/1/${companyId}`,
        method: "POST",
        body: {},
      }),
    }),
    addOrEditMessages: build.mutation<
      ICompanyGroupsRRM[],
      {
        companyId: number;
        RRMGroup: ICompanyGroupsRRM;
      }
    >({
      query: ({ companyId, RRMGroup }) => ({
        url: `Admintech/Admin_CompanyGroupsRRM_DML/2/${companyId}`,
        method: "POST",
        body: RRMGroup,
      }),
    }),
    deleteMessages: build.mutation<
      ICompanyGroupsRRM[],
      {
        companyId: number;
        messageId: string | number;
      }
    >({
      query: ({ companyId, messageId }) => ({
        url: `Admintech/Admin_CompanyGroupsRRM_DML/3/${companyId}`,
        method: "POST",
        body: [{ id: messageId }],
      }),
    }),
    getExercises: build.mutation<TExercise[], { companyId: number }>({
      query: ({ companyId }) => ({
        url: `Admintech/Admin_Exercises_DML/1/${companyId}`,
        method: "POST",
        body: {},
      }),
    }),
    addOrEditExercises: build.mutation<
      TExercise[],
      {
        companyId: number;
        exercise: TExercise;
      }
    >({
      query: ({ companyId, exercise }) => ({
        url: `Admintech/Admin_Exercises_DML/2/${companyId}`,
        method: "POST",
        body: exercise,
      }),
    }),
    deleteExercises: build.mutation<
      TExercise[],
      {
        companyId: number;
        exercise: TExercise;
      }
    >({
      query: ({ companyId, exercise }) => ({
        url: `Admintech/Admin_Exercises_DML/3/${companyId}`,
        method: "POST",
        body: exercise,
      }),
    }),
    getExercisesCategories: build.mutation<
      TExercisesCategory[],
      { companyId: number }
    >({
      query: ({ companyId }) => ({
        url: `Admintech/Admin_ExercisesCategories_DML/1/${companyId}`,
        method: "POST",
        body: {},
      }),
    }),
    addOrEditExercisesCategories: build.mutation<
      TExercisesCategory[],
      {
        companyId: number;
        category: TExercisesCategory;
      }
    >({
      query: ({ companyId, category }) => ({
        url: `Admintech/Admin_ExercisesCategories_DML/2/${companyId}`,
        method: "POST",
        body: category,
      }),
    }),
    deleteExercisesCategories: build.mutation<
      TExercisesCategory[],
      {
        companyId: number;
        categoryId: string | number;
      }
    >({
      query: ({ companyId, categoryId }) => ({
        url: `Admintech/Admin_ExercisesCategories_DML /3/${companyId}`,
        method: "POST",
        body: [{ id: categoryId }],
      }),
    }),
    getQuestionnaireCompaniesHeaders: build.mutation<
      //protocol headers
      IQuestionnaireCompaniesHeaders[],
      { companyId: number }
    >({
      query: ({ companyId }) => ({
        url: `Admintech/Admin_QuestionnaireCompaniesHeaders_DML/1/${companyId}`,
        method: "POST",
        body: {},
      }),
    }),
    addOrEditQuestionnaireCompaniesHeaders: build.mutation<
      IQuestionnaireCompaniesHeaders[],
      {
        companyId: number;
        body: IQuestionnaireCompaniesHeaders;
      }
    >({
      query: ({ companyId, body }) => ({
        url: `Admintech/Admin_QuestionnaireCompaniesHeaders_DML/2/${companyId}`,
        method: "POST",
        body,
      }),
    }),
    deleteQuestionnaireCompaniesHeaders: build.mutation<
      IQuestionnaireCompaniesHeaders[],
      {
        companyId: number;
        id: number;
      }
    >({
      query: ({ companyId, id }) => ({
        url: `Admintech/Admin_QuestionnaireCompaniesHeaders_DML/3/${companyId}`,
        method: "POST",
        body: { id },
      }),
    }),
    getQuestionnaireCompanies: build.mutation<
      //protocol components
      IQuestionnaireCompanies[],
      { headerId: number }
    >({
      query: ({ headerId }) => ({
        url: `Admintech/Admin_QuestionnaireCompanies_DML/1/${headerId}`,
        method: "POST",
        body: { id: headerId },
      }),
    }),
    addOrEditQuestionnaireCompanies: build.mutation<
      IQuestionnaireCompanies[],
      {
        headerId: number;
        body: IQuestionnaireCompanies;
      }
    >({
      query: ({ headerId, body }) => ({
        url: `Admintech/Admin_QuestionnaireCompanies_DML/2/${headerId}`,
        method: "POST",
        body,
      }),
    }),
    deleteQuestionnaireCompanies: build.mutation<
      IQuestionnaireCompanies[],
      {
        headerId: number;
        questionnaireCompanies: IQuestionnaireCompanies;
      }
    >({
      query: ({ headerId, questionnaireCompanies }) => ({
        url: `Admintech/Admin_QuestionnaireCompanies_DML/3/${headerId}`,
        method: "POST",
        body: questionnaireCompanies,
      }),
    }),
    getQuestionnaireCompaniesTypes: build.query<
      IQuestionnaireCompaniesTypes[],
      void
    >({
      query: () => ({
        url: `General/Gen_GetTableByTableName/QuestionnaireCompaniesTypes`,
        method: "Get",
        // body: { id },
      }),
    }),
    getAllMetaTables: build.query<IAppointmentsProgramsMetaData, number>({
      query: (companyId) => ({
        url: `Appointments/AppointmentsPrograms_GetAllMetaTables/${companyId}`,
        method: "GET",
      }),
    }),
    getAppointmentsProgramsByUserId: build.mutation<
      TAppointmentsProgramsData[],
      { companyId: number; usersId: number }
    >({
      query: (body) => ({
        url: `Appointments/AppointmentsPrograms_PostAppointments/1`,
        method: "POST",
        body: { ...body, name: "" },
      }),
    }),
    getCompanyAppointmentsPrograms: build.mutation<
      TAppointmentsProgramsData[],
      { companyId: number }
    >({
      query: (body) => ({
        url: `Appointments/AppointmentsPrograms_PostAppointments/1`,
        method: "POST",
        body: { ...body, name: "" },
      }),
    }),
    postAppointmentsPrograms: build.mutation<
      TAppointmentsProgramsData[],
      IAppointmentsProgram
    >({
      query: (body) => ({
        url: `Appointments/AppointmentsPrograms_PostAppointments/2`,
        method: "POST",
        body,
      }),
    }),
    deleteAppointmentsPrograms: build.mutation<
      TAppointmentsProgramsData[],
      IAppointmentsProgram
    >({
      query: (body) => ({
        url: `Appointments/AppointmentsPrograms_PostAppointments/3`,
        method: "POST",
        body,
      }),
    }),
    getAppointmentsProgramsTherapists: build.mutation<
      TAppointmentsProgramsTherapist[],
      Partial<IAppointmentsProgram>
    >({
      query: (body) => ({
        url: `Appointments/AppointmentsPrograms_PostAppointments/4`,
        method: "POST",
        body,
      }),
    }),
    getAppointmentsProgramsSchedule: build.mutation<
      IAppointment[],
      IAppointmentsProgram
    >({
      query: (body) => ({
        url: `Appointments/AppointmentsPrograms_PostAppointments/10`,
        method: "POST",
        body,
      }),
    }),
    deleteAppointment: build.mutation<
      IAppointment,
      Partial<IAppointmentsProgram>
    >({
      query: (body) => ({
        url: `Appointments/AppointmentsPrograms_PostAppointments/9`,
        method: "POST",
        body,
      }),
    }),
    getAllCombosForSearchUsers: build.query<IAppointment, number | string>({
      query: (companyId) => ({
        url: `user/GetAllCombosForSearchUsers/${companyId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetQuestionnairesMutation,
  useDeleteQuestionnaireMutation,
  useDeleteQuestionsMutation,
  useAddOrEditQuestionnaireMutation,
  useAddOrEditQuestionsMutation,
  useGetQuestionsMutation,
  useGetQuestionsOptionsMutation,
  useAddOrEditQuestionsOptionsMutation,
  useDeleteQuestionsOptionsMutation,
  useGetQuestionsGroupsMutation,
  useAddOrEditQuestionsGroupsMutation,
  useDeleteQuestionsGroupsMutation,
  useGetCompanyGroupsRRMHeadersMutation,
  useAddOrEditCompanyGroupsRRMHeadersMutation,
  useDeleteCompanyGroupsRRMHeadersMutation,
  useGetCompanyGroupsRRMMutation,
  useAddOrEditCompanyGroupsRRMMutation,
  useGetCompanyGeneralRRMMutation,
  useAddOrEditCompanyGeneralRRMMutation,
  useGetMessagesMutation,
  useAddOrEditMessagesMutation,
  useGetExercisesMutation,
  useAddOrEditExercisesMutation,
  useDeleteExercisesMutation,
  useGetExercisesCategoriesMutation,
  useAddOrEditExercisesCategoriesMutation,
  useGetQuestionnaireCompaniesHeadersMutation,
  useAddOrEditQuestionnaireCompaniesHeadersMutation,
  useDeleteQuestionnaireCompaniesHeadersMutation,
  useGetQuestionnaireCompaniesMutation,
  useAddOrEditQuestionnaireCompaniesMutation,
  useDeleteQuestionnaireCompaniesMutation,
  useGetQuestionnaireCompaniesTypesQuery,
  useLazyGetAllMetaTablesQuery,
  useGetAppointmentsProgramsByUserIdMutation,
  usePostAppointmentsProgramsMutation,
  useGetAppointmentsProgramsTherapistsMutation,
  useDeleteAppointmentsProgramsMutation,
  useGetAppointmentsProgramsScheduleMutation,
  useGetCompanyAppointmentsProgramsMutation,
  useDeleteAppointmentMutation,
  useLazyGetAllCombosForSearchUsersQuery,
} = utilsApi;
