import popupStyles from "./addRecommendation.module.css";
import React, { useState } from "react";
import { IUserReport } from "../../../services/types/users.types";

function AddRecommendationPopup({
  addMessageToScore,
}: {
  user: IUserReport;
  addMessageToScore: (form: { Title: string; Desc: string }) => void;
}) {
  // const [option, setOption] = useState<TOptionWithDesc | null>(null);
  const [showForm, setShowForm] = useState(true);
  const [form, setForm] = useState<{ Title: string; Desc: string }>({
    Title: "",
    Desc: "",
  });
  return (
    <article className={popupStyles.popup}>
      <h2 className={popupStyles.popup__title}>להוסיף המלצה</h2>
      {showForm && (
        <>
          <input
            type={"text"}
            id={"title"}
            name={"title"}
            placeholder={"הוסף כותרת"}
            className={`input ${popupStyles.input}`}
            value={form.Title}
            onChange={(e) => {
              setForm((prev) => ({ ...prev, Title: e.target.value }));
            }}
          />
          <textarea
            id={"recommendation"}
            name={"recommendation"}
            placeholder={"הוסף המלצה"}
            className={`input ${popupStyles.textarea}`}
            value={form.Desc}
            onChange={(e) => {
              setForm((prev) => ({ ...prev, Desc: e.target.value }));
            }}
          />
          <button
            type={"button"}
            className={` button ${popupStyles.popup__btn} hover`}
            onClick={() => {
              addMessageToScore(form);
            }}
          >
            שמירה
          </button>
        </>
      )}
      <button
        type={"button"}
        className={`button ${popupStyles.popup__btn} ${popupStyles.popup__nextBtn} hover`}
        onClick={() => {
          !showForm ? setShowForm(true) : addMessageToScore(form);
        }}
      >
        {showForm ? "שלח המלצה חדשה" : "המלצה חדשה"}
      </button>
    </article>
  );
}

export default AddRecommendationPopup;
