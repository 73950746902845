import { RootState } from "../types";

export const adminSelector = (state: RootState) => state.user.admin;
// export const usersSelector = (state: RootState) => state.user.admin.users;
// export const hosenUsersSelector = (state: RootState) =>
//   state.user.admin.hosenUsers;
// export const hosenUsersWithRecommendationsSelector = (state: RootState) =>
//   state.user.admin.hosenUsersWithRecommendations;
// export const therapistsSelector = (state: RootState) =>
//   state.user.admin.therapists;
