import styles from "./editQuestionnaire.module.css";
import questionnaireStyles from "../questionnaires/questionnaires.module.css";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import AddOrEditQuestionnaireForm from "../../components/forms/addOrEditQuestionnaire/addOrEditQuestionnaireForm";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../services/hooks";
import {
  questionnairesSelector,
  questionsSelector,
} from "../../services/selectors/utils.selectors";
import {
  useGetCompanyGroupsRRMHeadersMutation,
  useGetQuestionnairesMutation,
  useGetQuestionsMutation,
} from "../../services/api/utils.api";
import QuestionsList from "../../components/questionsList/questionsList";
import { IQuestionnaireData } from "../../services/types/utils.types";
import { adminSelector } from "../../services/selectors/user.selectors";
import Loader from "../../components/loader/loader";
import useMediaQuery from "../../hooks/useMediaQuery";

function EditQuestionnairePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 800px)");

  const admin = useAppSelector(adminSelector);
  const questions = useAppSelector(questionsSelector);
  const questionnaires = useAppSelector(questionnairesSelector);

  const [questionnaire, setQuestionnaire] = useState<
    IQuestionnaireData | undefined
  >(undefined);

  const [getQuestions] = useGetQuestionsMutation();
  const [getRRMHeaders] = useGetCompanyGroupsRRMHeadersMutation();
  const [getQuestionnaires, { isLoading }] = useGetQuestionnairesMutation();

  useEffect(() => {
    getQuestionnaires({ companyId: admin.companyId });
    id && getQuestions({ questionnaireId: id });
    getRRMHeaders({ companyId: admin.companyId });
  }, [getQuestionnaires, getQuestions, getRRMHeaders, id, admin.companyId]);

  useEffect(() => {
    setQuestionnaire(questionnaires.find((q) => q.id.toString() === id));
  }, [questionnaires, setQuestionnaire, id]);

  if (isLoading) return <Loader />;

  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        position={"center"}
        onClick={() => navigate(-1)}
      />
      <h1 className={styles.section__title}>
        {id ? "עריכת שאלון" : "הוספת שאלון חדש"}
      </h1>
      <AddOrEditQuestionnaireForm questionnaire={questionnaire} />
      {id && (
        <>
          <div className={styles.section__header}>
            <button
              type={"button"}
              className={questionnaireStyles.addBtn}
              onClick={() => navigate("rrmMain/edit")}
            >
              {!mobile && "הוסף שאלה"}
              <span className={questionnaireStyles.plus}>+</span>
            </button>
            <h2 className={styles.section__title}>כל השאלות</h2>
            <button
              type={"button"}
              className={questionnaireStyles.addBtn}
              onClick={() => navigate(`rrm`)}
            >
              {mobile ? "RRM" : "סדר RRM"}
            </button>
          </div>
          {questions.length ? (
            <QuestionsList questionsToShow={questions} />
          ) : (
            <p className={"text"}>לא נמצאו תוצאות</p>
          )}
        </>
      )}
    </section>
  );
}
export default EditQuestionnairePage;
