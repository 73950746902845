import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "../pages/login/login";
import LoginSmsCode from "../pages/login/loginSmsCode";
import ProtectedRoute from "../components/protectedRoute/ProtectedRoute";
import MainContentLayout from "../components/mainContentLayout/mainContentLayout";
import { Category } from "../types/types";
import UsersPage from "../pages/users/usersPage";
import LibraryPage from "../pages/library/library";
import HomePage from "../pages/home/home";
import ProtocolsMenuPage from "../pages/protocolsMenu/protocolsMenuPage";
import QuestionnairesPage from "../pages/questionnaires/questionnaires";
import EditQuestionnairePage from "../pages/editQuestionnaire/editQuestionnaire";
import RrmMainPage from "../pages/rrmMain/rrmMainPage";
import FeaturesPage from "../pages/features/features";
import AddNewFeaturePage from "../pages/addOrEditFeature/addNewFeature";
import EditFeaturePage from "../pages/addOrEditFeature/editFeature";
import EditDataPage from "../pages/editData/editDataPage";
import QuestionReportsPage from "../pages/questionReports/questionReportsPage";
import ActionReportPage from "../pages/actionReportPage/actionReportPage";
import AddOrEditQuestion from "../pages/addOrEditQuestion/addOrEditQuestion";
import AddOrEditOptions from "../pages/addOrEditOptions/addOrEditOptions";
import RrmQuestionsPage from "../pages/rrmQuestions/rrmQuestionsPage";
import TreatmentPlanPage from "../pages/treatmentPlan/treatmentPlanPage";
import ProtocolsPage from "../pages/protocols/protocols";
import NotFoundPage from "../pages/notFound/notFoundPage";
import EditPersonalDataPage from "../pages/editPersonalData/editPersonalDataPage";
import RrmGeneralPage from "../pages/rrmMain/rrmGeneralPage";
import SupportPage from "../pages/support/supportPage";

export const useRoutes = (
  isAuth: boolean,
  changeCategory: (
    e: React.MouseEvent<Element, MouseEvent>,
    cat: Category
  ) => void,
  fakeCompanyId: number | null
) => (
  <Routes>
    <Route path={"/login"} element={<LoginPage />} />
    <Route path={"login/enter_code"} element={<LoginSmsCode />} />
    {fakeCompanyId === null && (
      <>
        <Route
          path={"/"}
          element={
            <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
              <MainContentLayout
                activeCategory={Category.Main}
                changeCategory={changeCategory}
              >
                <HomePage />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/protocols"}
          element={
            <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
              <MainContentLayout
                activeCategory={Category.Protocols}
                changeCategory={changeCategory}
              >
                <ProtocolsMenuPage />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/protocols/protocols"}
          element={
            <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
              <MainContentLayout
                activeCategory={Category.Protocols}
                changeCategory={changeCategory}
              >
                <ProtocolsPage />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/protocols/questionnaires"}
          element={
            <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
              <MainContentLayout
                activeCategory={Category.Protocols}
                changeCategory={changeCategory}
              >
                <QuestionnairesPage />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/protocols/questionnaires/edit/:id?"}
          element={
            <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
              <MainContentLayout
                activeCategory={Category.Protocols}
                changeCategory={changeCategory}
              >
                <EditQuestionnairePage />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/protocols/questionnaires/edit/:qid/options/:id"}
          element={
            <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
              <MainContentLayout
                activeCategory={Category.Protocols}
                changeCategory={changeCategory}
              >
                <AddOrEditOptions />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/protocols/questionnaires/edit/:qid/rrmMain/edit/:id?"}
          element={
            <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
              <MainContentLayout
                activeCategory={Category.Protocols}
                changeCategory={changeCategory}
              >
                <AddOrEditQuestion />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/protocols/questionnaires/edit/:qid/questions/edit/:id?"}
          element={
            <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
              <MainContentLayout
                activeCategory={Category.Protocols}
                changeCategory={changeCategory}
              >
                <AddOrEditQuestion />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/protocols/questionnaires/edit/:qid/rrm"}
          element={
            <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
              <MainContentLayout
                activeCategory={Category.Protocols}
                changeCategory={changeCategory}
              >
                <RrmQuestionsPage />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/protocols/features"}
          element={
            <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
              <MainContentLayout
                activeCategory={Category.Protocols}
                changeCategory={changeCategory}
              >
                <FeaturesPage />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/protocols/features/add_new"}
          element={
            <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
              <MainContentLayout
                activeCategory={Category.Protocols}
                changeCategory={changeCategory}
              >
                <AddNewFeaturePage />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"/protocols/features/edit/:id"}
          element={
            <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
              <MainContentLayout
                activeCategory={Category.Protocols}
                changeCategory={changeCategory}
              >
                <EditFeaturePage />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        {/*<Route*/}
        {/*  path={"protocols/library"}*/}
        {/*  element={*/}
        {/*    <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>*/}
        {/*      <MainContentLayout*/}
        {/*        activeCategory={Category.Protocols}*/}
        {/*        changeCategory={changeCategory}*/}
        {/*      >*/}
        {/*        <LibraryPage />*/}
        {/*      </MainContentLayout>*/}
        {/*    </ProtectedRoute>*/}
        {/*  }*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path={"library"}*/}
        {/*  element={*/}
        {/*    <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>*/}
        {/*      <MainContentLayout*/}
        {/*        activeCategory={Category.Library}*/}
        {/*        changeCategory={changeCategory}*/}
        {/*      >*/}
        {/*        <LibraryPage />*/}
        {/*      </MainContentLayout>*/}
        {/*    </ProtectedRoute>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          path={"protocols/RRM"}
          element={
            <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
              <MainContentLayout
                activeCategory={Category.Protocols}
                changeCategory={changeCategory}
              >
                <RrmMainPage />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path={"protocols/generalRRM"}
          element={
            <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
              <MainContentLayout
                activeCategory={Category.Protocols}
                changeCategory={changeCategory}
              >
                <RrmGeneralPage />
              </MainContentLayout>
            </ProtectedRoute>
          }
        />
      </>
    )}
    <Route
      path={"/users"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <UsersPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/actions"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <EditDataPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/questions_reports"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <QuestionReportsPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/report/:cycle?/:questionnaire?"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <ActionReportPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/treatment_plan"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <TreatmentPlanPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/personal"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <EditPersonalDataPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"*"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <NotFoundPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/support"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/login`}>
          <MainContentLayout
            activeCategory={Category.Support}
            changeCategory={changeCategory}
          >
            <SupportPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
  </Routes>
);
