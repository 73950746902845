import styles from "./treatmentPlanPage.module.css";
import questionnaireStyles from "../questionnaires/questionnaires.module.css";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../services/hooks";
import { adminSelector } from "../../services/selectors/user.selectors";
import { usersSelector } from "../../services/selectors/users.selectors";
import AddTreatmentPlan from "../../components/addTreatmentPlan/addTreatmentPlan";
import UserInfo from "../../components/userInfo/userInfo";
import {
  useDeleteAppointmentsProgramsMutation,
  useGetAppointmentsProgramsByUserIdMutation,
  useLazyGetAllMetaTablesQuery,
} from "../../services/api/utils.api";
import {
  appointmentsProgramsDataSelector,
  userAppointmentsProgramsSelector,
} from "../../services/selectors/utils.selectors";
import useMediaQuery from "../../hooks/useMediaQuery";
import { TAppointmentsProgramsData } from "../../services/types/utils.types";
import Modal from "../../components/modals/modal/Modal";
import DeleteConfirmation from "../../components/modals/deleteConfirmation/deleteConfirmation";
import GeneralSuccessPopup from "../../components/modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../../components/modals/errorPopup/errorPopup";
import AppointmentsProgramSchedulePopup from "../../components/modals/appointmentsProgramSchedulePopup/appointmentsProgramSchedulePopup";
import { IUserReport } from "../../services/types/users.types";

function TreatmentPlanPage() {
  const navigate = useNavigate();

  const { id } = useParams();
  const mobile = useMediaQuery("(max-width: 800px)");

  const admin = useAppSelector(adminSelector);
  const users = useAppSelector(usersSelector);
  const appointmentsProgramsData = useAppSelector(
    appointmentsProgramsDataSelector
  );
  const userAppointmentsPrograms = useAppSelector(
    userAppointmentsProgramsSelector
  );

  const [user, setUser] = useState<IUserReport | undefined>(undefined);
  // const [showAddPlanList, setShowAddPlanList] = useState(false);
  const [toggle, setToggle] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [programToEdit, setProgramToEdit] = useState<
    TAppointmentsProgramsData | undefined
  >(undefined);
  const [showAddOrEditProgramForm, setShowAddOrEditProgramForm] =
    useState(false);
  const [programToDelete, setProgramToDelete] = useState<
    TAppointmentsProgramsData | undefined
  >(undefined);

  const [getAllMetaTables] = useLazyGetAllMetaTablesQuery();
  const [getAppointmentsPrograms] =
    useGetAppointmentsProgramsByUserIdMutation();
  const [deleteProgram] = useDeleteAppointmentsProgramsMutation();

  console.log(appointmentsProgramsData, userAppointmentsPrograms);

  useEffect(() => {
    if (admin) {
      getAllMetaTables(admin.companyId);
      id &&
        getAppointmentsPrograms({
          companyId: admin.companyId,
          usersId: +id,
        });
    }
  }, [getAllMetaTables, admin, getAppointmentsPrograms, id]);

  useEffect(() => {
    const theUser = users.find((u: IUserReport) => u.Id.toString() === id);
    theUser && setUser(theUser);
  }, [users, id]);

  const toggleAnswer = (id: number) => {
    setToggle({
      ...toggle,
      [id]: !toggle[id as keyof typeof setToggle],
    });
  };

  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        onClick={() => navigate(-1)}
        position={"center"}
      />
      <h3 className={`title`}>תוכנית טיפול</h3>
      {user && (
        <div className={styles.userInfoContainer}>
          <UserInfo user={user} />
        </div>
      )}
      {user ? (
        showAddOrEditProgramForm ? (
          <AddTreatmentPlan
            onClose={() => {
              setShowAddOrEditProgramForm(false);
              setProgramToEdit(undefined);
            }}
            user={user}
            program={programToEdit}
          />
        ) : (
          <>
            <button
              type={"button"}
              className={`${questionnaireStyles.addBtn}`}
              onClick={() => {
                setProgramToEdit(undefined);
                setShowAddOrEditProgramForm(true);
              }}
            >
              {"הוסף טיפול חדש"}
              <span className={questionnaireStyles.plus}>+</span>
            </button>
            {userAppointmentsPrograms.length > 0 ? (
              mobile ? (
                <ul className={questionnaireStyles.list}>
                  {userAppointmentsPrograms.map((p, index) => (
                    <li
                      key={p.appointmentsProgram.id}
                      className={`listRow_mobile ${
                        toggle[index as keyof typeof setToggle] &&
                        "listRow_mobile_long"
                      }`}
                      onClick={() => toggleAnswer(index)}
                    >
                      <p className={questionnaireStyles.listCell}>
                        מספר:
                        <span className={questionnaireStyles.listValue}>
                          {p.appointmentsProgram.id}
                        </span>
                      </p>
                      <p className={questionnaireStyles.listCell}>
                        שם:
                        <span className={questionnaireStyles.listValue}>
                          {p.appointmentsProgram.name}
                        </span>
                      </p>
                      <p className={questionnaireStyles.listCell}>
                        מספר מפגשים:
                        <span className={questionnaireStyles.listValue}>
                          {p.appointmentsProgram.countOfAppointments}
                        </span>
                      </p>
                      <p className={questionnaireStyles.listCell}>
                        סטטוס:
                        <span className={questionnaireStyles.listValue}>
                          {appointmentsProgramsData.AppointmentsProgramsStatus.find(
                            (s) => s.Id === p.appointmentsProgram.statusId
                          )?.label || p.appointmentsProgram.statusId}
                        </span>
                      </p>
                      <p className={questionnaireStyles.listCell}>אפשרויות:</p>
                      <div className={questionnaireStyles.listCell}>
                        <button
                          type={"button"}
                          className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_edit}`}
                          onClick={() => {
                            setProgramToEdit(p);
                            setShowAddOrEditProgramForm(true);
                          }}
                        />
                        <button
                          type={"button"}
                          className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_schedule}`}
                          onClick={() => {
                            setProgramToEdit(p);
                            setShowScheduleModal(true);
                          }}
                        />
                        <button
                          type={"button"}
                          className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_delete}`}
                          onClick={() => {
                            setProgramToDelete(p);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <ul className={questionnaireStyles.list}>
                  <li className={`${styles.listRow} ${styles.listRow_header}`}>
                    <p className={questionnaireStyles.listCell}>מספר</p>
                    <p className={questionnaireStyles.listCell}>שם</p>
                    <p className={questionnaireStyles.listCell}>מספר מפגשים</p>
                    <p className={questionnaireStyles.listCell}>משך מפגש</p>
                    <p className={questionnaireStyles.listCell}>תדירות</p>
                    <p className={questionnaireStyles.listCell}>
                      שיוך תומך/ת טיפול
                    </p>
                    <p className={questionnaireStyles.listCell}>סטטוס</p>
                    <p className={questionnaireStyles.listCell}>אפשרויות</p>
                  </li>
                  {userAppointmentsPrograms.map((p, index) => (
                    <li
                      key={p.appointmentsProgram?.id}
                      className={`${styles.listRow} ${
                        index % 2 === 0 && questionnaireStyles.listRow_colored
                      }`}
                    >
                      <p className={questionnaireStyles.listCell}>
                        {p.appointmentsProgram?.id}
                      </p>
                      <p className={questionnaireStyles.listCell}>
                        {p.appointmentsProgram.name}
                      </p>
                      <p className={questionnaireStyles.listCell}>
                        {p.appointmentsProgram.countOfAppointments}
                      </p>
                      <p className={questionnaireStyles.listCell}>
                        {appointmentsProgramsData.AppointmentsDurations.find(
                          (a) =>
                            a.Id ===
                            p.appointmentsProgram.appointmentsDurationsId
                        )?.Minutes || "-"}
                      </p>
                      <p className={questionnaireStyles.listCell}>
                        {appointmentsProgramsData.AppointmentsSequence.find(
                          (a) =>
                            a.Id ===
                            p.appointmentsProgram.appointmentsDurationsId
                        )?.label || "-"}
                      </p>
                      <p className={questionnaireStyles.listCell}>
                        {appointmentsProgramsData.Roles.find(
                          (r) => r.Id === p.appointmentsProgram.roleId
                        )?.label || p.appointmentsProgram.roleId}
                      </p>
                      <p className={questionnaireStyles.listCell}>
                        {appointmentsProgramsData.AppointmentsProgramsStatus.find(
                          (s) => s.Id === p.appointmentsProgram.statusId
                        )?.label || p.appointmentsProgram.statusId}
                      </p>
                      <div className={questionnaireStyles.listCell}>
                        <button
                          type={"button"}
                          className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_edit}`}
                          onClick={() => {
                            setProgramToEdit(p);
                            setShowAddOrEditProgramForm(true);
                          }}
                        />
                        <button
                          type={"button"}
                          className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_schedule}`}
                          onClick={() => {
                            setProgramToEdit(p);
                            setShowScheduleModal(true);
                          }}
                        />

                        <button
                          type={"button"}
                          disabled={p.appointmentsProgram.statusId !== 1}
                          className={`${questionnaireStyles.btn} ${
                            questionnaireStyles.btn_type_delete
                          } ${
                            p.appointmentsProgram.statusId !== 1 &&
                            "button_disabled"
                          }`}
                          onClick={() => {
                            setProgramToDelete(p);
                            setOpenModal(true);
                          }}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              )
            ) : (
              <p className={questionnaireStyles.text}>לא נמצאו תוצאות</p>
            )}
          </>
        )
      ) : (
        <p className={`title`}>משתמש עם מזהה {id} לא נמצא</p>
      )}
      {openModal && programToDelete && (
        <Modal
          onClose={() => {
            setOpenModal(false);
            setProgramToDelete(undefined);
          }}
        >
          <DeleteConfirmation
            text={`האם את/ה בטוח/ה שברצונך למחוק את התוכנית: ${programToDelete.appointmentsProgram.name}?`}
            onDelete={() => {
              deleteProgram(programToDelete.appointmentsProgram)
                .then((res) => {
                  if (res && "data" in res) {
                    setOpenModalSuccess(true);
                  }
                })
                .catch((err) => {
                  setOpenModalError(true);
                  console.log(err);
                });
            }}
            onClose={() => {
              setOpenModal(false);
              setProgramToDelete(undefined);
            }}
          />
        </Modal>
      )}
      {openModalSuccess && (
        <Modal onClose={() => setOpenModalSuccess(false)}>
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModalSuccess(false);
            }}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
      {showScheduleModal && programToEdit && (
        <Modal
          wide={true}
          onClose={() => {
            setShowScheduleModal(false);
            setProgramToEdit(undefined);
          }}
        >
          <AppointmentsProgramSchedulePopup program={programToEdit} />
        </Modal>
      )}
    </section>
  );
}
export default TreatmentPlanPage;
