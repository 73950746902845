import styles from "../forms.module.css";
import { useFormik } from "formik";
import { addNewUserValidationSchema } from "../../../utils/validation";
import React, { useEffect, useState } from "react";
import { useGetAllGendersQuery } from "../../../services/api/user.api";
import Loader from "../../loader/loader";
import { TUserForm } from "../../../types/types";
import Select from "react-select";
import { TCompanyLabeledOption } from "../../../services/types/users.types";
import { useAppSelector } from "../../../services/hooks";
import { companiesSelector } from "../../../services/selectors/users.selectors";

function AddNewUserForm({
  onSubmit,
}: {
  onSubmit: (values: TUserForm) => void;
}) {
  const companies = useAppSelector(companiesSelector);

  const { data: genderOptions, isLoading } = useGetAllGendersQuery();
  const [companiesOptions, setCompaniesOptions] = useState<
    TCompanyLabeledOption[]
  >([]);

  useEffect(() => {
    const options = companies.filter((c) => c.Id > 1);
    setCompaniesOptions([
      ...options,
      {
        Id: -2,
        value: -2,
        label: "ללא חברת בת",
        Name: "ללא חברת בת",
        Guid: "",
      },
    ]);
  }, [companies]);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      birth: "",
      genderId: 3,
      passport: "",
      roleId: 1,
      fakeCompanyId: null,
    },
    validationSchema: addNewUserValidationSchema,
    onSubmit: (values, { resetForm }) => {
      // Handle form submission
      onSubmit(values);
      resetForm();
    },
  });

  return isLoading ? (
    <Loader />
  ) : (
    <form
      className={`${styles.form} ${styles.form_type_addUser}`}
      onSubmit={formik.handleSubmit}
    >
      <div className={styles.form__questionsContainer}>
        <div className={styles.form__formField}>
          <label htmlFor="firstName" className={styles.form__label}>
            שם פרטי
          </label>
          <input
            type="text"
            className={`${styles.form__input} ${
              formik.errors.firstName && styles.form__input_invalid
            }`}
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="שם פרטי"
          />
        </div>
        <div className={styles.form__formField}>
          <label htmlFor="lastName" className={styles.form__label}>
            שם משפחה
          </label>
          <input
            type="text"
            className={`${styles.form__input} ${
              formik.errors.lastName && styles.form__input_invalid
            }`}
            name="lastName"
            id="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="שם משפחה"
          />
        </div>
        <div className={styles.form__formField}>
          <label htmlFor="email" className={styles.form__label}>
            אימייל
          </label>
          <input
            type="string"
            className={`${styles.form__input} ${
              formik.errors.email && styles.form__input_invalid
            }`}
            name="email"
            id="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="אימייל"
          />
        </div>{" "}
        <div className={styles.form__formField}>
          <label htmlFor="passport" className={styles.form__label}>
            תעודת זהות
          </label>
          <input
            type="string"
            className={`${styles.form__input} ${
              formik.errors.passport && styles.form__input_invalid
            }`}
            name="passport"
            id="passport"
            value={formik.values.passport}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="תעודת זהות"
          />
        </div>
        <div className={styles.form__formField}>
          <label htmlFor="birth" className={styles.form__label}>
            תאריך לידה
          </label>
          <input
            type="date"
            className={`${styles.form__input} ${
              formik.errors.birth && styles.form__input_invalid
            }`}
            name="birth"
            id="birth"
            value={formik.values.birth}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="שם פרטי"
            autoFocus={true}
          />
        </div>
        <div className={styles.form__formField}>
          <label htmlFor="phone" className={styles.form__label}>
            טלפון נייד
          </label>
          <input
            type="text"
            className={`${styles.form__input} ${
              formik.errors.phone && styles.form__input_invalid
            }`}
            name="phone"
            value={
              formik.values.phone
                ? formik.values.phone.replace(/[^\d]+/g, "")
                : ""
            }
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="טלפון נייד"
          />
        </div>
        <div className={styles.form__formField}>
          <label htmlFor="gender" className={styles.form__label}>
            מגדר
          </label>
          <Select
            name={"genderId"}
            // value={genderOptions.findformik.values.genderId}
            placeholder={"אנא בחר מגדר... "}
            options={
              genderOptions
                ? genderOptions.map((o) => ({
                    ...o,
                    value: o.id,
                    label: o.name,
                  }))
                : []
            }
            onChange={(value) => {
              if (value) {
                formik.setFieldValue("genderId", value.value);
              }
            }}
            className={`${styles.select}`}
          />
        </div>
        <div className={styles.form__formField}>
          <label htmlFor="gender" className={styles.form__label}>
            חברת בת
          </label>
          <Select
            name={"companies"}
            options={companiesOptions}
            value={
              companiesOptions.find(
                (c) => c.value === formik.values.fakeCompanyId
              ) || undefined
            }
            placeholder={"אנא בחר חברת בת... "}
            className={styles.select}
            onChange={(value) => {
              if (value) {
                formik.setFieldValue(
                  "fakeCompanyId",
                  value.Id === -2 ? null : value.Id
                );
              }
            }}
          />
        </div>
      </div>

      {formik.errors && (
        <p className={`error ${styles.signin_private__invalid_message}`}>
          {formik.errors.firstName ??
            formik.errors.lastName ??
            formik.errors.email ??
            formik.errors.passport ??
            formik.errors.birth ??
            formik.errors.phone}
        </p>
      )}
      <button
        type="submit"
        disabled={!formik.isValid || formik.isSubmitting}
        className={`button ${styles.form__btn} ${
          !formik.isValid || formik.isSubmitting
            ? styles.form__btn_disabled
            : "hover"
        }`}
      >
        המשך
      </button>
    </form>
  );
}

export default AddNewUserForm;
