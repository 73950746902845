import protocolsStyles from "./protocols.module.css";
import questionnaireStyles from "../questionnaires/questionnaires.module.css";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  useDeleteQuestionnaireCompaniesHeadersMutation,
  useGetQuestionnaireCompaniesHeadersMutation,
  useGetQuestionnaireCompaniesMutation,
} from "../../services/api/utils.api";
import { useAppSelector } from "../../services/hooks";
import { questionnaireCompaniesHeadersSelector } from "../../services/selectors/utils.selectors";
import Loader from "../../components/loader/loader";
import Modal from "../../components/modals/modal/Modal";
import DeleteConfirmation from "../../components/modals/deleteConfirmation/deleteConfirmation";
import useMediaQuery from "../../hooks/useMediaQuery";
import { adminSelector } from "../../services/selectors/user.selectors";
import { IQuestionnaireCompaniesHeaders } from "../../services/types/utils.types";
import { filterObjectsByString } from "../../utils/search";
import AddOrEditQuestionnaireCompaniesHeadersForm from "../../components/forms/addOrEditQuestionnaireCompaniesHeaders/addOrEditQuestionnaireCompaniesHeadersForm";
import GeneralSuccessPopup from "../../components/modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../../components/modals/errorPopup/errorPopup";
import AddOrEditQuestionnaireCompanies from "../../components/addOrEditQuestionnaireCompanies/addOrEditQuestionnaireCompanies";
import { useNavigate } from "react-router-dom";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";

function ProtocolsPage() {
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 800px)");
  const questionnaireCompaniesHeadersServer = useAppSelector(
    questionnaireCompaniesHeadersSelector
  );
  const admin = useAppSelector(adminSelector);

  const [inputValue, setInputValue] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [
    showAddOrEditQuestionnaireCompaniesHeadersForm,
    setShowAddOrEditQuestionnaireCompaniesHeadersForm,
  ] = useState(false);
  const [
    showAddOrEditQuestionnaireCompaniesForm,
    setShowAddOrEditQuestionnaireCompaniesForm,
  ] = useState(false);
  const [headerToDelete, setHeaderToDelete] = useState<
    IQuestionnaireCompaniesHeaders | undefined
  >(undefined);
  const [headerId, setHeaderId] = useState<number | undefined>(undefined);
  const [headerToEdit, setHeaderToEdit] = useState<
    IQuestionnaireCompaniesHeaders | undefined
  >(undefined);
  const [filteredHeaders, setFilteredHeaders] = useState<
    IQuestionnaireCompaniesHeaders[]
  >([]);
  const [toggle, setToggle] = useState({});

  const [getHeaders, { isLoading }] =
    useGetQuestionnaireCompaniesHeadersMutation();
  const [getProtocols] = useGetQuestionnaireCompaniesMutation();
  const [deleteHeader] = useDeleteQuestionnaireCompaniesHeadersMutation();

  useEffect(() => {
    getHeaders({ companyId: admin.companyId });
    getProtocols({ headerId: 2 });
  }, [getHeaders, getProtocols, admin.companyId]);

  useEffect(() => {
    setFilteredHeaders(questionnaireCompaniesHeadersServer);
  }, [setFilteredHeaders, questionnaireCompaniesHeadersServer]);

  //add filter logic
  useEffect(() => {
    setFilteredHeaders(
      filterObjectsByString(inputValue, questionnaireCompaniesHeadersServer)
    );
  }, [setFilteredHeaders, questionnaireCompaniesHeadersServer, inputValue]);

  const toggleAnswer = (id: number) => {
    setToggle({
      ...toggle,
      [id]: !toggle[id as keyof typeof setToggle],
    });
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleDelete = () => {
    if (!headerToDelete) return;
    deleteHeader({ companyId: admin.companyId, id: headerToDelete.id })
      .then((res) => {
        if ("data" in res && res.data) setOpenModalSuccess(true);
        else if ("error" in res) setOpenModalError(true);
      })
      .catch(() => setOpenModalError(true));
  };

  if (showAddOrEditQuestionnaireCompaniesForm && headerId)
    return (
      <AddOrEditQuestionnaireCompanies
        headerId={headerId}
        companyId={admin.companyId}
        onClose={() => {
          setShowAddOrEditQuestionnaireCompaniesForm(false);
          setHeaderId(undefined);
        }}
      />
    );
  return (
    <section className={questionnaireStyles.questionnaires}>
      <BackArrowButton
        text={"למסך הקוד"}
        onClick={() => navigate(-1)}
        position={"center"}
      />
      <h1 className={`title title_blue`}>פרוטוקולים</h1>

      {showAddOrEditQuestionnaireCompaniesHeadersForm ? (
        <AddOrEditQuestionnaireCompaniesHeadersForm
          questionnaireCompaniesHeader={headerToEdit}
          companyId={admin.companyId}
          onClose={() => {
            setShowAddOrEditQuestionnaireCompaniesHeadersForm(false);
            setHeaderToEdit(undefined);
          }}
        />
      ) : (
        <div className={questionnaireStyles.header}>
          <button
            type={"button"}
            className={questionnaireStyles.addBtn}
            onClick={() => {
              setShowAddOrEditQuestionnaireCompaniesHeadersForm(true);
            }}
          >
            {!mobile && "הוסף פרוטוקול חדש"}
            <span className={questionnaireStyles.plus}>+</span>
          </button>
          <input
            type={"text"}
            placeholder={` סנן...  `}
            className={questionnaireStyles.searchInput}
            onChange={onInputChange}
            value={inputValue}
          />
        </div>
      )}
      {isLoading ? (
        <Loader />
      ) : filteredHeaders.length > 0 ? (
        mobile ? (
          <ul className={questionnaireStyles.list}>
            {filteredHeaders.map((q, index) => (
              <li
                key={q.id}
                className={`listRow_mobile ${
                  toggle[index as keyof typeof setToggle] &&
                  "listRow_mobile_long"
                }`}
                onClick={() => toggleAnswer(index)}
              >
                <p className={questionnaireStyles.listCell}>
                  מספר:
                  <span className={questionnaireStyles.listValue}>{q.id}</span>
                </p>
                <p className={questionnaireStyles.listCell}>
                  שם:
                  <span className={questionnaireStyles.listValue}>
                    {q.desc}
                  </span>
                </p>
                <p className={questionnaireStyles.listCell}>
                  האם דפולטיבי:
                  <span className={questionnaireStyles.listValue}>
                    {q.isDefault}
                  </span>
                </p>
                <p className={questionnaireStyles.listCell}>
                  סטטוס:
                  <span className={questionnaireStyles.listValue}>
                    {q.statusId === 1 ? "פעיל" : "לא פעיל"}
                  </span>
                </p>
                <p className={questionnaireStyles.listCell}>אפשרויות:</p>
                <div className={questionnaireStyles.listCell}>
                  <button
                    type={"button"}
                    className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_edit}`}
                    onClick={() => {
                      setHeaderToEdit(q);
                      setShowAddOrEditQuestionnaireCompaniesHeadersForm(true);
                    }}
                  />
                  <button
                    type={"button"}
                    className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_component}`}
                    onClick={() => {
                      setHeaderId(q.id);
                      setShowAddOrEditQuestionnaireCompaniesForm(true);
                      setShowAddOrEditQuestionnaireCompaniesHeadersForm(false);
                    }}
                  />
                  <button
                    type={"button"}
                    className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_delete}`}
                    onClick={() => {
                      setHeaderToDelete(q);
                      setOpenModal(true);
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <ul className={`${questionnaireStyles.list} ${protocolsStyles.list}`}>
            <li
              className={`${protocolsStyles.listRow}   ${protocolsStyles.listRow_header}`}
            >
              <p className={questionnaireStyles.listCell}>מספר</p>
              <p className={questionnaireStyles.listCell}>שם</p>
              <p className={questionnaireStyles.listCell}>האם דפולטיבי</p>
              <p className={questionnaireStyles.listCell}>סטטוס</p>
              <p className={questionnaireStyles.listCell}>אפשרויות</p>
            </li>
            {filteredHeaders.map((q, index) => (
              <li
                key={q.id}
                className={`${protocolsStyles.listRow} ${
                  index % 2 === 0 && questionnaireStyles.listRow_colored
                }`}
              >
                <p className={questionnaireStyles.listCell}>{q.id}</p>
                <p className={questionnaireStyles.listCell}>{q.desc}</p>
                <p className={questionnaireStyles.listCell}>
                  {q.isDefault ? "כן" : "לא"}
                </p>
                <p className={questionnaireStyles.listCell}>
                  {q.statusId === 1 ? "פעיל" : "לא פעיל"}
                </p>
                <div className={questionnaireStyles.listCell}>
                  <button
                    type={"button"}
                    className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_edit}`}
                    onClick={() => {
                      setHeaderToEdit(q);
                      setShowAddOrEditQuestionnaireCompaniesHeadersForm(true);
                    }}
                  />
                  <button
                    type={"button"}
                    className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_component}`}
                    onClick={() => {
                      setHeaderId(q.id);
                      setShowAddOrEditQuestionnaireCompaniesForm(true);
                      setShowAddOrEditQuestionnaireCompaniesHeadersForm(false);
                    }}
                  />
                  <button
                    type={"button"}
                    className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_delete}`}
                    onClick={() => {
                      setHeaderToDelete(q);
                      setOpenModal(true);
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
        )
      ) : (
        <p className={questionnaireStyles.text}>לא נמצאו תוצאות</p>
      )}
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <DeleteConfirmation
            onClose={() => setOpenModal(false)}
            onDelete={handleDelete}
            text={`האם את/ה בטוח/ה שברצונך למחוק את הפרוטוקול: ${headerToDelete?.desc}?`}
          />
        </Modal>
      )}
      {openModalSuccess && (
        <Modal onClose={() => setOpenModalSuccess(false)}>
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModalSuccess(false);
            }}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
    </section>
  );
}

export default ProtocolsPage;
