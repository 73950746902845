import { createSlice } from "@reduxjs/toolkit";
import {
  IGetAllCombosForSearchUsersResponse,
  IGetUsersByCompanyIdWithRecommendsResponse,
  IUserReport,
  TCompanyLabeledOption,
  TRecommendationLabeledOption,
} from "../types/users.types";
import { userApi } from "../api/user.api";

type TUsersState = {
  users: IUserReport[];
  companies: TCompanyLabeledOption[];
  recommendations: TRecommendationLabeledOption[];
};
const initialState: TUsersState = {
  users: [],
  companies: [],
  recommendations: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userApi.endpoints.getUsersByCompanyIdWithRecommends.matchFulfilled,
        (
          state: TUsersState,
          { payload }: { payload: IGetUsersByCompanyIdWithRecommendsResponse }
        ) => {
          return {
            ...state,
            users: payload.Users,
          };
        }
      )
      .addMatcher(
        userApi.endpoints.getAllCombosForSearchUsers.matchFulfilled,
        (
          state: TUsersState,
          { payload }: { payload: IGetAllCombosForSearchUsersResponse }
        ) => {
          return {
            ...state,
            companies: payload.CompaniesList.map((c) => ({
              ...c,
              label: c.Name,
              value: c.Id,
            })),
            recommendations: payload.RecommendsList.map((r) => ({
              ...r,
              label: r.Desc,
              value: r.Id,
            })),
          };
        }
      );
  },
});

export const { actions, reducer } = usersSlice;
export default usersSlice.reducer;
