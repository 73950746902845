import styles from "../forms/forms.module.css";
import questionnaireStyles from "../../pages/questionnaires/questionnaires.module.css";
import CloseButton from "../closeButton/closeButton";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "../../services/hooks";
import { adminSelector } from "../../services/selectors/user.selectors";
import AddExerciseToUser from "../addExerciseToUser/addExerciseToUser";
import {
  useGetAppointmentsProgramsTherapistsMutation,
  usePostAppointmentsProgramsMutation,
} from "../../services/api/utils.api";
import {
  appointmentsProgramsDataSelector,
  appointmentsProgramsTherapistSelector,
} from "../../services/selectors/utils.selectors";
import addOrEditQuestionnaireStyles from "../forms/addOrEditQuestionnaire/addOrEditQuestionnaire.module.css";
import Select, { ActionMeta, SingleValue } from "react-select";
import { initialAppointmentsProgram } from "../../utils/constants";
import formStyles from "../forms/forms.module.css";
import {
  IAppointmentsProgram,
  IAppointmentsProgramsDataWithLabels,
  TAppointmentsProgramsData,
} from "../../services/types/utils.types";
import useAutosizeTextArea from "../../hooks/useAutosizeTextarea";
import useMediaQuery from "../../hooks/useMediaQuery";
import Loader from "../loader/loader";
import Modal from "../modals/modal/Modal";
import GeneralSuccessPopup from "../modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../modals/errorPopup/errorPopup";
import CompanyProgramsPopup from "../modals/companyPrograms/companyProgramsPopup";
import { IUserReport } from "../../services/types/users.types";

function AddTreatmentPlan({
  onClose,
  user,
  program,
}: {
  onClose: () => void;
  user: IUserReport;
  program: TAppointmentsProgramsData | undefined;
}) {
  const mobile = useMediaQuery("(max-width: 900px)");
  const admin = useAppSelector(adminSelector);
  const appointmentsProgramsTherapist = useAppSelector(
    appointmentsProgramsTherapistSelector
  );
  const appointmentsProgramsData = useAppSelector(
    appointmentsProgramsDataSelector
  );

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [postAppointmentsPrograms, { isLoading: isSending }] =
    usePostAppointmentsProgramsMutation();
  const [
    getAppointmentsProgramsTherapists,
    { isLoading: isGettingTherapists },
  ] = useGetAppointmentsProgramsTherapistsMutation();

  const [getTherapistsOptions, setGetTherapistsOptions] = useState(false);
  const [form, setForm] = useState(initialAppointmentsProgram);
  const [showExercises, setShowExercises] = useState(false);
  const [showCompaniesProgramsModal, setShowCompaniesProgramsModal] =
    useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const createDate = program?.appointmentsProgram?.createDate;
  const isValidForChanges = form.statusId === 1;
  // console.log(appointmentsProgramsData, form);

  useAutosizeTextArea(textAreaRef.current, form.comments || "");

  useEffect(() => {
    if (program?.appointmentsProgram) {
      setForm((prev) => ({
        ...prev,
        ...program?.appointmentsProgram,
      }));
    }
  }, [program]);

  useEffect(() => {
    setForm((prev) => ({ ...prev, therapistsId: 0 }));
    if (getTherapistsOptions) {
      getAppointmentsProgramsTherapists({
        appointmentsDurationsId: form.appointmentsDurationsId,
        appointmentsSequenceId: form.appointmentsDurationsId,
        countOfAppointments: form.countOfAppointments,
        roleId: form.roleId,
        companyId: admin.companyId,
        createUsersId: +admin.id,
        usersId: user.Id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form.appointmentsDurationsId,
    form.appointmentsDurationsId,
    form.countOfAppointments,
    form.roleId,
    user.Id,
    admin.id,
    getTherapistsOptions,
    getAppointmentsProgramsTherapists,
    admin.companyId,
  ]);

  const sendForm = () => {
    postAppointmentsPrograms({
      ...form,
      companyId: admin.companyId,
      createUsersId: +admin.id,
      usersId: user.Id,
    })
      .then((res) => {
        if (res && "data" in res) {
          setOpenModalSuccess(true);
        }
      })
      .catch((err) => {
        setOpenModalError(true);
        console.log(err);
      });
  };
  const onSelectChange = useCallback(
    <
      T extends keyof IAppointmentsProgramsDataWithLabels, // Constrain to valid keys in IAppointmentsProgramsDataWithLabels
      U extends IAppointmentsProgramsDataWithLabels[T][number] // Infer the item type within the array
    >(
      value: SingleValue<U>, // Value is of type AddLabelAndValue<U>
      { name }: ActionMeta<U>
    ) => {
      if (name && value && "value" in value) {
        setForm((prev) => ({
          ...prev,
          [name]: value.value, // Update the form field with the selected value
        }));
      }
    },
    []
  );
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { id, value } = e.target;
      setForm((prev) => ({ ...prev, [id]: +value }));
    },
    []
  );

  const onCompanyProgramClick = (program: Partial<IAppointmentsProgram>) => {
    setForm((prev) => ({ ...prev, ...program }));
    setShowCompaniesProgramsModal(false);
  };

  const isFormValid = (): boolean => {
    // Check if required fields are filled
    return (
      !!form.name &&
      form.appointmentsDurationsId > 0 &&
      form.appointmentsSequenceId > 0 &&
      form.countOfAppointments > 0 &&
      form.roleId > 0
    );
  };

  return (
    <article className={styles.section}>
      {!showExercises && <CloseButton onClick={onClose} />}
      {showExercises ? (
        <AddExerciseToUser onClose={() => setShowExercises(false)} />
      ) : (
        <form className={styles.form}>
          <div className={styles.form__header}>
            {isValidForChanges ? (
              <p className={styles.form__note}>
                שדות המסומנים ב-* הינם שדות חובה
              </p>
            ) : (
              <p className={styles.form__note}>
                לא ניתן לשנות תוכנית זו בשל סטטוס
              </p>
            )}
            {isValidForChanges && (
              <button
                type={"button"}
                className={`${questionnaireStyles.addBtn} ${
                  !isValidForChanges && "button_disabled"
                }`}
                disabled={!isValidForChanges}
                onClick={() => {
                  setShowCompaniesProgramsModal(true);
                }}
              >
                {"בחר מתוכנית קיימת"}
                <span className={questionnaireStyles.plus}>+</span>
              </button>
            )}
          </div>
          <ul
            className={`${styles.form__list} ${
              addOrEditQuestionnaireStyles.form__list
            } ${
              !mobile && addOrEditQuestionnaireStyles.form__list_four_columns
            }`}
          >
            <li>
              <label htmlFor="name" className={styles.form__label}>
                *שם
              </label>
              <input
                type="text"
                className={`${formStyles.form__input} ${
                  !isValidForChanges && formStyles.form__input_disabled
                }`}
                name="name"
                id="name"
                disabled={!isValidForChanges}
                value={form.name || ""}
                onChange={(e) =>
                  setForm((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </li>
            <li>
              <label
                htmlFor="countOfAppointments"
                className={styles.form__label}
              >
                *מספר מפגשים
              </label>
              <input
                type="number"
                className={`${formStyles.form__input} ${
                  !isValidForChanges && formStyles.form__input_disabled
                }`}
                name="countOfAppointments"
                id="countOfAppointments"
                value={form.countOfAppointments || ""}
                onChange={handleChange}
                disabled={!isValidForChanges}
              />
            </li>
            <li>
              <label htmlFor="roleId" className={styles.form__label}>
                *שיוך תומך/ת טיפול
              </label>
              <Select
                name={"roleId"}
                options={appointmentsProgramsData.Roles}
                value={
                  appointmentsProgramsData.Roles.find(
                    (o) => o.value === form?.roleId
                  ) || undefined
                }
                placeholder={"אנא בחר... "}
                className={styles.select}
                isDisabled={!isValidForChanges}
                onChange={onSelectChange}
              />
            </li>
            <li>
              <label
                htmlFor="appointmentsDurationsId"
                className={styles.form__label}
              >
                *משך מפגש
              </label>
              <Select
                name={"appointmentsDurationsId"}
                options={appointmentsProgramsData.AppointmentsDurations}
                value={
                  appointmentsProgramsData.AppointmentsDurations.find(
                    (o) => o.value === form?.appointmentsDurationsId
                  ) || undefined
                }
                placeholder={"אנא בחר... "}
                className={styles.select}
                onChange={onSelectChange}
                isDisabled={!isValidForChanges}
              />
            </li>
            <li>
              <label
                htmlFor="appointmentsSequenceId"
                className={styles.form__label}
              >
                *תדירות מפגשים
              </label>
              <Select
                name={"appointmentsSequenceId"}
                options={appointmentsProgramsData.AppointmentsSequence}
                value={
                  appointmentsProgramsData.AppointmentsSequence.find(
                    (o) => o.value === form?.appointmentsSequenceId
                  ) || undefined
                }
                placeholder={"אנא בחר... "}
                className={styles.select}
                onChange={(value) => {
                  value &&
                    setForm((prev) => ({
                      ...prev,
                      appointmentsSequenceId: value.Id,
                    }));
                }}
                isDisabled={!isValidForChanges}
              />
            </li>
            <li>
              <label htmlFor="genderId" className={styles.form__label}>
                מגדר
              </label>
              <Select
                name={"genderId"}
                options={appointmentsProgramsData.Genders}
                value={
                  appointmentsProgramsData.Genders.find(
                    (o) => o.value === form?.genderId
                  ) || undefined
                }
                placeholder={"אנא בחר... "}
                className={styles.select}
                onChange={onSelectChange}
                isDisabled={!isValidForChanges}
              />
            </li>
            <li>
              <label htmlFor="migzarId" className={styles.form__label}>
                מגזר
              </label>
              <Select
                name={"migzarId"}
                options={appointmentsProgramsData.Migzars}
                value={
                  appointmentsProgramsData.Migzars.find(
                    (o) => o.value === form?.migzarId
                  ) || undefined
                }
                placeholder={"אנא בחר... "}
                className={styles.select}
                onChange={onSelectChange}
                isDisabled={!isValidForChanges}
              />
            </li>
            <li>
              <label htmlFor="specializationsId" className={styles.form__label}>
                התמחות
              </label>
              <Select
                name={"specializationsId"}
                options={appointmentsProgramsData.Specializations}
                value={
                  appointmentsProgramsData.Specializations.find(
                    (o) => o.value === form?.specializationsId
                  ) || undefined
                }
                placeholder={"אנא בחר... "}
                className={styles.select}
                onChange={onSelectChange}
                isDisabled={!isValidForChanges}
              />
            </li>
            <li>
              <label htmlFor="statusId" className={styles.form__label}>
                סטטוס
              </label>
              <Select
                name={"statusId"}
                options={appointmentsProgramsData.AppointmentsProgramsStatus}
                value={
                  appointmentsProgramsData.AppointmentsProgramsStatus.find(
                    (s) => s.Id === form.statusId
                  ) || undefined
                }
                placeholder={"אנא בחר סטטוס"}
                className={styles.select}
                onChange={onSelectChange}
                isDisabled={!isValidForChanges}
              />
            </li>
            <li>
              <label htmlFor="mediasId" className={styles.form__label}>
                אופן המפגש
              </label>
              <Select
                name={"mediasId"}
                options={appointmentsProgramsData.Medias}
                value={
                  appointmentsProgramsData.Medias.find(
                    (o) => o.value === form?.mediasId
                  ) || undefined
                }
                placeholder={"אנא בחר... "}
                className={styles.select}
                onChange={onSelectChange}
                isDisabled={!isValidForChanges}
              />
            </li>
            <li>
              <label htmlFor="vetek" className={styles.form__label}>
                ותק
              </label>
              <input
                type="number"
                className={`${formStyles.form__input} ${
                  !isValidForChanges && formStyles.form__input_disabled
                }`}
                name="vetek"
                id="vetek"
                value={form.vetek || ""}
                onChange={handleChange}
                disabled={!isValidForChanges}
              />
            </li>
            <li>
              <input
                type="checkbox"
                disabled={!isValidForChanges || !isFormValid()}
                name="therapistsId"
                id="therapistsId"
                checked={getTherapistsOptions}
                onChange={() => {
                  setGetTherapistsOptions((prev) => !prev);
                }}
              />
              <label htmlFor="therapistsId" className={styles.form__label}>
                שיוך מטפל
              </label>
              {getTherapistsOptions ? (
                isGettingTherapists ? (
                  <Loader />
                ) : (
                  <Select
                    name={"therapistsId"}
                    options={appointmentsProgramsTherapist}
                    value={
                      appointmentsProgramsTherapist.find(
                        (o) => o.value === form?.therapistsId
                      ) || undefined
                    }
                    placeholder={"אנא בחר... "}
                    className={styles.select}
                    onChange={(value) => {
                      value &&
                        setForm((prev) => ({
                          ...prev,
                          therapistsId: value.value,
                        }));
                    }}
                    isDisabled={!isValidForChanges}
                  />
                )
              ) : (
                <p className={`${styles.form__note}`}>
                  *יש למלא את כל השדות הנדרשים
                </p>
              )}
            </li>
            <li>
              <label htmlFor="comments" className={styles.form__label}>
                הערות
              </label>
              <textarea
                ref={textAreaRef}
                rows={1}
                className={`${formStyles.form__input} ${
                  !isValidForChanges && formStyles.form__input_disabled
                }`}
                name="comments"
                id="comments"
                value={form.comments || ""}
                onChange={(e) =>
                  setForm((prev) => ({ ...prev, comments: e.target.value }))
                }
                disabled={!isValidForChanges}
              />
            </li>
          </ul>
          {createDate && (
            <p className={styles.form__note}>
              תאריך יצירה: {createDate.toString()}
            </p>
          )}
          <button
            type={"button"}
            className={`button ${styles.submitBtn} ${
              (!isValidForChanges || !isFormValid() || isSending) &&
              `button_disabled`
            }`}
            onClick={sendForm}
            disabled={isSending || !isValidForChanges || !isFormValid()}
          >
            {isSending ? "שליחה" : "שמור שינויים"}
          </button>
        </form>
      )}
      {openModalSuccess && (
        <Modal onClose={() => setOpenModalSuccess(false)}>
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModalSuccess(false);
              onClose();
            }}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
      {showCompaniesProgramsModal && (
        <Modal onClose={() => setShowCompaniesProgramsModal(false)} wide={true}>
          <CompanyProgramsPopup
            companyId={admin.companyId}
            onClick={onCompanyProgramClick}
          />
        </Modal>
      )}
    </article>
  );
}

export default AddTreatmentPlan;
