import questionnaireStyles from "../questionnaires/questionnaires.module.css";
import styles from "./rrmMainPage.module.css";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  useDeleteCompanyGroupsRRMHeadersMutation,
  useGetCompanyGroupsRRMHeadersMutation,
} from "../../services/api/utils.api";
import { useAppSelector } from "../../services/hooks";
import { companyGroupsRRMHeadersSelector } from "../../services/selectors/utils.selectors";
import Loader from "../../components/loader/loader";
import useMediaQuery from "../../hooks/useMediaQuery";
import { filterObjectsByString } from "../../utils/search";
import { ICompanyGroupsRRMHeaders } from "../../services/types/utils.types";
import { adminSelector } from "../../services/selectors/user.selectors";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import AddOrEditRRMHeaders from "../../components/forms/addOrEditRRMHeaders/addOrEditRRMHeaders";
import Modal from "../../components/modals/modal/Modal";
import DeleteConfirmation from "../../components/modals/deleteConfirmation/deleteConfirmation";
import GeneralSuccessPopup from "../../components/modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../../components/modals/errorPopup/errorPopup";
import { useNavigate } from "react-router-dom";

function RrmMainPage() {
  const mobile = useMediaQuery("(max-width: 800px)");
  const navigate = useNavigate();
  const admin = useAppSelector(adminSelector);
  const rrmHeaders = useAppSelector(companyGroupsRRMHeadersSelector);

  const [filteredRRMHeaders, setFilteredRRMHeaders] = useState<
    ICompanyGroupsRRMHeaders[]
  >([]);
  const [RRMToEditId, setRRMToEditId] = useState<null | number>(null);
  const [RRMToDelete, setRRMToDelete] =
    useState<null | ICompanyGroupsRRMHeaders>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [showAddOrEditRRMForm, setShowAddOrEditRRMForm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const [getRRMHeaders, { isLoading }] =
    useGetCompanyGroupsRRMHeadersMutation();
  const [deleteRRMHeader] = useDeleteCompanyGroupsRRMHeadersMutation();

  useEffect(() => {
    setFilteredRRMHeaders(rrmHeaders);
  }, [rrmHeaders]);

  useEffect(() => {
    getRRMHeaders({ companyId: admin.companyId });
  }, [getRRMHeaders, admin.companyId]);

  useEffect(() => {
    setFilteredRRMHeaders(filterObjectsByString(inputValue, rrmHeaders));
  }, [inputValue, rrmHeaders]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleDelete = () => {
    if (!RRMToDelete) return;
    deleteRRMHeader({ companyId: admin.companyId, RRMHeader: RRMToDelete })
      .then((res) => {
        if ("data" in res && res.data) setOpenModalSuccess(true);
        else if ("error" in res) setOpenModalError(true);
      })
      .catch(() => setOpenModalError(true));
  };

  if (showAddOrEditRRMForm)
    return (
      <section className={styles.section}>
        <BackArrowButton
          text={"למסך הקודם"}
          position={"center"}
          onClick={() => {
            setShowAddOrEditRRMForm(false);
            setRRMToEditId(null);
          }}
        />
        <h1 className={`title title_blue`}>
          {RRMToEditId ? "עריכת קבוצת RRM" : "הוספת קבוצת RRM"}
        </h1>
        <AddOrEditRRMHeaders
          setShowAddOrEditRRMForm={setShowAddOrEditRRMForm}
          setRRMToEditId={setRRMToEditId}
          rrmId={RRMToEditId}
        />
      </section>
    );

  return (
    <section className={questionnaireStyles.questionnaires}>
      <BackArrowButton
        text={"למסך הקוד"}
        onClick={() => navigate(-1)}
        position={"center"}
      />
      <h1 className={`title title_blue`}>RRM</h1>
      <div className={questionnaireStyles.header}>
        <button
          type={"button"}
          className={questionnaireStyles.addBtn}
          onClick={() => setShowAddOrEditRRMForm(true)}
        >
          {!mobile && "הוסף RRM"}
          <span className={questionnaireStyles.plus}>+</span>
        </button>
        <input
          type={"text"}
          placeholder={"חפס..."}
          className={questionnaireStyles.searchInput}
          onChange={onInputChange}
          value={inputValue}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : filteredRRMHeaders.length > 0 ? (
        <ul className={`${questionnaireStyles.list} ${styles.list}`}>
          <li
            className={`${questionnaireStyles.listRow} ${questionnaireStyles.listRow_header} ${styles.listRow} colored  `}
          >
            <span className={questionnaireStyles.listCell}>מספר</span>
            <span className={questionnaireStyles.listCell}>שם</span>
            <span className={questionnaireStyles.listCell}>סוג</span>
            <span className={questionnaireStyles.listCell}>דפולטיבי</span>
            <span className={questionnaireStyles.listCell}>סטטוס</span>
            <span className={questionnaireStyles.listCell}>אפשרויות</span>
          </li>
          {filteredRRMHeaders.map((h, index) => (
            <li
              key={h.id}
              className={`${questionnaireStyles.listRow} ${styles.listRow}  ${
                index % 2 !== 0 && "colored"
              }`}
            >
              <span className={questionnaireStyles.listCell}>{h.id}</span>
              <span className={questionnaireStyles.listCell}>{h.desc}</span>
              <span className={questionnaireStyles.listCell}>
                {h.isClini ? "קליני" : "רגיל"}
              </span>
              <span className={questionnaireStyles.listCell}>
                {h.isDefault ? "דפולטיבי" : "לא"}
              </span>
              <span className={questionnaireStyles.listCell}>
                {h.statusId === 1 ? "פעיל" : "לא פעיל"}
              </span>
              <div className={questionnaireStyles.listCell}>
                <button
                  type={"button"}
                  className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_edit}`}
                  onClick={() => {
                    setRRMToEditId(h.id);
                    setShowAddOrEditRRMForm(true);
                  }}
                />
                <button
                  type={"button"}
                  className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_delete}`}
                  onClick={() => {
                    setRRMToDelete(h);
                    setOpenModal(true);
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className={"text"}>לא נמצאו תוצאות</p>
      )}
      {openModal && RRMToDelete && (
        <Modal onClose={() => setOpenModal(false)}>
          <DeleteConfirmation
            onClose={() => setOpenModal(false)}
            onDelete={handleDelete}
            text={`האם את/ה בטוח/ה שברצונך למחוק את הפרוטוקול: ${RRMToDelete?.desc}?`}
          />
        </Modal>
      )}
      {openModalSuccess && (
        <Modal onClose={() => setOpenModalSuccess(false)}>
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModalSuccess(false);
            }}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
    </section>
  );
}

export default RrmMainPage;
