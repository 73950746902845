import React, { Dispatch, SetStateAction } from "react";
import { useDrop } from "react-dnd";
import { ITEM_TYPE } from "../../utils/constants";
import styles from "./draggableRRMHeader.module.css";
import questionnaireStyles from "../../pages/questionnaires/questionnaires.module.css";
import {
  ICompanyGroupsRRMHeaders,
  IQuestion,
} from "../../services/types/utils.types";

interface IRRMHeaderProps {
  header: ICompanyGroupsRRMHeaders;
  onDropQuestion: (questionId: number, headerId: number) => void;
  questions: IQuestion[];
  setRRMToEditId: Dispatch<SetStateAction<null | number>>;
  setShowAddOrEditRRMForm: Dispatch<SetStateAction<boolean>>;
}

const DraggableRRMHeader: React.FC<IRRMHeaderProps> = ({
  header,
  onDropQuestion,
  questions,
  setRRMToEditId,
  setShowAddOrEditRRMForm,
}) => {
  const [, drop] = useDrop({
    accept: ITEM_TYPE.QUESTION,
    drop: (item: { id: number }) => onDropQuestion(item.id, header.id),
  });

  return (
    <div ref={drop} className={`option ${styles.rrmHeader}`}>
      {header.desc} {header.isClini ? "(קליני)" : ""}
      <span> סך השאלות: {questions?.length || 0}</span>
      <button
        type={"button"}
        className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_edit}`}
        onClick={() => {
          setRRMToEditId(header.id);
          setShowAddOrEditRRMForm(true);
        }}
      />
      <ul className={styles.questions}>
        {questions.map((q, index) => (
          <li key={q.id} className={styles.question}>
            {index + 1}. {q.id}: {q.desc}
            <button
              type={"button"}
              className={`${styles.btn} hover`}
              onClick={() => onDropQuestion(q.id, 0)}
            >
              X
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DraggableRRMHeader;
