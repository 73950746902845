import questionnaireStyles from "../questionnaires/questionnaires.module.css";
import styles from "./rrmMainPage.module.css";
import React, { useEffect, useState } from "react";
import { useGetCompanyGeneralRRMMutation } from "../../services/api/utils.api";
import { useAppSelector } from "../../services/hooks";
import { companyGeneralRRMSelector } from "../../services/selectors/utils.selectors";
import Loader from "../../components/loader/loader";
import useMediaQuery from "../../hooks/useMediaQuery";
import { TGeneralRRM } from "../../services/types/utils.types";
import { adminSelector } from "../../services/selectors/user.selectors";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import Modal from "../../components/modals/modal/Modal";
import GeneralSuccessPopup from "../../components/modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../../components/modals/errorPopup/errorPopup";
import RrmGeneralModel from "../../components/rrmModel/rrmGeneralModel";
import AddOrEditGeneralRRMForm from "../../components/forms/addOrEditGeneralRRM/addOrEditGeneralRRMForm";
import { useNavigate } from "react-router-dom";

function RrmGeneralPage() {
  const mobile = useMediaQuery("(max-width: 800px)");
  const navigate = useNavigate();
  const admin = useAppSelector(adminSelector);
  const companyGeneralRRMs = useAppSelector(companyGeneralRRMSelector);

  const [RRMToEdit, setRRMToEdit] = useState<null | TGeneralRRM>(null);
  const [showAddOrEditRRMForm, setShowAddOrEditRRMForm] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const [getGeneralRRMs, { isLoading }] = useGetCompanyGeneralRRMMutation();

  useEffect(() => {
    getGeneralRRMs({ companyId: admin.companyId });
  }, [getGeneralRRMs, admin.companyId]);

  if (showAddOrEditRRMForm)
    return (
      <section className={styles.section}>
        <BackArrowButton
          text={"למסך הקודם"}
          position={"center"}
          onClick={() => {
            setShowAddOrEditRRMForm(false);
            setRRMToEdit(null);
          }}
        />
        <h1 className={`title title_blue`}>
          {RRMToEdit ? "עריכת RRM כללי" : "הוספת RRM כללי"}
        </h1>
        <AddOrEditGeneralRRMForm
          closeForm={() => {
            setShowAddOrEditRRMForm(false);
            setRRMToEdit(null);
          }}
          seq={companyGeneralRRMs.length + 1}
          RRMToEdit={RRMToEdit}
        />
      </section>
    );

  return (
    <section className={questionnaireStyles.questionnaires}>
      <BackArrowButton
        text={"למסך הקוד"}
        onClick={() => navigate(-1)}
        position={"center"}
      />
      <h1 className={`title title_blue  `}>RRM כללי</h1>
      <div className={`${questionnaireStyles.header} ${styles.margin}`}>
        <button
          type={"button"}
          className={questionnaireStyles.addBtn}
          onClick={() => setShowAddOrEditRRMForm(true)}
        >
          {!mobile && "הוסף RRM כללי"}
          <span className={questionnaireStyles.plus}>+</span>
        </button>
      </div>
      {isLoading ? (
        <Loader />
      ) : companyGeneralRRMs.length > 0 ? (
        <RrmGeneralModel
          companyId={admin.companyId}
          setRRMToEdit={setRRMToEdit}
          setShowForm={setShowAddOrEditRRMForm}
        />
      ) : (
        <p className={"text"}>לא נמצאו תוצאות</p>
      )}
      {/*{openModal && RRMToDelete && (*/}
      {/*  <Modal onClose={() => setOpenModal(false)}>*/}
      {/*    <DeleteConfirmation*/}
      {/*      onClose={() => setOpenModal(false)}*/}
      {/*      onDelete={handleDelete}*/}
      {/*      text={`האם את/ה בטוח/ה שברצונך למחוק את הפרוטוקול: ${RRMToDelete?.desc}?`}*/}
      {/*    />*/}
      {/*  </Modal>*/}
      {/*)}*/}
      {openModalSuccess && (
        <Modal onClose={() => setOpenModalSuccess(false)}>
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModalSuccess(false);
            }}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
    </section>
  );
}

export default RrmGeneralPage;
