import supportStyles from "./supportPage.module.css";
import React, { ChangeEvent, useState } from "react";
import Modal from "../../components/modals/modal/Modal";
import GeneralSuccessPopup from "../../components/modals/generalSuccessPopup/generalSuccessPopup";

function SupportPage() {
  const [form, setForm] = useState({ theme: "", question: "" });
  const [openModal, setOpenModal] = useState(false);

  const disabled = !form.theme || !form.question;

  // const [sendTechSupportRequest, { isError: isTechSupportError }] =
  //   useSendTechSupportRequestMutation();
  // const [sendCustomerServiceRequest, { isError: isCustomerServiceError }] =
  //   useSendCustomerServiceRequestMutation();
  // const updateError =
  //   useAppSelector(errorRequestNumberSelector) ??
  //   "משהו השתבש, אנא נסה שוב מאוחר יותר";

  function handleChange(
    evt: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const value = evt.target.value;
    setForm({
      ...form,
      [evt.target.name]: value,
    });
  }
  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    // type === customerService
    //   ? sendCustomerServiceRequest({ request: form })
    //   : sendTechSupportRequest({ request: form }).then((response) => {
    //       if ("data" in response && response.data) {
    //         // getUser("");
    //         // setForm({theme: "", question: ""});
    //         // setOpenModal(true);
    //       }
    //     });
    setForm({ theme: "", question: "" });
    setOpenModal(true);
  };
  return (
    <section className={supportStyles.support}>
      <h2 className={`title title_blue`}>תמיכה טכנית</h2>
      <p className={supportStyles.support__text}>
        גישה רק לדברים החיוניים ביותר. שדרג לפרימיום וקבל שירותי תמיכה נפשית על
        ידי פסיכולוג מוסמך זמין 24/7, התייעצות בפורום עם מתמודדים כמוך, ועוד
      </p>
      <form className={supportStyles.support__form} onSubmit={submitForm}>
        <label htmlFor="theme" className={supportStyles.support__label}>
          נושא הפנייה
        </label>
        <input
          value={form.theme}
          type="text"
          name={"theme"}
          id="theme"
          className={supportStyles.support__input}
          autoFocus={true}
          onChange={handleChange}
        />
        <label htmlFor="question" className={supportStyles.support__label}>
          תוכן הפנייה
        </label>
        <textarea
          value={form.question}
          name={"question"}
          id="question"
          className={supportStyles.support__textArea}
          onChange={handleChange}
        />
        {/*{(isCustomerServiceError || isTechSupportError) && (*/}
        {/*  <p className={"error"}>{updateError}</p>*/}
        {/*)}*/}
        <button
          type="submit"
          className={`button ${supportStyles.support__btn} ${
            disabled ? supportStyles.support__btn_disabled : ""
          }`}
        >
          שליחת פניה
        </button>
      </form>
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <GeneralSuccessPopup onClose={() => setOpenModal(false)} />
        </Modal>
      )}
    </section>
  );
}

export default SupportPage;
