import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/auth.slice";
import usersReducer from "../slices/users.slice";
import utilsReducer from "../slices/utils.slice";
import { authApi } from "../api/auth.api";

export const rootReducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  utils: utilsReducer,
  [authApi.reducerPath]: authApi.reducer,
});

export const index = configureStore({
  reducer: rootReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      authApi.middleware
    ),
  devTools: process.env.NODE_ENV === "development",
  preloadedState: undefined,
});

export default store;
