import styles from "./editDataPage.module.css";
import { userActionsEdit, userActionsEditBasic } from "../../utils/user";
import { Link, useNavigate, useParams } from "react-router-dom";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import useMediaQuery from "../../hooks/useMediaQuery";
import { mobileWidth } from "../../utils/constants";
import { useAppSelector } from "../../services/hooks";
import { adminSelector } from "../../services/selectors/user.selectors";

function EditDataPage() {
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const mobile = useMediaQuery(mobileWidth);

  const admin = useAppSelector(adminSelector);
  const options =
    admin.fakeCompanyId === null || admin.FakeCompanyId === null
      ? userActionsEdit
      : userActionsEditBasic;

  return (
    <article className={styles.section}>
      {!mobile && (
        <BackArrowButton
          text={"למסך הקודם"}
          onClick={() => navigate(-1)}
          position={"center"}
        />
      )}
      <ul className={`options`}>
        {options.map((a, index) => (
          <Link
            to={`/users/${userId}/${a.path} `}
            key={index}
            className={`option hover`}
          >
            {a.text}
          </Link>
        ))}
        {mobile && (
          <button
            onClick={() => navigate(-1)}
            className={`button ${styles.btn} hover`}
          >
            למסך הקודם
          </button>
        )}
      </ul>
    </article>
  );
}

export default EditDataPage;
