import styles from "./rrmModel.module.css";
import questionnaireStyles from "../../pages/questionnaires/questionnaires.module.css";
import { noDataLabel } from "../../utils/constants";
import { useAppSelector } from "../../services/hooks";
import { companyGeneralRRMSelector } from "../../services/selectors/utils.selectors";
import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { TGeneralRRM } from "../../services/types/utils.types";
import { useAddOrEditCompanyGeneralRRMMutation } from "../../services/api/utils.api";
import { adminSelector } from "../../services/selectors/user.selectors";

function RrmGeneralModel({
  setRRMToEdit,
  setShowForm,
}: {
  companyId: number;
  setRRMToEdit: Dispatch<SetStateAction<null | TGeneralRRM>>;
  setShowForm: Dispatch<SetStateAction<boolean>>;
}) {
  const admin = useAppSelector(adminSelector);
  const companyGeneralRRMs = useAppSelector(companyGeneralRRMSelector);

  const [draggedItemId, setDraggedItemId] = useState<number | null>(null);
  const [items, setItems] = useState(companyGeneralRRMs);
  const [touched, setTouched] = useState(false);

  const [addOrEditRRM, { isLoading }] = useAddOrEditCompanyGeneralRRMMutation();

  useEffect(() => {
    setItems(companyGeneralRRMs);
  }, [companyGeneralRRMs]);

  const handleDragStart = (id: number) => {
    setDraggedItemId(id);
  };

  const handleDragOver = (e: React.DragEvent<HTMLLIElement>) => {
    e.preventDefault();
  };

  const handleDrop = (droppedId: number) => {
    setTouched(true);
    if (draggedItemId === null || draggedItemId === droppedId) return;

    // Rearrange the items based on drag-and-drop
    const draggedIndex = items.findIndex((item) => item.id === draggedItemId);
    const droppedIndex = items.findIndex((item) => item.id === droppedId);
    const updatedItems = [...items];
    const [draggedItem] = updatedItems.splice(draggedIndex, 1);
    updatedItems.splice(droppedIndex, 0, draggedItem);

    // Update the sequence numbers
    const itemsWithUpdatedSeq = updatedItems.map((item, index) => ({
      ...item,
      rrmseq: index + 1, // Adjust as per desired numbering
    }));

    setItems(itemsWithUpdatedSeq);
    setDraggedItemId(null);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    addOrEditRRM({
      companyId: admin.companyId,
      rrm: items,
    })
      .then((res) => {
        if (res && "data" in res) {
          setTouched(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setTouched(false);
  };

  return (
    <article className={styles.card}>
      {items.length > 0 ? (
        <ul className={styles.card__list}>
          <li
            className={`${styles.card__li} ${styles.card__li_type_general} ${styles.card__li_title}`}
          >
            <span className={styles.card__text}>מס'</span>
            <span className={styles.card__text}>תמרור</span>
            <span className={styles.card__text}>תיאור</span>
            <span className={styles.card__text}>סטטוס</span>
            <span className={styles.card__text}>אנליזה</span>
            <span className={styles.card__text}>המלצות</span>
            <span className={styles.card__text}>דגשים</span>
            <span className={styles.card__text}>במהלך השיחה</span>
            <span className={styles.card__text}>סדר</span>
            <span className={styles.card__text}>עריכה</span>
          </li>
          {items.map((item) => (
            <li
              className={`${styles.card__li} ${styles.card__li_type_general}`}
              key={item.id}
              draggable
              onDragStart={() => handleDragStart(item.id)}
              onDragOver={handleDragOver}
              onDrop={() => handleDrop(item.id)}
            >
              <span className={styles.card__text}>{item.id || "-"}</span>
              <div
                className={styles.card__cell}
                style={{ backgroundColor: item.color || "#00000000" }}
              >
                {item.title || noDataLabel}
              </div>
              <span className={styles.card__text}>{item.desc || "-"}</span>
              <span className={styles.card__text}>
                {item.statusId === 0 ? "לא פעיל" : "פעיל"}
              </span>
              <span className={styles.card__text}>{item.anliza || "-"}</span>
              <span className={styles.card__text}>
                {item.recommendations || "-"}
              </span>
              <span className={styles.card__text}>
                {item.highlights || "-"}
              </span>
              <span className={styles.card__text}>
                {item.conversation || "-"}
              </span>
              {/*<span className={styles.card__text}>{item.start || "-"}</span>*/}
              {/*<span className={styles.card__text}>{item.end || "-"}</span>*/}
              <span className={styles.card__text}>{item.rrmseq}</span>
              <button
                type={"button"}
                className={`${questionnaireStyles.btn} ${
                  questionnaireStyles.btn_type_edit
                } ${touched && "button_disabled"}`}
                disabled={touched}
                onClick={() => {
                  setRRMToEdit(item);
                  setShowForm(true);
                }}
              />
            </li>
          ))}
        </ul>
      ) : (
        <p className={`text`}>תרם נוספו ציונים</p>
      )}
      {touched && (
        <button
          type={"submit"}
          className={`button hover ${styles.submitBtn} `}
          disabled={!touched || isLoading}
          onClick={handleSubmit}
        >
          שמור שינויים
        </button>
      )}
    </article>
  );
}

export default RrmGeneralModel;
