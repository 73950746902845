import React from "react";
import styles from "./draggableQuestion.module.css";
import { useDrag, useDrop } from "react-dnd";
import { ITEM_TYPE } from "../../utils/constants";
import { IQuestion } from "../../services/types/utils.types";

interface IDraggableQuestionProps {
  question: IQuestion;
  moveQuestion: (draggedId: number, targetId: number) => void;
}

const DraggableQuestion: React.FC<IDraggableQuestionProps> = ({
  question,
  moveQuestion,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE.QUESTION,
    item: { id: question.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ITEM_TYPE.QUESTION,
    hover: (item: { id: number }) => {
      if (item.id !== question.id) {
        moveQuestion(item.id, question.id);
      }
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className={`option ${styles.question}`}
    >
      {question.desc}
    </div>
  );
};

export default DraggableQuestion;
