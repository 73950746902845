import { IQuestion } from "../../services/types/utils.types";
import { useNavigate } from "react-router-dom";
import styles from "../../pages/questionnaires/questionnaires.module.css";
import React, { useState } from "react";
import Tooltip from "../tooltip/tooltip";

const QuestionsActionButtons = ({
  question,
  onDelete,
}: {
  question: IQuestion;
  onDelete: () => void;
}) => {
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState("");

  return (
    <div className={styles.listCell}>
      <button
        type="button"
        className={`${styles.btn} ${styles.btn_type_edit}`}
        onClick={() => navigate(`questions/edit/${question.id}`)}
        onMouseEnter={() => setShowTooltip("edit")}
        onMouseLeave={() => setShowTooltip("")}
      >
        <Tooltip isShown={showTooltip === "edit"}>לעריכה של שאלה</Tooltip>
      </button>
      <button
        type="button"
        className={`${styles.btn} ${styles.btn_type_options} ${
          question.dataTypesId !== 1 && "button_disabled"
        }`}
        disabled={question.dataTypesId !== 1}
        onClick={() => navigate(`options/${question.id}`)}
        onMouseEnter={() => setShowTooltip("options")}
        onMouseLeave={() => setShowTooltip("")}
      >
        <Tooltip isShown={showTooltip === "options"}>ראה אופציות</Tooltip>
      </button>
      <button
        type="button"
        className={`${styles.btn} ${styles.btn_type_delete}`}
        onClick={onDelete}
      />
    </div>
  );
};

export default QuestionsActionButtons;
