export interface IUserAction {
  text: string;
  value: string;
  path: string;
}

export const userActionsEditBasic: IUserAction[] = [
  { text: "דוח פעולה", value: "actionsReport", path: "report" },
  { text: "פרטים אישיים", value: "editPersonalData", path: "personal" },
  {
    text: "דוחות שאלונים",
    value: "questionsReports",
    path: "questions_reports",
  },
];
export const userActionsEdit: IUserAction[] = [
  ...userActionsEditBasic,
  // { text: "תרגילים", value: "exercises", path: "exercises" },
  { text: "תכנית טיפול", value: "treatmentPlan", path: "treatment_plan" },
  // { text: "עדכונים", value: "updates", path: "updates" },
  // { text: "דוח RRM", value: "rrm", path: "rrm" },
  // { text: "מסמכים", value: "documents", path: "documents" },
  // { text: "מרשמים", value: "prescriptions", path: "prescriptions" },
  // {
  //   text: "דוחות פריוונצ'ר",
  //   value: "prevencherReports",
  //   path: "prevencher_reports",
  // },
  // { text: "דוחות טיפול", value: "treatmentReports", path: "treatment_reports" },
  // { text: "דוחות EMD", value: "emd", path: "emd" },
  // { text: "דוחות GAR", value: "gar", path: "gar" },
];
