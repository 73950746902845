import styles from "./companyProgramsPopup.module.css";
import { useGetCompanyAppointmentsProgramsMutation } from "../../../services/api/utils.api";
import React, { useEffect } from "react";
import { useAppSelector } from "../../../services/hooks";
import {
  appointmentsProgramsDataSelector,
  companyAppointmentsProgramsSelector,
} from "../../../services/selectors/utils.selectors";
import Loader from "../../loader/loader";
import { IAppointmentsProgram } from "../../../services/types/utils.types";

function CompanyProgramsPopup({
  companyId,
  onClick,
}: {
  companyId: number;
  onClick: (program: Partial<IAppointmentsProgram>) => void;
}) {
  const appointmentsPrograms = useAppSelector(
    companyAppointmentsProgramsSelector
  );
  const appointmentsProgramsData = useAppSelector(
    appointmentsProgramsDataSelector
  );

  const [getAppointmentsPrograms, { isLoading }] =
    useGetCompanyAppointmentsProgramsMutation();
  useEffect(() => {
    getAppointmentsPrograms({ companyId });
  }, [getAppointmentsPrograms, companyId]);
  const isValid = (a: any) => a !== undefined && a !== null && a !== 0;

  return (
    <article className={styles.popup}>
      {isLoading ? (
        <Loader />
      ) : appointmentsPrograms.length > 0 ? (
        <ul className={`options ${styles.options}`}>
          {appointmentsPrograms.map((a, index) => (
            <li
              key={index}
              className={`option ${styles.option}`}
              onClick={() => onClick(a)}
            >
              {a.name}
              <ul className={styles.option__content}>
                <li>
                  שם: <span className={styles.label}>{a.name}</span>
                </li>
                <li>
                  מספר מפגשים:{" "}
                  <span className={styles.label}>{a.countOfAppointments}</span>
                </li>
                <li>
                  איש מקצוע:
                  <span className={styles.label}>
                    {appointmentsProgramsData.Roles.find(
                      (o) => o.value === a?.roleId
                    )?.label || a?.roleId}
                  </span>{" "}
                </li>
                <li>
                  תדירות:
                  <span className={styles.label}>
                    {appointmentsProgramsData.AppointmentsSequence.find(
                      (o) => o.value === a?.appointmentsSequenceId
                    )?.label || a?.appointmentsSequenceId}{" "}
                  </span>
                </li>
                <li>
                  משך הפגישה:
                  <span className={styles.label}>
                    {appointmentsProgramsData.AppointmentsDurations.find(
                      (o) => o.value === a?.appointmentsDurationsId
                    )?.label || a?.appointmentsDurationsId}
                  </span>
                </li>
                <li>
                  אופן הטיפול:
                  <span className={styles.label}>
                    {appointmentsProgramsData.Medias.find(
                      (o) => o.value === a?.mediasId
                    )?.label || a?.mediasId}
                  </span>
                </li>
                {isValid(a.vetek) && (
                  <li>
                    ותק: <span className={styles.label}>{a.vetek}</span>
                  </li>
                )}
                {isValid(a.genderId) && (
                  <li>
                    מגדר:
                    <span className={styles.label}>
                      {appointmentsProgramsData.Genders.find(
                        (o) => o.value === a?.genderId
                      )?.label || a.genderId}
                    </span>
                  </li>
                )}
                {isValid(a.migzarId) && (
                  <li>
                    מגזר:
                    <span className={styles.label}>
                      {appointmentsProgramsData.Migzars.find(
                        (o) => o.value === a?.migzarId
                      )?.label || a.migzarId}
                    </span>
                  </li>
                )}
                {isValid(a.specializationsId) && (
                  <li>
                    התמחות:
                    <span className={styles.label}>
                      {appointmentsProgramsData.Specializations.find(
                        (o) => o.value === a?.specializationsId
                      )?.label || a.specializationsId}
                    </span>
                  </li>
                )}
                {a.comments && <li>הערות: {a.comments}</li>}
              </ul>
            </li>
          ))}
        </ul>
      ) : (
        <p className={"text"}>No appointments</p>
      )}
    </article>
  );
}
export default CompanyProgramsPopup;
