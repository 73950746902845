import AddOrEditOptionForm from "../forms/addOrEditOption/addOrEditOptionForm";
import addOrEditOptionsStyles from "../../pages/addOrEditOptions/addOrEditOptions.module.css";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { questionOptionInitialState } from "../../utils/constants";
import { TQuestionOption } from "../../services/types/utils.types";
import { INumberOption } from "../../types/types";
import { useAddOrEditQuestionsOptionsMutation } from "../../services/api/utils.api";

interface IAddNewOptionProps {
  id: string;
  groupsSelectOptions: INumberOption[];
  setShowAddNewOptionForm: Dispatch<SetStateAction<boolean>>;
  setOpenModalSuccess: Dispatch<SetStateAction<boolean>>;
  setOpenErrorModal: Dispatch<SetStateAction<boolean>>;
  seq: number | undefined;
  groupId: number | undefined;
}

function AddNewOption({
  id,
  setShowAddNewOptionForm,
  setOpenModalSuccess,
  setOpenErrorModal,
  seq,
  groupId,
}: IAddNewOptionProps) {
  const [addOptionForm, setAddOptionForm] = useState<TQuestionOption>(
    questionOptionInitialState
  );

  const [addOrEditOptions] = useAddOrEditQuestionsOptionsMutation();

  useEffect(() => {
    if (seq) {
      setAddOptionForm((prev) => ({ ...prev, seq }));
    }
    if (groupId) {
      setAddOptionForm((prev) => ({ ...prev, groupId }));
    }
  }, [seq, groupId]);

  const addNewOption = () => {
    id &&
      addOrEditOptions({
        questionsId: id,
        options: [{ ...addOptionForm, questionsId: Number(id) }],
      })
        .then((res) => {
          if ("data" in res) {
            setAddOptionForm(questionOptionInitialState);
            setShowAddNewOptionForm(false);
            setOpenModalSuccess(true);
          }
        })
        .catch((err) => {
          setOpenErrorModal(true);
          console.log(err);
        });
  };

  const isFormValid = () => {
    return addOptionForm.desc;
  };

  return (
    <>
      <AddOrEditOptionForm
        form={addOptionForm}
        onFormChange={(updatedOption) => setAddOptionForm(updatedOption)}
      />
      <button
        type={"submit"}
        className={`button ${addOrEditOptionsStyles.submitBtn} ${
          !isFormValid() && addOrEditOptionsStyles.submitBtn_disabled
        } `}
        disabled={!isFormValid()}
        onClick={addNewOption}
      >
        {"לשמור בבסיס הנתונים"}
      </button>
    </>
  );
}

export default AddNewOption;
