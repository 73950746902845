import {
  IQuestion,
  IQuestionnaireData,
  TQuestionOption,
  TUtilsState,
  TGroup,
  ICompanyGroupsRRMHeaders,
  ICompanyGroupsRRM,
  IQuestionnaireCompaniesHeaders,
  IQuestionnaireCompanies,
  IQuestionnaireCompaniesTypes,
  IAppointmentsProgramsDataWithLabels,
  TAppointmentsProgramsTherapist,
  TAppointmentsProgramsData,
  IAppointmentsProgramsMetaData,
  IAppointment,
  TGeneralRRM,
} from "../types/utils.types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { utilsApi } from "../api/utils.api";
import { sortOptionsByGroup } from "../../utils/utils";
import { TExercise, TExercisesCategory } from "../../utils/constants";

const appointmentsProgramsDataInitialState: IAppointmentsProgramsDataWithLabels =
  {
    Medias: [],
    Roles: [],
    Periods: [],
    AppointmentsDurations: [],
    AppointmentsSequence: [],
    Genders: [],
    Migzars: [],
    Specializations: [],
    AppointmentsProgramsStatus: [],
  };
const initialState: TUtilsState = {
  questionnaires: [],
  questionnairesOptions: [],
  questionnaireCompaniesHeaders: [],
  questionnaireCompanies: [],
  questionnaireCompaniesTypes: [],
  questions: [],
  questionsSortedByQuestionnaire: {},
  questionOptions: [],
  questionOptionsGroupped: [],
  nextQuestionOptions: [],
  groups: [],
  companyGeneralRRM: [],
  companyGroupsRRMHeaders: [],
  companyGroupsRRM: [],
  features: [],
  exercises: [],
  exercisesCategories: [],
  exercisesCategoriesSelectOptions: [],
  error: "",
  appointmentsProgramsData: appointmentsProgramsDataInitialState,
  appointmentsProgramsTherapist: [],
  userAppointmentsPrograms: [],
  companyAppointmentsPrograms: [],
  appointmentsProgramsSchedule: [],
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {
    resetQuestions: (state) => {
      return { ...state, questions: [] };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        utilsApi.endpoints.getQuestionnaires.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IQuestionnaireData[]>) => {
          return {
            ...state,
            questionnaires: action.payload,
            questionnairesOptions: [
              { value: "0", label: "ללא שאלון", id: 0 },
              ...action.payload.map((q) => ({
                value: q.id.toString(),
                id: q.id,
                label: `${q.desc}: ${q.statusId === 1 ? "פעיל" : "לא פעיל"}`,
              })),
            ],
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditQuestionnaire.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IQuestionnaireData[]>) => {
          if (!Array.isArray(action.payload)) {
            return state; // Early return, no state update
          }
          return { ...state, questionnaires: action.payload };
        }
      )
      .addMatcher(
        utilsApi.endpoints.deleteQuestionnaire.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IQuestionnaireData[]>) => {
          return { ...state, questionnaires: action.payload };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getExercises.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TExercise[]>) => {
          return {
            ...state,
            exercises: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditExercises.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TExercise[]>) => {
          return {
            ...state,
            exercises: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.deleteExercises.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TExercise[]>) => {
          return {
            ...state,
            exercises: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getExercisesCategories.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TExercisesCategory[]>) => {
          return {
            ...state,
            exercisesCategories: action.payload,
            exercisesCategoriesSelectOptions: action.payload.map((c) => ({
              value: c.name,
              id: c.id,
              label: c.name,
            })),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditExercisesCategories.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TExercisesCategory[]>) => {
          return {
            ...state,
            exercisesCategories: action.payload,
            exercisesCategoriesSelectOptions: action.payload.map((c) => ({
              value: c.name,
              id: c.id,
              label: c.name,
            })),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.deleteExercisesCategories.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TExercisesCategory[]>) => {
          return {
            ...state,
            exercisesCategories: action.payload,
            exercisesCategoriesSelectOptions: action.payload.map((c) => ({
              value: c.name,
              id: c.id,
              label: c.name,
            })),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getQuestions.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IQuestion[]>) => {
          return {
            ...state,
            questions: action.payload.sort((a, b) => a.seq - b.seq),
            nextQuestionOptions: [
              ...action.payload.map((q) => ({
                value: q.id.toString(),
                label: q.desc,
                id: q.id,
              })),
              {
                value: "null",
                label: "ללא שאלה הבאה",
                id: null,
              },
            ],
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.deleteQuestions.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IQuestion[]>) => {
          return {
            ...state,
            questions: action.payload.sort((a, b) => a.seq - b.seq),
            nextQuestionOptions: [
              ...action.payload.map((q) => ({
                value: q.id.toString(),
                label: q.desc,
                id: q.id,
              })),
              {
                value: "null",
                label: "ללא שאלה הבאה",
                id: null,
              },
            ],
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getQuestionsOptions.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TQuestionOption[]>) => {
          return {
            ...state,
            questionOptions: action.payload,
            questionOptionsGroupped: sortOptionsByGroup(action.payload),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditQuestionsOptions.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TQuestionOption[]>) => {
          return {
            ...state,
            questionOptions: action.payload,
            questionOptionsGroupped: sortOptionsByGroup(action.payload),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.deleteQuestionsOptions.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TQuestionOption[]>) => {
          return {
            ...state,
            questionOptions: action.payload,
            questionOptionsGroupped: sortOptionsByGroup(action.payload),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getQuestionsGroups.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TGroup[]>) => {
          return {
            ...state,
            groups: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditQuestionsGroups.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TGroup[]>) => {
          return {
            ...state,
            groups: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.deleteQuestionsGroups.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TGroup[]>) => {
          return {
            ...state,
            groups: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getCompanyGroupsRRMHeaders.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<ICompanyGroupsRRMHeaders[]>
        ) => {
          return {
            ...state,
            companyGroupsRRMHeaders: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditCompanyGroupsRRMHeaders.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<ICompanyGroupsRRMHeaders[]>
        ) => {
          return {
            ...state,
            companyGroupsRRMHeaders: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.deleteCompanyGroupsRRMHeaders.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<ICompanyGroupsRRMHeaders[]>
        ) => {
          return {
            ...state,
            companyGroupsRRMHeaders: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getCompanyGroupsRRM.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<ICompanyGroupsRRM[]>) => {
          return {
            ...state,
            companyGroupsRRM: action.payload.sort((a, b) => a.seq - b.seq),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditCompanyGroupsRRM.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<ICompanyGroupsRRM[]>) => {
          return {
            ...state,
            companyGroupsRRM: action.payload.sort((a, b) => a.seq - b.seq),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getCompanyGeneralRRM.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TGeneralRRM[]>) => {
          return {
            ...state,
            companyGeneralRRM: action.payload.sort(
              (a, b) => a.rrmseq - b.rrmseq
            ),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditCompanyGeneralRRM.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TGeneralRRM[]>) => {
          return {
            ...state,
            companyGeneralRRM: action.payload.sort(
              (a, b) => a.rrmseq - b.rrmseq
            ),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.deleteCompanyGeneralRRM.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<TGeneralRRM[]>) => {
          return {
            ...state,
            companyGeneralRRM: action.payload.sort(
              (a, b) => a.rrmseq - b.rrmseq
            ),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getQuestionnaireCompaniesHeaders.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<IQuestionnaireCompaniesHeaders[]>
        ) => {
          return {
            ...state,
            questionnaireCompaniesHeaders: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditQuestionnaireCompaniesHeaders
          .matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<IQuestionnaireCompaniesHeaders[]>
        ) => {
          return {
            ...state,
            questionnaireCompaniesHeaders: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.deleteQuestionnaireCompaniesHeaders.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<IQuestionnaireCompaniesHeaders[]>
        ) => {
          return {
            ...state,
            questionnaireCompaniesHeaders: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getQuestionnaireCompanies.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<IQuestionnaireCompanies[]>
        ) => {
          console.log(action);
          return {
            ...state,
            questionnaireCompanies: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.addOrEditQuestionnaireCompanies.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<IQuestionnaireCompanies[]>
        ) => {
          return {
            ...state,
            questionnaireCompanies: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.deleteQuestionnaireCompanies.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<IQuestionnaireCompanies[]>
        ) => {
          return {
            ...state,
            questionnaireCompanies: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getQuestionnaireCompaniesTypes.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<IQuestionnaireCompaniesTypes[]>
        ) => {
          return {
            ...state,
            questionnaireCompaniesTypes: action.payload.map((t) => ({
              value: t.id,
              label: t.name,
            })),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getAppointmentsProgramsTherapists.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<TAppointmentsProgramsTherapist[]>
        ) => {
          return {
            ...state,
            appointmentsProgramsTherapist: action.payload.map((t) => ({
              ...t,
              value: t.therapistsId,
              label: t.fullName,
            })),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getAppointmentsProgramsByUserId.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<TAppointmentsProgramsData[]>
        ) => {
          return {
            ...state,
            userAppointmentsPrograms: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getCompanyAppointmentsPrograms.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<TAppointmentsProgramsData[]>
        ) => {
          return {
            ...state,
            companyAppointmentsPrograms: action.payload.map((a) => {
              const { appointmentsProgram } = a;
              const {
                name,
                appointmentsDurationsId,
                appointmentsDurationsOpenMinutes,
                appointmentsSequenceId,
                appointmentsSequenceOpenCountInPeriod,
                countOfAppointments,
                genderId,
                roleId,
                specializationsId,
                comments,
                mediasId,
                migzarId,
                vetek,
              } = appointmentsProgram;
              return {
                name,
                appointmentsDurationsId,
                appointmentsDurationsOpenMinutes,
                appointmentsSequenceId,
                appointmentsSequenceOpenCountInPeriod,
                countOfAppointments,
                genderId,
                roleId,
                specializationsId,
                comments,
                mediasId,
                migzarId,
                vetek,
              };
            }),
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.postAppointmentsPrograms.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<TAppointmentsProgramsData[]>
        ) => {
          return {
            ...state,
            userAppointmentsPrograms: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.deleteAppointmentsPrograms.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<TAppointmentsProgramsData[]>
        ) => {
          return {
            ...state,
            userAppointmentsPrograms: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getAppointmentsProgramsSchedule.matchFulfilled,
        (state: TUtilsState, action: PayloadAction<IAppointment[]>) => {
          return {
            ...state,
            appointmentsProgramsSchedule: action.payload,
          };
        }
      )
      .addMatcher(
        utilsApi.endpoints.getAllMetaTables.matchFulfilled,
        (
          state: TUtilsState,
          action: PayloadAction<IAppointmentsProgramsMetaData>
        ) => {
          const appointmentsSequenceWithLabels =
            action.payload.AppointmentsSequence.map((sequence) => {
              const period = action.payload.Periods.find(
                (p) => p.Id === sequence.PeriodsId
              );
              let label = `${sequence.CountInPeriod} ב${
                period ? period.Name : "Unknown"
              }`;
              label = label.replace("1", "פעם").replace("2", "פעמיים");
              return {
                ...sequence,
                value: sequence.Id,
                label,
              };
            });
          return {
            ...state,
            appointmentsProgramsData: {
              ...state.appointmentsProgramsData,
              AppointmentsDurations:
                action.payload?.AppointmentsDurations.filter(
                  (d) => d.Minutes !== null
                ).map((d) => ({
                  ...d,
                  value: d.Id,
                  label: d.Minutes || "No duration",
                })),
              AppointmentsSequence: appointmentsSequenceWithLabels,
              Medias: action.payload?.Medias.map((m) => ({
                ...m,
                value: m.Id,
                label: m.Name || "",
              })),
              Roles: action.payload?.Roles.map((r) => ({
                ...r,
                value: r.Id,
                label: r.Name,
              })),
              Genders: action.payload?.Genders.map((g) => ({
                ...g,
                value: g.Id,
                label: g.Name,
              })),
              Migzars: action.payload?.Migzars.map((m) => ({
                ...m,
                value: m.Id,
                label: m.Name,
              })),
              Specializations: action.payload.Specializations?.map((s) => ({
                ...s,
                value: s.Id,
                label: s.Name,
              })),
              AppointmentsProgramsStatus:
                action.payload.AppointmentsProgramsStatus?.map((s) => ({
                  ...s,
                  value: s.Id,
                  label: s.Desc,
                })),
            },
          };
        }
      );
  },
});

export const { actions, reducer } = utilsSlice;
export default utilsSlice.reducer;
