import styles from "./addOrEditOption.module.css";
import questionnaireStyles from "../../../pages/questionnaires/questionnaires.module.css";
import optionsPageStyles from "../../../pages/addOrEditOptions/addOrEditOptions.module.css";
import formStyles from "../forms.module.css";
import Select from "react-select";
import React, { useState } from "react";
import { TQuestionOption } from "../../../services/types/utils.types";
import { useAppSelector } from "../../../services/hooks";
import { nextQuestionOptionsSelector } from "../../../services/selectors/utils.selectors";
import Modal from "../../modals/modal/Modal";
import DeleteConfirmation from "../../modals/deleteConfirmation/deleteConfirmation";
import { useDeleteQuestionsOptionsMutation } from "../../../services/api/utils.api";
import GeneralSuccessPopup from "../../modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../../modals/errorPopup/errorPopup";

function AddOrEditOptionForm({
  onFormChange,
  form,
}: {
  onFormChange: (updatedOption: TQuestionOption) => void;
  form: TQuestionOption | undefined;
}) {
  const nextQuestionOptions = useAppSelector(nextQuestionOptionsSelector);

  const [openModal, setOpenModal] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const [deleteOption] = useDeleteQuestionsOptionsMutation();

  const onDelete = () => {
    setOpenModal(true);
  };
  const handleDelete = () => {
    if (form?.id) {
      deleteOption({ questionsId: form.questionsId, option: form })
        .then((res) => {
          if (res && "data" in res) {
            setOpenModalSuccess(true);
          }
        })
        .catch(() => setOpenModalError(true));
    }
  };

  if (!form) return null;
  return (
    <li className={optionsPageStyles.option}>
      <p className={styles.form__noMargin}>{form.id}</p>
      <p className={styles.form__noMargin}>{form.seq}</p>
      <textarea
        className={`${formStyles.form__input}`}
        name="desc"
        id="desc"
        value={form.desc}
        onChange={(e) => onFormChange({ ...form, desc: e.target.value })}
      />
      <Select
        name={"nextQuestionId"}
        options={nextQuestionOptions}
        value={nextQuestionOptions.find((o) => o.id === form.nextQuestionId)}
        placeholder={"אנא בחר שאלה הבאה"}
        className={`${optionsPageStyles.select} `}
        onChange={(value) => {
          onFormChange({ ...form, nextQuestionId: value?.id || null });
        }}
      />
      <input
        type={"number"}
        className={`${formStyles.form__input}`}
        name="factorAnswer"
        id="factorAnswer"
        value={form.factorAnswer || 0}
        onChange={(e) =>
          onFormChange({ ...form, factorAnswer: Number(e.target.value) })
        }
      />
      <span>{form.statusId === 1 ? "פעיל" : "לא פעיל"}</span>
      <button
        type="button"
        className={`${questionnaireStyles.btn} ${
          questionnaireStyles.btn_type_delete
        } ${!form.id && "button_disabled"}`}
        onClick={onDelete}
        disabled={!form.id}
      />
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <DeleteConfirmation
            onDelete={handleDelete}
            text={`האם את/ה בטוח/ה שברצונך למחוק את האופציה: ${form?.desc}?`}
            onClose={() => {
              setOpenModal(false);
            }}
          />
        </Modal>
      )}
      {openModalSuccess && (
        <Modal onClose={() => setOpenModalSuccess(false)}>
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModalSuccess(false);
            }}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
    </li>
  );
}

export default AddOrEditOptionForm;
