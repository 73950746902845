import React, { useState } from "react";
import { Category } from "../../types/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import { mobileWidth } from "../../utils/constants";
import { useAppSelector } from "../../services/hooks";
import { isAuthSelector } from "../../services/selectors/auth.selectors";
import { useGetUserQuery } from "../../services/api/user.api";
import Loader from "../loader/loader";
import { useRoutes } from "../../hooks/useRoutes";
import { adminSelector } from "../../services/selectors/user.selectors";

function App() {
  const mobile = useMediaQuery(mobileWidth);

  const isAuth = useAppSelector(isAuthSelector);
  const admin = useAppSelector(adminSelector);
  const { isLoading: isUserLoading } = useGetUserQuery({ skip: false });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeCategory, setActiveCategory] = useState(Category.Main);
  const changeCategory = (e: React.MouseEvent, cat: Category) => {
    if (!mobile) {
      e?.stopPropagation();
    }
    setActiveCategory(cat);
  };

  const routes = useRoutes(isAuth, changeCategory, admin.fakeCompanyId);

  return isUserLoading ? <Loader /> : routes;
}

export default App;
