import styles from "../forms.module.css";
import Select from "react-select";
import {
  initialCompanyGeneralRRM,
  statusOptions,
} from "../../../utils/constants";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useAddOrEditCompanyGeneralRRMMutation } from "../../../services/api/utils.api";
import { useAppSelector } from "../../../services/hooks";
import Modal from "../../modals/modal/Modal";
import GeneralSuccessPopup from "../../modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../../modals/errorPopup/errorPopup";
import { adminSelector } from "../../../services/selectors/user.selectors";
import { TGeneralRRM } from "../../../services/types/utils.types";
import CloseButton from "../../closeButton/closeButton";
import useAutosizeTextArea from "../../../hooks/useAutosizeTextarea";

function AddOrEditGeneralRRMForm({
  closeForm,
  seq,
  RRMToEdit,
}: {
  closeForm: () => void;
  seq: number;
  RRMToEdit: null | TGeneralRRM;
}) {
  const admin = useAppSelector(adminSelector);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [form, setForm] = useState({
    ...initialCompanyGeneralRRM,
    rrmseq: seq,
  });
  const [touched, setTouched] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const [addOrEditRRM, { isLoading }] = useAddOrEditCompanyGeneralRRMMutation();

  useEffect(() => {
    if (RRMToEdit) {
      setForm((prev) => ({ ...prev, ...RRMToEdit }));
    }
  }, [setForm, RRMToEdit]);

  useAutosizeTextArea(textAreaRef.current, form.anliza || "");

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTouched(true);
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const onSubmit = () => {
    addOrEditRRM({
      companyId: admin.companyId,
      rrm: [{ ...form, companyId: admin.companyId }],
    })
      .then((res) => {
        if (res && "data" in res) {
          setTouched(false);
          setOpenModalSuccess(true);
          closeForm();
        }
      })
      .catch((err) => {
        setOpenModalError(true);
        console.log(err);
      });
  };

  return (
    <form className={styles.form}>
      <CloseButton onClick={closeForm} />
      <ul
        className={`${styles.form__list} ${styles.form__list_type_threeColumns} ${styles.form__list_type_lastTwoLiWide}`}
      >
        <li>
          <label htmlFor="title" className={styles.form__label}>
            כותרת
          </label>
          <input
            type={"text"}
            className={`${styles.form__input}`}
            name="title"
            id="title"
            value={form.title || ""}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="color" className={styles.form__label}>
            צבע
          </label>
          <input
            className={`${styles.form__input}`}
            type="color"
            name="color"
            id="color"
            value={form.color || "#ffffff"}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="statusId" className={styles.form__label}>
            סטטוס
          </label>
          <Select
            name={"statusId"}
            options={statusOptions}
            value={form.statusId === 1 ? statusOptions[0] : statusOptions[1]}
            placeholder={"אנא בחר סוג סטטוס"}
            className={styles.select}
            onChange={(value) => {
              setTouched(true);
              value &&
                setForm((prev) => ({ ...prev, statusId: Number(value.value) }));
            }}
          />
        </li>
        <li>
          <label htmlFor="desc" className={styles.form__label}>
            תיאור
          </label>
          <textarea
            className={`${styles.form__input}`}
            name="desc"
            id="desc"
            value={form.desc || ""}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="recommendations" className={styles.form__label}>
            המלצות
          </label>
          <textarea
            className={`${styles.form__input} ${styles.form__textArea}`}
            name="recommendations"
            id="recommendations"
            value={form.recommendations || ""}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="highlights" className={styles.form__label}>
            הדגשות
          </label>
          <textarea
            className={`${styles.form__input} ${styles.form__textArea}`}
            name="highlights"
            id="highlights"
            value={form.highlights || ""}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="conversation" className={styles.form__label}>
            שיחה
          </label>
          <textarea
            className={`${styles.form__input} ${styles.form__textArea}`}
            name="conversation"
            id="conversation"
            value={form.conversation || ""}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="anliza" className={styles.form__label}>
            אנליזה
          </label>
          <textarea
            ref={textAreaRef}
            className={`${styles.form__input} ${styles.form__textArea}`}
            name="anliza"
            id="anliza"
            value={form.anliza || ""}
            onChange={handleChange}
          />
        </li>
      </ul>
      <button
        type={"button"}
        className={`button button_white ${styles.form__btn} ${
          (isLoading || !touched || !form.title) && styles.form__btn_disabled
        } `}
        disabled={isLoading || !touched || !form.title}
        onClick={onSubmit}
      >
        {isLoading ? "שליחה" : "שמור שינוים"}
      </button>
      {openModalSuccess && (
        <Modal
          onClose={() => {
            setOpenModalSuccess(false);
          }}
        >
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModalSuccess(false);
            }}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
    </form>
  );
}

export default AddOrEditGeneralRRMForm;
