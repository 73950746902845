import {
  IBooleanOption,
  IOption,
  TAddQuestionForm,
  TLabeledItem,
  TQuestionnaireTypeOption,
  TReportQuestionOption,
} from "../types/types";
import {
  IAppointmentsProgram,
  ICompanyGroupsRRM,
  ICompanyGroupsRRMHeaders,
  IQuestion,
  IQuestionnaireCompanies,
  IQuestionnaireCompaniesHeaders,
  IQuestionnaireCompaniesTypes,
  IQuestionnaireData,
  TAddFeatureRequest,
  TGeneralRRM,
  TGroup,
  TQuestionOption,
} from "../services/types/utils.types";
import { StylesConfig } from "react-select";
import {
  TQuestionnaireEntry,
  TRRMListEntry,
} from "../services/types/user.types";

export const TEST_BASE_URL = "https://api-test.bpreven.com/";
export const BASE_URL = "https://api.bpreven.com/";
// export const BASE_URL = "http://localhost:7241/";

export const mobileWidth = "(max-width: 600px)";
export const actionReportId = 27;

export const searchOptions: TLabeledItem[] = [
  { value: "Desc", label: "שם" },
  { value: "Symbol", label: "סימן" },
  { value: "StartQuestion", label: "שאלה ראשונית" },
  { value: "Status", label: "סטטוס" },
];
export const searchFeaturesOptions: TLabeledItem[] = [
  { value: "all", label: "הכל" },
  { value: "Symbol", label: "סימן" },
  { value: "[Desc]", label: "שם" },
  { value: "Number", label: "מספר" },
];
// export const searchLibraryOptions: TLabeledItem[] = [
//   { value: "all", label: "הכל" },
//   { value: "Exercises.Symbol", label: "סימן" },
//   { value: "Exercises.About", label: "תיאור" },
//   { value: "Exercises.StatusId", label: "סטטוס" },
//   { value: "Exercises.Title", label: "כותרת" },
// ];

export const questionnaireTypes: TQuestionnaireTypeOption[] = [
  { value: "Q", label: "רגיל", id: 1, Symbol: "Q", Name: "רגיל" },
  { value: "QR", label: "הרשמה", id: 2, Symbol: "QR", Name: "הרשמה" },
  {
    value: "QG",
    label: "כניסת אורח ראשוני",
    id: 3,
    Symbol: "QG",
    Name: "כניסת אורח ראשוני",
  },
  {
    value: "QT",
    label: "שאלון מטפלים",
    id: 4,
    Symbol: "QT",
    Name: "שאלון מטפלים",
  },
];
export const nextQuestionOptionsInitialState = [
  { value: "OptionId", label: "לפי אופציות" },
];
export const yesNoOptions: TLabeledItem[] = [
  { value: "0", label: "לא" },
  { value: "1", label: "כן" },
];
export const statusOptions: TLabeledItem[] = [
  { value: "1", label: "פעיל" },
  { value: "0", label: "לא פעיל" },
];
export const questionnaireCategoryOptions: TLabeledItem[] = [
  { value: "רגיל", label: "רגיל" },
  { value: "סקר", label: "סקר" },
];
export const periodOptions: TLabeledItem[] = [
  { value: "monthly", label: "חודשי" },
  { value: "dayly", label: "יומי" },
  { value: "weekly", label: "שבועי" },
];
export const dayOptions: TLabeledItem[] = Array.from(
  { length: 31 },
  (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  })
);
export const weekOptions: TLabeledItem[] = Array.from(
  { length: 5 },
  (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  })
);
export const monthOptions: TLabeledItem[] = Array.from(
  { length: 12 },
  (_, index) => ({
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  })
);
export const typeDataOptions: TLabeledItem[] = [
  { value: "1", label: "אופציות" },
  { value: "2", label: "טקסט" },
  { value: "3", label: "מספר" },
  { value: "4", label: "תאריך" },
  { value: "5", label: "משפט" },
];
export const addOrEditQuestionnaireInitialState: Partial<IQuestionnaireData> = {
  id: 0,
  desc: "",
  symbol: "",
  statusId: 1,
  factorQuestionnaire: 1,
  category: "רגיל",
};
export const addNewFeatureInitialState: TAddFeatureRequest = {
  Name: "",
  Symbol: "",
  number: 0,
};
export const addNewExerciseInitialState: TExercise = {
  id: 0,
  title: "",
  about: "",
  companyId: 0,
  statusId: 1,
  exercisesCategoriesId: 0,
  generalRRMSEQ: 1,
};

export const addNewQuestionInitialState: TAddQuestionForm = {
  DescQ: "",
  IsEnd: false,
  NameQ: "",
  StatusId: 1,
  typeData: "OptionId",
  DescDataType: "OptionId",
};
export const editQuestionFormInitialState: Partial<IQuestion> = {
  desc: "",
  id: 0,
  nextQuestionId: null,
  questionnaireId: 0,
  dataTypesId: 1,
  seq: 1,
  statusId: 1,
  factorQuestion: 1,
  companyGroupsRRMHeadersId: 0,
  optionsCountMin: null,
  optionsCountMax: null,
  isSpecific: false,
};
export const questionOptionInitialState: TQuestionOption = {
  id: 0,
  desc: "",
  questionsId: 0,
  groupId: null,
  groups: null,
  nextQuestionId: 0,
  nextQuestions: null,
  seq: 0,
  factorAnswer: 1,
  statusId: 1,
};
export const questionGroupInitialState: TGroup = {
  id: 0,
  name: "",
  questionsId: 0,
  seq: 0,
  factorGroup: 1,
  statusId: 1,
  optionsCountMin: 0,
  optionsCountMax: null,
};

export const findLatestQuestionnaire = (
  id: number,
  questionnaires: TQuestionnaireEntry[]
): TQuestionnaireEntry | undefined => {
  if (questionnaires.length === 0) return undefined;
  const temp = questionnaires.filter((q) => q.QuestionnaireId === id);
  return temp.length
    ? temp
        .filter((q) => q.QuestionnaireId === id)
        .reduce((latest, current) => {
          const latestDate = new Date(latest.TimeStamp);
          const currentDate = new Date(current.TimeStamp);
          return currentDate > latestDate ? current : latest;
        })
    : undefined;
};

export const RRMListDefault: TRRMListEntry[] = [
  {
    Id: 1,
    Title: "A",
    Desc: null,
    Color: "#5d3cc7",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 2,
    Title: "B",
    Desc: null,
    Color: "#4f0080",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 3,
    Title: "C",
    Desc: null,
    Color: "#fffe02",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 4,
    Title: "D",
    Desc: null,
    Color: "#ff7654",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 5,
    Title: "E",
    Desc: null,
    Color: "#ff7803",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
  {
    Id: 6,
    Title: "F",
    Desc: null,
    Color: "#cd2701",
    Min: 0,
    Max: 0,
    Anliza: "",
    Recommendations: "",
    Highlights: "",
    Conversation: "",
  },
];

export const noDataLabel = "אין מידע";

export const selectCustomStyles: StylesConfig<IOption, false> = {
  control: (provided) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "24px",
    height: "24px",
  }),

  valueContainer: (provided) => ({
    ...provided,
    height: "24px",
    padding: "0 6px",
  }),

  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: "24px",
  }),
};

export function calculateAge(birthDateString: string): number {
  const today = new Date();
  const birthDate = new Date(birthDateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // Check if the birth month hasn't come yet this year, or if it's the same month but the day hasn't come yet
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

export function findMinAndMax(items: TRRMListEntry[] | null): {
  min: number;
  max: number;
} {
  if (!items || items.length === 0) {
    throw new Error("The array is empty");
  }

  let min = items[0].Min;
  let max = items[0].Max;

  for (const item of items) {
    if (item.Min < min) {
      min = item.Min;
    }
    if (item.Max > max) {
      max = item.Max;
    }
  }

  return { min, max };
}

export function filterLastOfEachGroup(
  options: TLabeledQuestionOption[]
): TLabeledQuestionOption[] {
  if (!options.length || !options[0]?.GroupId) {
    return options;
  } else {
    const groupMap = options.reduce<Record<number, TLabeledQuestionOption>>(
      (acc, item) => {
        // @ts-ignore
        acc[item.GroupId] = item; // Overwrites previous entry with same GroupId with the current item
        return acc;
      },
      {}
    );

    return Object.values(groupMap);
  }
}

export const getSelectNote = (
  min: number | null,
  max: number | null
): string => {
  let text = "";
  if (!min) return text;
  if (min) {
    min === 1
      ? max === 1
        ? (text = "*יש לבחור אפשרות אחת")
        : (text = "*יש לבחור אפשרות אחת לפחות")
      : (text = `*יש לבחור לפחות ${min} אפשרויות`);
  }
  return text;
};

export const isFormValid = (form: {
  [key: string]: {
    isValid: boolean;
  };
}): boolean => {
  return Object.keys(form).every((k) => form[k].isValid);
};

export type TLabeledQuestionOption = IOption & TReportQuestionOption;
export type TGrouppedQuestionsOptions = {
  label: string;
  options: TLabeledQuestionOption[];
};

export const transformServerOptions = (
  optionsArray: TReportQuestionOption[],
  count: number | null,
  min: null | number
): Array<TGrouppedQuestionsOptions | TLabeledQuestionOption> => {
  const groups: Record<string, TGrouppedQuestionsOptions> = {};
  const noGroup: TLabeledQuestionOption[] = [];

  optionsArray.forEach((option) => {
    if (option.GroupId) {
      const groupKey = `group-${option.GroupId}`;
      if (!groups[groupKey]) {
        groups[groupKey] = {
          label: option.Groups ? option.Groups.Name : "Default Group",
          options: [],
        };
      }
      groups[groupKey].options.push({
        ...option,
        count,
        min,
        value: option.Id.toString(),
        label: option.Desc,
      });
    } else {
      noGroup.push({
        ...option,
        count,
        min,
        value: option.Id.toString(),
        label: option.Desc,
      });
    }
  });
  for (const groupKey in groups) {
    if (groups[groupKey].options) {
      groups[groupKey].options.sort((a, b) => a.Seq - b.Seq);
    }
  }
  noGroup.sort((a, b) => a.Seq - b.Seq);
  return [...Object.values(groups), ...noGroup];
};

// Type guard function for TGrouppedQuestionsOptions
export function isTGrouppedQuestionsOptions(
  object: TGrouppedQuestionsOptions | TLabeledQuestionOption
): object is TGrouppedQuestionsOptions {
  return typeof object === "object" && object !== null && "options" in object;
}

export const initialQuestionState: Partial<IQuestion> = {
  id: 0,
  seq: 0,
  desc: "",
  statusId: 1,
  // isEnd: false,
  // descQuestionnaire: "",
  // typeQ: 0,
  // descDataType: "",
  // nextQuestionDesc: "",
};

export const ITEM_TYPE = {
  GROUP: "GROUP",
  OPTION: "OPTION",
  QUESTION: "QUESTION",
};

export const initialCompanyGroupsRRMHeaders: ICompanyGroupsRRMHeaders = {
  id: 0,
  companyId: 0,
  questionnaireId: 0,
  desc: "",
  isClini: false,
  isDefault: false,
  statusId: 1,
};

export const initialCompanyGeneralRRM: TGeneralRRM = {
  id: 0,
  companyId: 0,
  title: "",
  desc: "",
  color: "#ffffff",
  anliza: "",
  recommendations: "",
  highlights: "",
  conversation: "",
  statusId: 1,
  rrmseq: 1,
};
export const initialCompanyGroupsRRM: ICompanyGroupsRRM = {
  id: 0,
  companyId: 0,
  questionnaireId: null,
  companyGroupsRRMHeadersId: null,
  title: "",
  desc: "",
  color: "",
  anliza: "",
  recommendations: "",
  highlights: "",
  conversation: "",
  start: 0,
  end: 0,
  statusId: 1,
  seq: 0,
};
export const RRMHeadersTypesOptions: IBooleanOption[] = [
  { label: "קליני", value: true },
  { label: "רגיל", value: false },
];

export const treatmentPlanGeneralFilterOptions: TLabeledItem[] = [
  { value: "yearsOfExperience", label: "שנות ניסיון" },
  { value: "men", label: "גברים" },
  { value: "women", label: "נשים" },
];
export const treatmentPlanPsychologistFilterOptions: TLabeledItem[] = [
  ...treatmentPlanGeneralFilterOptions,
  { value: "clinical", label: "קליני" },
  { value: "rehabilitative", label: "שיקומי" },
  { value: "educational", label: "חינוכי" },
  { value: "developmental", label: "התפתחותי" },
  { value: "medical", label: "רפואי" },
];
export interface ITreatmentPlanFormField {
  id: string;
  label: string;
  placeholder: string;
  filterOptions?: TLabeledItem[];
}
export const addTreatmentPlanFormFields = [
  {
    id: "supporter",
    label: "שיוך תומך/ת טיפול",
    placeholder: "אנא בחר ",
    filterOptions: treatmentPlanGeneralFilterOptions,
  },
  {
    id: "psychotherapist",
    label: "שיוך פסיכותרפיסט (MA)",
    placeholder: "אנא בחר...",
    filterOptions: treatmentPlanPsychologistFilterOptions,
  },
  {
    id: "psychologist",
    label: "שיוך פסיכולוג",
    placeholder: "אנא בחר",
    filterOptions: treatmentPlanPsychologistFilterOptions,
  },
  {
    id: "psychiatrist",
    label: "שיוך פסיכיאטר",
    placeholder: "אנא בחר",
    filterOptions: treatmentPlanPsychologistFilterOptions,
  },
  { id: "protocol", label: "שיוך פרוטוקול", placeholder: "אנא בחר" },
];

// Category Type
export type TExercisesCategory = {
  id: number;
  companyId: number;
  name: string;
  statusId: number;
};

// Exercise Type
export type TExercise = {
  id: number;
  title: string;
  about: string;
  exercisesCategoriesId: number; // need to change to []
  companyId: number;
  generalRRMSEQ: number;
  link?: string;
  statusId: number;
  // audioLink?: string;
};

// Mock exercises data
//   {
//     link: "https://media.istockphoto.com/id/1413205857/video/group-of-young-multi-ethnic-gym-class-doing-practice-yoga-lesson-together-on-sunrise-morning.mp4?s=mp4-640x640-is&k=20&c=fSLBrMcv8WrQ9dGEX7tMjY5Fhok681tu66Xsx5JQZLc=",
//     title: "אתה עוצמתי",
//     about:
//       "זוהי מדיטציה מודרכת של 16 דקות מעצימה ומרפאה על להיות כוחני. זוהי תזכורת לכך שאתה יכול להיות בעל מוח מדיטטיבי עמוק. אתה אדם חזק ואתה חשוב! טיפול עצמי ואהבה עצמית הם כל כך חשובים כי אינך יכול להשפיע עמוקות ואוהבת על אנשים אחרים עד שאתה באמת אוהב ודואג לעצמך",
//   },
//   {
//     link: "https://media.istockphoto.com/id/1408178995/video/spa-still-life-stones-massage-and-water-relax-close-up-relaxing-view-of-burning-candles-water.mp4?s=mp4-640x640-is&k=20&c=Npc8krlrGkO29HLwGRKpyq8cGFtB8S95xyzHSbDI8zs=",
//     title: "מדיטציה ללב",
//     about:
//       "הקשיבו למדיטציה מודרכת זו של 20 דקות בכל יום וחקור את האהבה והחמלה שבתוך ליבנו כדי למצוא מצב של רגיעה ושלווה פנימית.",
//   },
//   {
//     link: "https://media.istockphoto.com/id/1295220207/video/serene-african-woman-relieving-stress-at-workplace-do-meditation-practice.mp4?s=mp4-640x640-is&k=20&c=Q4k98iuDSEz0qKFfZIukkbwwL0kfKTa9Dv_rfhaHOHk=",
//     title: "מדיטציה נגד חרדה",
//     about:
//       "נכתב וקריינות על ידי ג'ון דייוויסי. ג'ון הוא מאמן, מורה ודובר לחיים בתשומת לב.",
//   },
//   {
//     link: "https://media.istockphoto.com/id/1399551003/video/mindful-concentration-and-breathing.mp4?s=mp4-640x640-is&k=20&c=5vpaljndH1kbNSFXef66BVeMK3y_oTC8RhhFMb_9Szc=",
//     title: "מדיטציה לריפוי והשתחררות",
//     about:
//       "מדיטציה מודרכת של 10 דקות לריפוי, שחרור ושלווה פנימית. לרפא את הלב והפצעים הרגשיים שלך עם הדמיית אנרגיה חיובית, הקשה על צ'אקרת הלב לריפוי אנרגיה והצהרות חיוביות. מדיטציית ריפוי זו תעזור לך לטפח הכרת תודה, סליחה עצמית ופרספקטיבה למסע שלך. מעולה למתחילים",
//   },
// ];

// Initial state for QuestionnaireCompaniesHeaders
export const addOrEditQuestionnaireCompaniesHeadersInitialState: IQuestionnaireCompaniesHeaders =
  {
    id: 0,
    companyId: 0,
    desc: "",
    isDefault: false,
    statusId: 1,
  };

// Initial state for QuestionnaireCompanies
export const addOrEditQuestionnaireCompaniesInitialState: IQuestionnaireCompanies =
  {
    id: 0,
    companyId: 0,
    questionnaireCompaniesHeadersId: 0,
    questionnaireCompaniesTypeId: 0,
    userQuestionnaireId: 0,
    therapistQuestionnaireId: 0,
    dayly: undefined,
    weekly: undefined,
    monthly: undefined,
    statusId: 1,
  };

// Initial state for QuestionnaireCompaniesTypes
export const initialQuestionnaireCompaniesTypes: IQuestionnaireCompaniesTypes =
  {
    id: 0,
    name: "",
    nameEn: "",
  };

export const initialAppointmentsProgram: IAppointmentsProgram = {
  id: 0,
  companyId: 0,
  name: "",
  usersId: 0,
  roleId: 0,
  specializationsId: 0,
  countOfAppointments: 0,
  appointmentsDurationsId: 0,
  appointmentsDurationsOpenMinutes: null,
  mediasId: 0,
  appointmentsSequenceId: 0,
  appointmentsSequenceOpenCountInPeriod: null,
  createUsersId: 0,
  updateTherapistsUsersId: null,
  updateTherapistsDate: null,
  comments: "",
  statusId: 1,
  dateToDelete: null, // NotMapped property
  deleteType: null, // NotMapped property
};
