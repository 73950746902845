import styles from "./addOrEditQuestion.module.css";
import formStyles from "../forms.module.css";
import Select, { ActionMeta, SingleValue } from "react-select";
import {
  editQuestionFormInitialState,
  statusOptions,
  typeDataOptions,
} from "../../../utils/constants";
import { TLabeledItem, TQuestionnaireTypeOption } from "../../../types/types";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAddOrEditQuestionsMutation } from "../../../services/api/utils.api";
import { IQuestion } from "../../../services/types/utils.types";
import { useAppSelector } from "../../../services/hooks";
import { nextQuestionOptionsSelector } from "../../../services/selectors/utils.selectors";
import Modal from "../../modals/modal/Modal";
import GeneralSuccessPopup from "../../modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../../modals/errorPopup/errorPopup";
import useAutosizeTextArea from "../../../hooks/useAutosizeTextarea";

function AddOrEditQuestionForm({
  question,
}: {
  question: Partial<IQuestion> | undefined;
}) {
  const navigate = useNavigate();
  const { id, qid } = useParams();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const nextQuestionOptions = useAppSelector(nextQuestionOptionsSelector);

  const [form, setForm] = useState(editQuestionFormInitialState);
  const [touched, setTouched] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const [addQuestion, { isLoading }] = useAddOrEditQuestionsMutation();

  // Initialize the form with existing question or default sequence
  useEffect(() => {
    if (question) {
      setForm((prevState) => ({
        ...prevState,
        ...question, // This will update all the corresponding fields
      }));
    } else {
      setForm((prev) => ({ ...prev, seq: nextQuestionOptions?.length }));
    }
  }, [question, nextQuestionOptions]);

  useAutosizeTextArea(textAreaRef.current, form.desc || "");

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTouched(true);
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };
  const onSelectChange = (
    value: SingleValue<TLabeledItem | TQuestionnaireTypeOption>,
    { name }: ActionMeta<TLabeledItem>
  ) => {
    setTouched(true);
    setForm((prev) => ({ ...prev, [name as string]: value?.value }));
  };

  return (
    <form className={styles.form}>
      <ul className={styles.form__list}>
        <li>
          <label htmlFor="desc" className={styles.form__label}>
            שאלה
          </label>
          <textarea
            ref={textAreaRef}
            rows={1}
            className={`${formStyles.form__input}`}
            name="desc"
            id="desc"
            value={form.desc}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="statusId" className={styles.form__label}>
            סטטוס
          </label>
          <Select
            name={"statusId"}
            options={statusOptions}
            value={statusOptions.find(
              (opt) => opt.value === form?.statusId?.toString()
            )}
            placeholder={"אנא בחר סטטוס"}
            className={styles.select}
            onChange={onSelectChange}
          />
        </li>
        <li>
          <label htmlFor="dataTypesId" className={styles.form__label}>
            סוג התשובה
          </label>
          <Select
            name={"dataTypesId"}
            options={typeDataOptions}
            value={
              form?.dataTypesId
                ? typeDataOptions.find(
                    (t) => t.value === form.dataTypesId?.toString()
                  )
                : undefined
            }
            placeholder={"אנא בחר סוג התשובה"}
            className={styles.select}
            onChange={onSelectChange}
          />
        </li>

        <li>
          <label htmlFor="nextQuestionId" className={styles.form__label}>
            השאלה הבאה
          </label>
          <Select
            name={"nextQuestionId"}
            options={nextQuestionOptions}
            value={nextQuestionOptions.find(
              (o) => o.id === form.nextQuestionId
            )}
            placeholder={"אנא בחר שאלה הבאה"}
            className={styles.select}
            onChange={(value) => {
              console.log(value);
              setTouched(true);
              setForm((prev) => ({ ...prev, nextQuestionId: value?.id }));
            }}
          />
        </li>
        <li>
          <label htmlFor="factorQuestion" className={styles.form__label}>
            משקולת שאלה לציון RRM
          </label>
          <input
            type={"number"}
            className={`${formStyles.form__input}`}
            name="factorQuestion"
            id="factorQuestion"
            value={form.factorQuestion}
            onChange={handleChange}
          />
        </li>
        {/*<li>*/}
        {/*  <label*/}
        {/*    htmlFor="companyGroupsRRMHeadersId"*/}
        {/*    className={styles.form__label}*/}
        {/*  >*/}
        {/*    קבוצת RRM*/}
        {/*  </label>*/}
        {/*  <input*/}
        {/*    type={"number"}*/}
        {/*    className={`${formStyles.form__input}`}*/}
        {/*    name="companyGroupsRRMHeadersId"*/}
        {/*    id="companyGroupsRRMHeadersId"*/}
        {/*    value={form.companyGroupsRRMHeadersId || 0}*/}
        {/*    onChange={handleChange}*/}
        {/*  />*/}
        {/*</li>*/}
        <li>
          <label htmlFor="optionsCountMin" className={styles.form__label}>
            מינימום בחירה
          </label>
          <input
            type={"number"}
            className={`${formStyles.form__input}`}
            name="optionsCountMin"
            id="optionsCountMin"
            value={form?.optionsCountMin || undefined}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="optionsCountMax" className={styles.form__label}>
            מקסימום בחירה
          </label>
          <input
            type={"number"}
            className={`${formStyles.form__input}`}
            name="optionsCountMax"
            id="optionsCountMax"
            value={form.optionsCountMax || undefined}
            onChange={handleChange}
          />
        </li>
        <li>
          <input
            type="checkbox"
            checked={!!form?.isSpecific}
            className={`${styles.form__checkbox}`}
            name="isSpecific"
            id="isSpecific"
            onChange={() => {
              setTouched(true);
              setForm((prev) => ({ ...prev, isSpecific: !prev.isSpecific }));
            }}
          />
          <label htmlFor="isSpecific" className={styles.form__label}>
            האם שדה ספציפי
          </label>
          <input
            type={"text"}
            className={`${formStyles.form__input} ${
              !form.isSpecific && styles.form__btn_disabled
            }`}
            name="specificText"
            id="specificText"
            disabled={!form.isSpecific}
            value={form.isSpecific ? form.specificText ?? "" : ""}
            onChange={handleChange}
          />
        </li>
        <li>
          <input
            type="checkbox"
            checked={!!form?.isNoScore}
            className={`${styles.form__checkbox}`}
            name="isNoScore"
            id="isNoScore"
            onChange={() => {
              setTouched(true);
              setForm((prev) => ({ ...prev, isNoScore: !prev.isNoScore }));
            }}
          />
          <label htmlFor="isNoScore" className={styles.form__label}>
            האם שאלה היא לא לציון
          </label>
        </li>
      </ul>
      <button
        type={"submit"}
        className={`button ${styles.form__btn} ${
          (isLoading || !touched || !form.desc) && styles.form__btn_disabled
        } `}
        disabled={isLoading || !touched || !form.desc}
        onClick={(e) => {
          e.preventDefault();
          qid &&
            addQuestion({
              questionnaireId: qid,
              questions: [{ ...form, questionnaireId: Number(qid) }],
            })
              .then((res) => {
                if (res && "data" in res) {
                  setOpenModalSuccess(true);
                }
              })
              .catch((err) => {
                setOpenModalError(true);
                console.log(err);
              });
        }}
      >
        {isLoading ? "שליחה" : id ? "לערוך שאלה " : "ליצור שאלה"}
      </button>
      {openModalSuccess && (
        <Modal onClose={() => setOpenModalSuccess(false)}>
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModalSuccess(false);
              navigate(-1);
            }}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
    </form>
  );
}

export default AddOrEditQuestionForm;
