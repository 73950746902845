import {
  TQuestionOption,
  TQuestionOptionsByGroup,
} from "../services/types/utils.types";
import { TQuestionnaireEntry } from "../services/types/user.types";
import { IOption } from "../types/types";
import { Dispatch, SetStateAction } from "react";
import { noDataLabel, TExercisesCategory } from "./constants";

// export function compareArrays(
//   array1: TQuestionOption[],
//   array2: TQuestionOption[]
// ): TQuestionOption[] {
//   const changes: TQuestionOption[] = [];
//
//   array1.forEach((obj1) => {
//     const obj2 = array2.find((o) => o.Id === obj1.Id);
//     if (!obj2) return;
//
//     if (
//       obj1.Desc !== obj2.Desc ||
//       obj1.GroupId !== obj2.GroupId ||
//       obj1.NextQuestionId !== obj2.NextQuestionId ||
//       obj1.Nextques !== obj2.Nextques
//     ) {
//       changes.push(obj2);
//     }
//   });
//
//   return changes;
// }
export function mergeArrays(
  array1: TQuestionOption[],
  array2: TQuestionOption[]
): TQuestionOption[] {
  return array1.map((obj1) => {
    const obj2 = array2.find((o) => o.id === obj1.id);
    return obj2 || obj1;
  });
}

export function findChanges(
  array1: any[],
  array2: any[]
): {
  descChanged: TQuestionOption[];
  groupIdChanged: TQuestionOption[];
  nextQuestionIdChanged: TQuestionOption[];
  nextquesChanged: TQuestionOption[];
} {
  const descChanged: TQuestionOption[] = [];
  const groupIdChanged: TQuestionOption[] = [];
  const nextQuestionIdChanged: TQuestionOption[] = [];
  const nextquesChanged: TQuestionOption[] = [];

  array1.forEach((obj1) => {
    const obj2 = array2.find((o) => o.Id === obj1.Id);
    if (!obj2) {
      return;
    }

    if (obj1.Desc !== obj2.Desc) {
      descChanged.push(obj2);
    }
    if (obj1.GroupId !== obj2.GroupId) {
      groupIdChanged.push(obj2);
    }
    if (obj1.NextQuestionId !== obj2.NextQuestionId) {
      nextQuestionIdChanged.push(obj2);
    }
    if (obj1.Nextques !== obj2.Nextques) {
      nextquesChanged.push(obj2);
    }
  });

  return {
    descChanged: descChanged,
    groupIdChanged: groupIdChanged,
    nextQuestionIdChanged: nextQuestionIdChanged,
    nextquesChanged: nextquesChanged,
  };
}

export const getCategory = (
  categories: TExercisesCategory[],
  id: number
): string => {
  const category = categories.find((c) => c.id === id);
  return category ? category.name : noDataLabel;
};

export const getTimeFromTimestamp = (date: Date | string) => {
  if (typeof date === "string") {
    date = new Date(date);
  }
  const timestamp = date.toTimeString().split(":");
  return `${timestamp[0]}:${timestamp[1]}`;
};

export function getPrintDate(date: Date, short?: boolean): string {
  return `${date.getDate()}/${date.getMonth() + 1}/${
    !short ? date.getFullYear() : date.getFullYear().toString().substr(-2)
  }`;
}
export const formatDateOptions = (
  questionnaires: TQuestionnaireEntry[],
  questionnaireId: number
): IOption[] => {
  return questionnaires
    .filter((qu) => qu.QuestionnaireId === questionnaireId)
    .map((q) => ({
      label: `${getPrintDate(
        new Date(q.TimeStamp),
        true
      )} (${getTimeFromTimestamp(new Date(q.TimeStamp))}) (${q.Cycle})`,
      value: q.TimeStamp,
    }));
};

export const sortOptionsByGroup = (
  arr: TQuestionOption[]
): TQuestionOptionsByGroup[] => {
  return arr.reduce((acc: TQuestionOptionsByGroup[], obj: TQuestionOption) => {
    // Check if the group already exists in the result array
    let group = acc.find((item) => item.groupId === obj.groupId);

    // If the group doesn't exist, create a new one
    if (!group) {
      group = { groupId: obj.groupId, options: [] };
      acc.push(group);
    }

    // Add the object to the options array of the group
    group.options.push(obj);

    return acc;
  }, []);
};

export const moveOption = (
  draggedOption: TQuestionOption,
  targetOption: TQuestionOption,
  setForm: Dispatch<SetStateAction<TQuestionOption[]>>
) => {
  // setTouched(true);
  setForm((prevOptions: TQuestionOption[]) => {
    const filteredOptions = prevOptions.filter(
      (opt: TQuestionOption) => opt.id !== draggedOption.id
    );

    const targetIndex = filteredOptions.findIndex(
      (opt: TQuestionOption) => opt.id === targetOption.id
    );

    if (targetIndex === -1) return prevOptions; // Return early if target not found

    const targetGroupId = targetOption.groupId;
    const targetGroupOptions = filteredOptions.filter(
      (option) => option.groupId === targetGroupId
    );
    const otherOptions = filteredOptions.filter(
      (option) => option.groupId !== targetGroupId
    );

    const insertIndex =
      draggedOption.seq === null || draggedOption.seq > targetOption.seq
        ? targetIndex // Insert before target
        : targetIndex + 1; // Insert after target

    // Create a new array with the dragged option inserted
    const updatedOptions = [
      ...targetGroupOptions.slice(0, insertIndex),
      { ...draggedOption, groupId: targetGroupId },
      ...targetGroupOptions.slice(insertIndex),
    ];
    return [
      ...updatedOptions.map((opt, index) => ({ ...opt, seq: index + 1 })),
      ...otherOptions,
    ];
  });
};
