import styles from "./questionsList.module.css";
import React, { useCallback, useMemo, useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import { IQuestion } from "../../services/types/utils.types";
import Modal from "../modals/modal/Modal";
import DeleteConfirmation from "../modals/deleteConfirmation/deleteConfirmation";
import { useDeleteQuestionsMutation } from "../../services/api/utils.api";
import GeneralSuccessPopup from "../modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../modals/errorPopup/errorPopup";
import { typeDataOptions } from "../../utils/constants";
import QuestionsActionButtons from "../questionsActionButtons/questionsActionButtons";

const renderQuestion = (
  q: IQuestion,
  index: number,
  isMobile: boolean,
  onDelete: () => void
) => {
  return (
    <li
      key={q.id}
      className={
        isMobile
          ? `listRow_mobile`
          : `${styles.listRow} ${index % 2 === 0 ? styles.listRow_colored : ""}`
      }
    >
      <p className={styles.listCell}>{q.id}</p>
      <p className={styles.listCell}>{q.seq}</p>
      <p className={styles.listCell}>{q.desc}</p>
      <p className={styles.listCell}>{q.statusId === 1 ? "פעיל" : "לא פעיל"}</p>
      <p className={styles.listCell}>
        {typeDataOptions.find((o) => o.value === q.dataTypesId?.toString())
          ?.label || "-"}
      </p>

      <p className={styles.listCell}>
        {q.optionsCountMin || 0} / {q.optionsCountMax || 0}
      </p>
      <p className={styles.listCell}>{q.factorQuestion}</p>
      <QuestionsActionButtons question={q} onDelete={onDelete} />
    </li>
  );
};

function QuestionsList({ questionsToShow }: { questionsToShow: IQuestion[] }) {
  const mobile = useMediaQuery("(max-width: 800px)");

  const [openModal, setOpenModal] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState<
    IQuestion | undefined
  >(undefined);

  const [deleteQuestion] = useDeleteQuestionsMutation();

  const onDeleteButtonClick = useCallback((q: IQuestion) => {
    setQuestionToDelete(q);
    setOpenModal(true);
  }, []);

  const handleDelete = useCallback(() => {
    if (questionToDelete) {
      deleteQuestion({
        questionnaireId: questionToDelete.questionnaireId,
        question: questionToDelete,
      })
        .then((res) => {
          if (res && "data" in res) {
            setOpenModalSuccess(true);
          }
        })
        .catch(() => setOpenModalError(true));
    }
  }, [deleteQuestion, questionToDelete]);

  const questionList = useMemo(
    () =>
      questionsToShow.map((q, index) =>
        renderQuestion(q, index, mobile, () => onDeleteButtonClick(q))
      ),
    [questionsToShow, mobile, onDeleteButtonClick]
  );

  return (
    <ul className={styles.list}>
      <li className={`${styles.listRow} ${styles.listRow_header}`}>
        <p className={styles.listCell}>מס'</p>
        <p className={styles.listCell}>סדר</p>
        <p className={styles.listCell}>שאלה</p>
        <p className={styles.listCell}>סטטוס</p>
        <p className={styles.listCell}>סוג התשובה</p>
        <p className={styles.listCell}>לבחירה</p>
        <p className={styles.listCell}>משקולת</p>
        <p className={styles.listCell}>אפשרויות</p>
      </li>
      {questionList}
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <DeleteConfirmation
            onDelete={handleDelete}
            text={`האם את/ה בטוח/ה שברצונך למחוק את השאלה: ${questionToDelete?.desc}?`}
            onClose={() => {
              setOpenModal(false);
              setQuestionToDelete(undefined);
            }}
          />
        </Modal>
      )}
      {openModalSuccess && (
        <Modal onClose={() => setOpenModalSuccess(false)}>
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModalSuccess(false);
            }}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
    </ul>
  );
}

export default React.memo(QuestionsList);
