import styles from "./appointmentsProgramSchedulePopup.module.css";
import {
  useDeleteAppointmentMutation,
  useGetAppointmentsProgramsScheduleMutation,
} from "../../../services/api/utils.api";
import {
  IAppointment,
  TAppointmentsProgramsData,
} from "../../../services/types/utils.types";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../services/hooks";
import {
  appointmentsProgramsDataSelector,
  appointmentsProgramsScheduleSelector,
} from "../../../services/selectors/utils.selectors";
import questionnaireStyles from "../../../pages/questionnaires/questionnaires.module.css";
import Modal from "../modal/Modal";
import DeleteConfirmation from "../deleteConfirmation/deleteConfirmation";

function AppointmentsProgramSchedulePopup({
  program,
}: {
  program: TAppointmentsProgramsData;
}) {
  const appointmentsProgramsSchedule = useAppSelector(
    appointmentsProgramsScheduleSelector
  );
  const appointmentsProgramsData = useAppSelector(
    appointmentsProgramsDataSelector
  );

  const [itemToDelete, setItemToDelete] = useState<IAppointment | undefined>(
    undefined
  );
  const [getSchedule] = useGetAppointmentsProgramsScheduleMutation();
  const [deleteAppointment] = useDeleteAppointmentMutation();

  useEffect(() => {
    getSchedule(program.appointmentsProgram);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <article className={styles.popup}>
      <h3 className={`title`}>מועדי התוכנית</h3>
      {appointmentsProgramsSchedule.length > 0 ? (
        <ul className={questionnaireStyles.list}>
          <li className={`${styles.listRow} ${styles.listRow_header}`}>
            <p className={questionnaireStyles.listCell}>מספר</p>
            <p className={questionnaireStyles.listCell}>תאריך מפגש</p>
            <p className={questionnaireStyles.listCell}>שעת התחלה</p>
            <p className={questionnaireStyles.listCell}>שעת סיום</p>
            <p className={questionnaireStyles.listCell}>סטטוס</p>
            <p className={questionnaireStyles.listCell}>אפשרויות</p>
          </li>
          {appointmentsProgramsSchedule.map((a) => (
            <li className={`${styles.listRow}`}>
              <p className={questionnaireStyles.listCell}>{a.id}</p>
              <p className={questionnaireStyles.listCell}>
                {a.appointmentDate?.split("T")[0] || "-"}
              </p>
              <p className={questionnaireStyles.listCell}>
                {a.startTime || "-"}
              </p>
              <p className={questionnaireStyles.listCell}>{a.endTime || "-"}</p>
              <p className={questionnaireStyles.listCell}>
                {appointmentsProgramsData.AppointmentsProgramsStatus.find(
                  (s) => s.Id === a.statusId
                )?.label || a.statusId}
              </p>
              <button
                type={"button"}
                className={`${styles.btn} hover`}
                onClick={() => setItemToDelete(a)}
              >
                בטל
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p className={"text"}>הפגישות טרם נקבעו</p>
      )}
      {itemToDelete?.appointmentDate && (
        <Modal onClose={() => setItemToDelete(undefined)}>
          <DeleteConfirmation
            text={`האם את/ה בטוח/ה שברצונך למחוק את המועד מספר ${itemToDelete.id}?`}
            onDelete={() => {
              const { appointmentsProgram } = program;
              deleteAppointment({
                ...appointmentsProgram,
                deleteType: 1,
                dateToDelete: itemToDelete?.appointmentDate,
              });
              setItemToDelete(undefined); //!add error/success popup
            }}
            onClose={() => setItemToDelete(undefined)}
          />
        </Modal>
      )}
    </article>
  );
}
export default AppointmentsProgramSchedulePopup;
