import addOrEditQuestionnaireCompaniesHeaderStyles from "./addOrEditQuestionnaireCompaniesHeadersForm.module.css";
import styles from "../forms.module.css";
import Select, { ActionMeta, SingleValue } from "react-select";
import {
  addOrEditQuestionnaireCompaniesHeadersInitialState,
  statusOptions,
  yesNoOptions,
} from "../../../utils/constants";
import { TLabeledItem, TQuestionnaireTypeOption } from "../../../types/types";
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IQuestionnaireCompaniesHeaders } from "../../../services/types/utils.types";
import { useAddOrEditQuestionnaireCompaniesHeadersMutation } from "../../../services/api/utils.api";
import Modal from "../../modals/modal/Modal";
import GeneralSuccessPopup from "../../modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../../modals/errorPopup/errorPopup";
import CloseButton from "../../closeButton/closeButton";

interface IAddOrEditQuestionnaireCompaniesHeaders {
  questionnaireCompaniesHeader: IQuestionnaireCompaniesHeaders | undefined;
  companyId: number;
  onClose: () => void;
}
function AddOrEditQuestionnaireCompaniesHeadersForm({
  questionnaireCompaniesHeader,
  companyId,
  onClose,
}: IAddOrEditQuestionnaireCompaniesHeaders) {
  const [form, setForm] = useState(
    addOrEditQuestionnaireCompaniesHeadersInitialState
  );
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [touched, setTouched] = useState(false);
  console.log(form);
  const [editQuestionnaireCompaniesHeaders, { isLoading }] =
    useAddOrEditQuestionnaireCompaniesHeadersMutation();

  // Update form state with values from questionnaire
  useEffect(() => {
    setForm((prevState) => ({
      ...prevState,
      ...questionnaireCompaniesHeader, // This will update all the corresponding fields
    }));
  }, [questionnaireCompaniesHeader]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTouched(true);
      const { id, value } = e.target;
      setForm((prev) => ({ ...prev, [id]: value }));
    },
    []
  );

  const onSelectChange = useCallback(
    (
      value: SingleValue<TLabeledItem | TQuestionnaireTypeOption>,
      { name }: ActionMeta<TLabeledItem>
    ) => {
      setTouched(true);
      if (name && value) {
        console.log(name, value);
        if (name === "isDefault") {
          setForm((prev) => ({ ...prev, [name]: value.value === "1" }));
        } else {
          setForm((prev) => ({ ...prev, [name]: value.value }));
        }
      }
    },
    []
  );

  const isFormValid = () => {
    // Check if required fields are filled
    return form.desc;
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    editQuestionnaireCompaniesHeaders({
      companyId: companyId,
      body: { ...form, companyId: companyId },
    })
      .then((res) => {
        if (res && "data" in res) {
          // @ts-ignore
          if (res.data?.success === "false") {
            console.log(res.data);
          }
          setOpenModalSuccess(true);
          setTouched(false);
        }
      })
      .catch((err) => {
        setOpenModalError(true);
        console.log(err);
      });
  };

  return (
    <section className={addOrEditQuestionnaireCompaniesHeaderStyles.section}>
      <CloseButton onClick={onClose} />
      <h3 className={`title`}>
        {questionnaireCompaniesHeader ? "לערוך פרוטוקול" : "ליצור פרוטוקול"}
      </h3>
      <form className={styles.form}>
        <ul
          className={`${styles.form__list} ${styles.form__list_type_threeColumns}`}
        >
          <li>
            <label htmlFor="desc" className={styles.form__label}>
              שם פרוטוקול
            </label>
            <input
              type="text"
              className={`${styles.form__input}`}
              name="desc"
              id="desc"
              value={form.desc}
              onChange={handleChange}
            />
          </li>
          <li>
            <label htmlFor="statusId" className={styles.form__label}>
              סטטוס
            </label>
            <Select
              name={"statusId"}
              options={statusOptions}
              value={
                statusOptions.find(
                  (o) => o.value === form?.statusId?.toString()
                ) || undefined
              }
              placeholder={"אנא בחרסוג סטטוס"}
              className={styles.select}
              onChange={onSelectChange}
            />
          </li>
          <li>
            <label htmlFor="isDefault" className={styles.form__label}>
              האם דפולטיבי
            </label>
            <Select
              name={"isDefault"}
              options={yesNoOptions}
              value={form.isDefault ? yesNoOptions[1] : yesNoOptions[0]}
              placeholder={"אנא בחר..."}
              className={styles.select}
              onChange={onSelectChange}
            />
          </li>
        </ul>
        <button
          type={"submit"}
          className={`button ${styles.form__btn} ${
            (isLoading || !touched || !isFormValid()) &&
            styles.form__btn_disabled
          } `}
          disabled={isLoading || !touched || !isFormValid()}
          onClick={onSubmit}
        >
          {isLoading
            ? "שליחה"
            : questionnaireCompaniesHeader
            ? "לערוך פרוטוקול"
            : "ליצור פרוטוקול"}
        </button>
        {openModalSuccess && (
          <Modal
            onClose={() => {
              setOpenModalSuccess(false);
              onClose();
            }}
          >
            <GeneralSuccessPopup
              onClose={() => {
                setOpenModalSuccess(false);
                onClose();
              }}
            />
          </Modal>
        )}
        {openModalError && (
          <Modal onClose={() => setOpenModalError(false)}>
            <ErrorPopup onClose={() => setOpenModalError(false)} />
          </Modal>
        )}
      </form>
    </section>
  );
}

export default AddOrEditQuestionnaireCompaniesHeadersForm;
