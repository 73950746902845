import styles from "../forms.module.css";
import Select from "react-select";
import {
  initialCompanyGroupsRRM,
  statusOptions,
} from "../../../utils/constants";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAddOrEditCompanyGroupsRRMMutation } from "../../../services/api/utils.api";
import { useAppSelector } from "../../../services/hooks";
import Modal from "../../modals/modal/Modal";
import GeneralSuccessPopup from "../../modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../../modals/errorPopup/errorPopup";
import { adminSelector } from "../../../services/selectors/user.selectors";
import { ICompanyGroupsRRM } from "../../../services/types/utils.types";
import CloseButton from "../../closeButton/closeButton";
import { companyGroupsRRMSelector } from "../../../services/selectors/utils.selectors";
import useAutosizeTextArea from "../../../hooks/useAutosizeTextarea";

function AddOrEditGroupsRRMForm({
  setShowGroupsRRMForm,
  rrmHeaderId,
  rrmGroupToEdit,
}: {
  setShowGroupsRRMForm: Dispatch<SetStateAction<boolean>>;
  rrmHeaderId: null | number;
  rrmGroupToEdit: null | ICompanyGroupsRRM;
}) {
  const admin = useAppSelector(adminSelector);
  const rrmGroups = useAppSelector(companyGroupsRRMSelector);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [form, setForm] = useState(initialCompanyGroupsRRM);
  const [touched, setTouched] = useState(false);
  console.log(form);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);

  const [addOrEditGroupsRRM, { isLoading }] =
    useAddOrEditCompanyGroupsRRMMutation();

  useEffect(() => {
    if (rrmGroupToEdit) {
      setForm((prev) => ({ ...prev, ...rrmGroupToEdit }));
    } else {
      setForm({ ...initialCompanyGroupsRRM, seq: rrmGroups.length + 1 });
    }
  }, [setForm, rrmGroupToEdit, rrmGroups.length]);

  useAutosizeTextArea(textAreaRef.current, form.anliza || "");

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTouched(true);
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  return (
    <form className={styles.form}>
      <CloseButton
        onClick={() => {
          setShowGroupsRRMForm(false);
        }}
      />
      <ul
        className={`${styles.form__list} ${styles.form__list_type_threeColumns} ${styles.form__list_type_lastLiWide}`}
      >
        <li>
          <label htmlFor="title" className={styles.form__label}>
            כותרת
          </label>
          <input
            type={"text"}
            className={`${styles.form__input}`}
            name="title"
            id="title"
            value={form.title || ""}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="desc" className={styles.form__label}>
            תיאור
          </label>
          <textarea
            className={`${styles.form__input}`}
            name="desc"
            id="desc"
            value={form.desc || ""}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="color" className={styles.form__label}>
            צבע
          </label>
          <input
            className={`${styles.form__input}`}
            type="color"
            name="color"
            id="color"
            value={form.color || "#ffffff"}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="statusId" className={styles.form__label}>
            סטטוס
          </label>
          <Select
            name={"statusId"}
            options={statusOptions}
            value={form.statusId === 1 ? statusOptions[0] : statusOptions[1]}
            placeholder={"אנא בחר סוג סטטוס"}
            className={styles.select}
            onChange={(value) => {
              value &&
                setForm((prev) => ({ ...prev, statusId: Number(value.value) }));
            }}
          />
        </li>
        <li>
          <label htmlFor="recommendations" className={styles.form__label}>
            המלצות
          </label>
          <textarea
            className={`${styles.form__input} ${styles.form__textArea}`}
            name="recommendations"
            id="recommendations"
            value={form.recommendations || ""}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="highlights" className={styles.form__label}>
            הדגשות
          </label>
          <textarea
            className={`${styles.form__input} ${styles.form__textArea}`}
            name="highlights"
            id="highlights"
            value={form.highlights || ""}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="conversation" className={styles.form__label}>
            שיחה
          </label>
          <textarea
            className={`${styles.form__input} ${styles.form__textArea}`}
            name="conversation"
            id="conversation"
            value={form.conversation || ""}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="start" className={styles.form__label}>
            התחלה
          </label>
          <input
            type={"number"}
            className={`${styles.form__input}`}
            name="start"
            id="start"
            value={form.start}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="end" className={styles.form__label}>
            סוף
          </label>
          <input
            type={"number"}
            className={`${styles.form__input}`}
            name="end"
            id="end"
            value={form.end}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="anliza" className={styles.form__label}>
            אנליזה
          </label>
          <textarea
            ref={textAreaRef}
            className={`${styles.form__input} ${styles.form__textArea}`}
            name="anliza"
            id="anliza"
            value={form.anliza || ""}
            onChange={handleChange}
          />
        </li>
      </ul>
      <button
        type={"submit"}
        className={`button button_white ${styles.form__btn} ${
          (isLoading || !touched || !form.title) && styles.form__btn_disabled
        } `}
        disabled={isLoading || !touched || !form.title}
        onClick={(e) => {
          e.preventDefault();
          addOrEditGroupsRRM({
            companyGroupsRRMHeadersId: Number(rrmHeaderId) || 0,
            RRMGroup: [
              {
                ...form,
                companyId: admin.companyId,
                companyGroupsRRMHeadersId: rrmHeaderId,
              },
            ],
          })
            .then((res) => {
              if (res && "data" in res) {
                setTouched(false);
                setOpenModalSuccess(true);
                setShowGroupsRRMForm(false);
              }
            })
            .catch((err) => {
              setOpenModalError(true);
              console.log(err);
            });
        }}
      >
        {isLoading ? "שליחה" : "שמור שינויים"}
      </button>
      {openModalSuccess && (
        <Modal
          onClose={() => {
            setOpenModalSuccess(false);
          }}
        >
          <GeneralSuccessPopup
            onClose={() => {
              setOpenModalSuccess(false);
            }}
          />
        </Modal>
      )}
      {openModalError && (
        <Modal onClose={() => setOpenModalError(false)}>
          <ErrorPopup onClose={() => setOpenModalError(false)} />
        </Modal>
      )}
    </form>
  );
}

export default AddOrEditGroupsRRMForm;
