import styles from "./addOrEditGroup.module.css";
import formStyles from "../forms.module.css";
import { questionGroupInitialState } from "../../../utils/constants";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useAddOrEditQuestionsGroupsMutation } from "../../../services/api/utils.api";
import { TGroup } from "../../../services/types/utils.types";

interface IAddOrEditGroupFormProps {
  id: string;
  setShowAddOrEditGroupForm: Dispatch<SetStateAction<boolean>>;
  setOpenModalSuccess: Dispatch<SetStateAction<boolean>>;
  setOpenErrorModal: Dispatch<SetStateAction<boolean>>;
  group: TGroup | undefined;
}
function AddOrEditGroupForm({
  id,
  setShowAddOrEditGroupForm,
  setOpenModalSuccess,
  setOpenErrorModal,
  group,
}: IAddOrEditGroupFormProps) {
  const [addOrEditOptionsGroups, { isLoading }] =
    useAddOrEditQuestionsGroupsMutation();

  const [form, setForm] = useState<TGroup>(questionGroupInitialState);

  useEffect(() => {
    if (group) {
      setForm((prev) => ({ ...prev, ...group }));
    } else {
      setForm(questionGroupInitialState);
    }
  }, [group]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setForm((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const addNewOptionGroup = () => {
    addOrEditOptionsGroups({
      questionsId: id,
      groups: [{ ...form, questionsId: Number(id) }],
    })
      .then((res) => {
        if ("data" in res) {
          setForm(questionGroupInitialState);
          setShowAddOrEditGroupForm(false);
          setOpenModalSuccess(true);
        }
      })
      .catch((err) => {
        setOpenErrorModal(true);
        console.log(err);
      });
  };

  return (
    <form className={styles.form}>
      <ul className={styles.form__list}>
        <li>
          <label htmlFor="name" className={styles.form__label}>
            שם קבוצה
          </label>
          <input
            type={"text"}
            className={`${formStyles.form__input}`}
            name="name"
            id="name"
            value={form.name}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="factorGroup" className={styles.form__label}>
            משקולת לציון RRM
          </label>
          <input
            type={"number"}
            className={`${formStyles.form__input}`}
            name="factorGroup"
            id="factorGroup"
            value={form.factorGroup}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="seq" className={styles.form__label}>
            סדר
          </label>
          <input
            type={"number"}
            className={`${formStyles.form__input}`}
            name="seq"
            id="seq"
            value={form.seq || 0}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="optionsCountMin" className={styles.form__label}>
            מינימום בחירה
          </label>
          <input
            type={"number"}
            className={`${formStyles.form__input}`}
            name="optionsCountMin"
            id="optionsCountMin"
            value={form.optionsCountMin || 0}
            onChange={handleChange}
          />
        </li>
        <li>
          <label htmlFor="optionsCountMax" className={styles.form__label}>
            מקסימום בחירה
          </label>
          <input
            type={"number"}
            className={`${formStyles.form__input}`}
            name="optionsCountMax"
            id="optionsCountMax"
            value={form.optionsCountMax || 0}
            onChange={handleChange}
          />
        </li>
      </ul>
      <button
        type={"submit"}
        className={`button ${styles.form__btn} ${
          (isLoading || !form.name) && styles.form__btn_disabled
        } `}
        disabled={isLoading || !form.name}
        onClick={(e) => {
          e.preventDefault();
          addNewOptionGroup();
        }}
      >
        {isLoading ? "שליחה" : group ? "ערוך קבוצה" : "ליצור קבוצה"}
      </button>
    </form>
  );
}

export default AddOrEditGroupForm;
