import styles from "./addOrEditQuestionnaireCompanies.module.css";
import AddOrEditQuestionnaireCompaniesForm from "../forms/addOrEditQuestionnaireCompanies/addOrEditQuestionnaireCompaniesForm";
import React, { useEffect, useState } from "react";
import {
  questionnaireCompaniesHeadersSelector,
  questionnaireCompaniesSelector,
  questionnaireCompaniesTypesSelector,
  questionnaireOptionsSelector,
} from "../../services/selectors/utils.selectors";
import { useAppSelector } from "../../services/hooks";
import questionnaireStyles from "../../pages/questionnaires/questionnaires.module.css";
import BackArrowButton from "../backArrowButton/backArrowButton";
import {
  useDeleteQuestionnaireCompaniesMutation,
  useGetQuestionnaireCompaniesMutation,
  useGetQuestionnaireCompaniesTypesQuery,
  useGetQuestionnairesMutation,
} from "../../services/api/utils.api";
import Loader from "../loader/loader";
import { noDataLabel } from "../../utils/constants";
import { IQuestionnaireCompanies } from "../../services/types/utils.types";
import Modal from "../modals/modal/Modal";
import DeleteConfirmation from "../modals/deleteConfirmation/deleteConfirmation";

interface IAddOrEditQuestionnaireCompanies {
  companyId: number;
  headerId: number;
  onClose: () => void;
}

function AddOrEditQuestionnaireCompanies({
  companyId,
  onClose,
  headerId,
}: IAddOrEditQuestionnaireCompanies) {
  const questionnaireCompanies = useAppSelector(questionnaireCompaniesSelector);
  const questionnaireCompaniesHeaders = useAppSelector(
    questionnaireCompaniesHeadersSelector
  );
  const questionnaireCompaniesTypes = useAppSelector(
    questionnaireCompaniesTypesSelector
  );
  const questionnaireOptions = useAppSelector(questionnaireOptionsSelector);

  const [getProtocols, { isLoading }] = useGetQuestionnaireCompaniesMutation();
  const [deleteProtocol] = useDeleteQuestionnaireCompaniesMutation();
  const [getQuestionnaires] = useGetQuestionnairesMutation();

  const [showForm, setShowForm] = useState(false);
  const [questionnaireCompanyToEdit, setQuestionnaireCompanyToEdit] = useState<
    IQuestionnaireCompanies | undefined
  >(undefined);
  const [questionnaireCompanyToDelete, setQuestionnaireCompanyToDelete] =
    useState<IQuestionnaireCompanies | undefined>(undefined);
  const [openModal, setOpenModal] = useState(false);

  useGetQuestionnaireCompaniesTypesQuery();

  useEffect(() => {
    getProtocols({ headerId });
  }, [getProtocols, headerId]);

  useEffect(() => {
    getQuestionnaires({ companyId });
  }, [getQuestionnaires, companyId]);

  if (isLoading) return <Loader />;
  return (
    <section className={styles.section}>
      <div className={styles.btn_container}>
        <button
          type={"button"}
          className={`${questionnaireStyles.addBtn}`}
          onClick={() => {
            setQuestionnaireCompanyToEdit(undefined);
            setShowForm(true);
          }}
        >
          הוסף רכיב
          <span className={questionnaireStyles.plus}>+</span>
        </button>
      </div>
      <h3 className={`title`}>רכיבים</h3>{" "}
      <BackArrowButton
        text={"למסך הקודם"}
        position={"center"}
        onClick={onClose}
      />
      {showForm ? (
        <AddOrEditQuestionnaireCompaniesForm
          headerId={headerId}
          companyId={companyId}
          questionnaireCompany={questionnaireCompanyToEdit}
          onClose={() => {
            setShowForm(false);
            setQuestionnaireCompanyToEdit(undefined);
          }}
        />
      ) : questionnaireCompanies.length > 0 ? (
        <ul className={styles.list}>
          <li className={`${styles.li} ${styles.li_header}`}>
            <span>מס'</span>
            <span>שם רכיב</span>
            <span>שאלון מטפל</span>
            <span>שאלון מטופל</span>
            <span>סוג רכיב</span>
            <span>תדירות</span>
            <span>סטטוס</span>
            <span>אפשרויות</span>
          </li>

          {questionnaireCompanies.map((c, index) => (
            <li
              key={c.id}
              className={`${styles.li} ${index % 2 === 0 && "colored"}`}
            >
              <span>{c.id}</span>
              <span>
                {questionnaireCompaniesHeaders.find(
                  (h) => h.id === c.questionnaireCompaniesHeadersId
                )?.desc || noDataLabel}
              </span>
              <span>
                {questionnaireOptions.find(
                  (q) => q.id === c.therapistQuestionnaireId
                )?.label || noDataLabel}
              </span>
              <span>
                {questionnaireOptions.find(
                  (q) => q.id === c.userQuestionnaireId
                )?.label || noDataLabel}
              </span>
              <span>
                {questionnaireCompaniesTypes.find(
                  (t) => t.value === c.questionnaireCompaniesTypeId
                )?.label || noDataLabel}
              </span>
              <span>{c.dayly || c.monthly || c.weekly}</span>
              <span>{c.statusId === 1 ? "פעיל" : "לא פעיל"}</span>
              <div className={questionnaireStyles.listCell}>
                <button
                  type={"button"}
                  className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_edit}`}
                  onClick={() => {
                    setQuestionnaireCompanyToEdit(c);
                    setShowForm(true);
                  }}
                />
                <button
                  type={"button"}
                  className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_delete}`}
                  onClick={() => {
                    setQuestionnaireCompanyToDelete(c);
                    setOpenModal(true);
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className={`text centered_item`}>לא נמצאו תוצאות</p>
      )}
      {openModal && questionnaireCompanyToDelete && (
        <Modal
          onClose={() => {
            setOpenModal(false);
            setQuestionnaireCompanyToDelete(undefined);
          }}
        >
          <DeleteConfirmation
            onClose={() => {
              setOpenModal(false);
              setQuestionnaireCompanyToDelete(undefined);
            }}
            onDelete={() => {
              deleteProtocol({
                headerId,
                questionnaireCompanies: questionnaireCompanyToDelete,
              });
            }}
            text={`האם את/ה בטוח/ה שברצונך למחוק את רכיב מספר ${questionnaireCompanyToDelete.id}?`}
          />
        </Modal>
      )}
    </section>
  );
}

export default AddOrEditQuestionnaireCompanies;
