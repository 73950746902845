import addOrEditQuestionnaireCompaniesHeaderStyles from "./addOrEditQuestionnaireCompaniesForm.module.css";
import styles from "../forms.module.css";
import Select, { ActionMeta, SingleValue } from "react-select";
import {
  addOrEditQuestionnaireCompaniesInitialState,
  dayOptions,
  monthOptions,
  statusOptions,
  weekOptions,
} from "../../../utils/constants";
import { INumberOption, TLabeledItem } from "../../../types/types";
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import {
  useAddOrEditQuestionnaireCompaniesMutation,
  useGetQuestionnairesMutation,
} from "../../../services/api/utils.api";
import Modal from "../../modals/modal/Modal";
import GeneralSuccessPopup from "../../modals/generalSuccessPopup/generalSuccessPopup";
import ErrorPopup from "../../modals/errorPopup/errorPopup";
import CloseButton from "../../closeButton/closeButton";
import { useAppSelector } from "../../../services/hooks";
import {
  questionnaireCompaniesTypesSelector,
  questionnaireOptionsSelector,
} from "../../../services/selectors/utils.selectors";
import Loader from "../../loader/loader";
import { IQuestionnaireCompanies } from "../../../services/types/utils.types";

interface IAddOrEditQuestionnaireCompanies {
  companyId: number;
  headerId: number;
  questionnaireCompany: undefined | IQuestionnaireCompanies;
  onClose: () => void;
}
function AddOrEditQuestionnaireCompaniesForm({
  companyId,
  questionnaireCompany,
  onClose,
  headerId,
}: IAddOrEditQuestionnaireCompanies) {
  const questionnaireOptions = useAppSelector(questionnaireOptionsSelector);
  const questionnaireCompaniesTypes = useAppSelector(
    questionnaireCompaniesTypesSelector
  );

  const [form, setForm] = useState(addOrEditQuestionnaireCompaniesInitialState);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [touched, setTouched] = useState(false);

  const [getQuestionnaires, { isLoading }] = useGetQuestionnairesMutation();
  const [editQuestionnaireCompanies, { isLoading: isSending }] =
    useAddOrEditQuestionnaireCompaniesMutation();

  useEffect(() => {
    getQuestionnaires({ companyId });
  }, [getQuestionnaires, companyId]);

  // Update form state with values from questionnaire
  useEffect(() => {
    if (questionnaireCompany) {
      setForm((prevState) => ({
        ...prevState,
        ...questionnaireCompany, // This will update all the corresponding fields
      }));
    } else {
      setForm(addOrEditQuestionnaireCompaniesInitialState);
    }
  }, [questionnaireCompany]);

  const onSelectChange = useCallback(
    (
      value: SingleValue<TLabeledItem | INumberOption>,
      { name }: ActionMeta<TLabeledItem | INumberOption>
    ) => {
      setTouched(true);
      if (name && value) {
        if (name === "questionnaireCompaniesTypeId") {
          // setPeriod(value.value);
          setForm((prev) => ({
            ...prev,
            monthly: undefined,
            weekly: undefined,
            dayly: undefined,
          }));
        }
        setForm((prev) => ({ ...prev, [name]: +value.value }));
      }
    },
    []
  );

  const isFormValid = () => {
    // Determine which fields are required based on `questionnaireCompaniesTypeId`
    const isDaylyRequired = form.questionnaireCompaniesTypeId === 4;
    const isWeeklyRequired = form.questionnaireCompaniesTypeId === 5;
    const isMonthlyRequired = form.questionnaireCompaniesTypeId === 6;
    const isQuestionnaireAdded =
      form.therapistQuestionnaireId || form.userQuestionnaireId;

    // Validate required fields
    return (
      !!isQuestionnaireAdded &&
      !!form.questionnaireCompaniesTypeId &&
      (!isDaylyRequired || form.dayly !== undefined) &&
      (!isWeeklyRequired || form.weekly !== undefined) &&
      (!isMonthlyRequired || form.monthly !== undefined)
    );
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    editQuestionnaireCompanies({
      headerId,
      body: {
        ...form,
        companyId: companyId,
        questionnaireCompaniesHeadersId: headerId,
      },
    })
      .then((res) => {
        if (res && "data" in res) {
          // @ts-ignore
          if (res.data?.success === "false") {
          }
          setOpenModalSuccess(true);
          setTouched(false);
        }
      })
      .catch((err) => {
        setOpenModalError(true);
        console.log(err);
      });
  };
  if (isLoading) return <Loader />;

  return (
    <section className={addOrEditQuestionnaireCompaniesHeaderStyles.section}>
      <CloseButton onClick={onClose} />
      <h3 className={`title`}>
        {questionnaireCompany ? "ערוך רכיב" : "הוסף רכיב"}
      </h3>
      <form className={styles.form}>
        <ul
          className={`${styles.form__list} ${styles.form__list_type_fourColumns}`}
        >
          <li>
            <label htmlFor="userQuestionnaireId" className={styles.form__label}>
              שאלון מטופל
            </label>
            <Select
              className={styles.select}
              name="userQuestionnaireId"
              id="userQuestionnaireId"
              value={questionnaireOptions.find(
                (o) => o.id === form.userQuestionnaireId
              )}
              options={questionnaireOptions}
              onChange={onSelectChange}
              placeholder={"אנא בחר שאלון מטופל"}
            />
          </li>
          <li>
            <label
              htmlFor="therapistQuestionnaireId"
              className={styles.form__label}
            >
              שאלון מטפל
            </label>
            <Select
              className={styles.select}
              name="therapistQuestionnaireId"
              id="therapistQuestionnaireId"
              options={questionnaireOptions}
              value={questionnaireOptions.find(
                (o) => o.id === form.therapistQuestionnaireId
              )}
              onChange={onSelectChange}
              placeholder={"אנא בחר שאלון מטפל"}
            />
          </li>
          <li>
            <label
              htmlFor="questionnaireCompaniesTypeId"
              className={styles.form__label}
            >
              סוג רכיב
            </label>
            <Select
              name={"questionnaireCompaniesTypeId"}
              options={questionnaireCompaniesTypes}
              value={questionnaireCompaniesTypes.find(
                (o) => o.value === form.questionnaireCompaniesTypeId
              )}
              placeholder={"אנא בחר סוג"}
              className={styles.select}
              onChange={onSelectChange}
            />
          </li>
          <li>
            <label htmlFor="statusId" className={styles.form__label}>
              סטטוס
            </label>
            <Select
              name={"statusId"}
              options={statusOptions}
              value={
                statusOptions.find(
                  (o) => o.value === form?.statusId?.toString()
                ) || undefined
              }
              placeholder={"אנא בחרסוג סטטוס"}
              className={styles.select}
              onChange={onSelectChange}
            />
          </li>
          {form.questionnaireCompaniesTypeId === 4 && (
            <li>
              <label htmlFor="dayly" className={styles.form__label}>
                יומי
              </label>
              <Select
                name={"dayly"}
                options={dayOptions}
                placeholder={"אנא בחר..."}
                className={styles.select}
                onChange={onSelectChange}
                value={
                  dayOptions.find((o) => o.value === form?.dayly?.toString()) ||
                  undefined
                }
              />
            </li>
          )}
          {form.questionnaireCompaniesTypeId === 5 && (
            <li>
              <label htmlFor="weekly" className={styles.form__label}>
                שבועי
              </label>
              <Select
                name={"weekly"}
                options={weekOptions}
                placeholder={"אנא בחר..."}
                className={styles.select}
                onChange={onSelectChange}
                value={
                  weekOptions.find(
                    (o) => o.value === form?.weekly?.toString()
                  ) || undefined
                }
              />
            </li>
          )}
          {form.questionnaireCompaniesTypeId === 6 && (
            <li>
              <label htmlFor="monthly" className={styles.form__label}>
                חודשי
              </label>
              <Select
                name={"monthly"}
                options={monthOptions}
                placeholder={"אנא בחר..."}
                className={styles.select}
                onChange={onSelectChange}
                value={
                  monthOptions.find(
                    (o) => o.value === form?.monthly?.toString()
                  ) || undefined
                }
              />
            </li>
          )}
        </ul>
        <button
          type={"submit"}
          className={`button ${styles.form__btn} ${
            (isSending || !touched || !isFormValid()) &&
            styles.form__btn_disabled
          } `}
          disabled={isSending || !touched || !isFormValid()}
          onClick={onSubmit}
        >
          {isSending ? "שליחה" : "שמירה"}
        </button>
        {openModalSuccess && (
          <Modal
            onClose={() => {
              setOpenModalSuccess(false);
              onClose();
            }}
          >
            <GeneralSuccessPopup
              onClose={() => {
                setOpenModalSuccess(false);
                onClose();
              }}
            />
          </Modal>
        )}
        {openModalError && (
          <Modal onClose={() => setOpenModalError(false)}>
            <ErrorPopup onClose={() => setOpenModalError(false)} />
          </Modal>
        )}
      </form>
    </section>
  );
}

export default AddOrEditQuestionnaireCompaniesForm;
