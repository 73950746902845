import React, { Dispatch, SetStateAction } from "react";
import { TGroup, TQuestionOption } from "../../services/types/utils.types";
import { useDrag, useDrop } from "react-dnd";
import styles from "../../pages/addOrEditOptions/addOrEditOptions.module.css";
import { ITEM_TYPE } from "../../utils/constants";
import questionnaireStyles from "../../pages/questionnaires/questionnaires.module.css";

interface DraggableGroupProps {
  group: TGroup;
  moveGroup: (draggedGroup: TGroup, targetGroup: TGroup) => void;
  updateGroup: (
    draggedOption: TQuestionOption,
    newGroupId: number | null
  ) => void;
  setShowAddOrEditGroupForm: Dispatch<SetStateAction<boolean>>;
  setGroupToEdit: Dispatch<SetStateAction<TGroup | undefined>>;
  children: React.ReactNode;
}
function DraggableGroup({
  group,
  moveGroup,
  children,
  updateGroup,
  setShowAddOrEditGroupForm,
  setGroupToEdit,
}: DraggableGroupProps) {
  // const [groupToDelete, setGroupToDelete] = useState<TGroup | undefined>(
  //   undefined
  // );
  // const [openModal, setOpenModal] = useState(false);
  // const [openModalSuccess, setOpenModalSuccess] = useState(false);
  // const [openModalError, setOpenModalError] = useState(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ITEM_TYPE.GROUP,
    item: { group },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop({
    accept: [ITEM_TYPE.GROUP, ITEM_TYPE.OPTION], // Accept both groups and options
    hover: (draggedItem: { group?: TGroup; option?: TQuestionOption }) => {
      if (draggedItem.group && draggedItem.group.id !== group.id) {
        moveGroup(draggedItem.group, group);
      } else if (draggedItem.option) {
        // Move option to the current group
        updateGroup(draggedItem.option, group.id);
      }
    },
  });

  // const onDelete = () => {
  //   setOpenModal(true);
  //   console.log(group);
  // };

  // const [deleteGroup] = useDeleteQuestionsGroupsMutation();

  // const handleDelete = useCallback(() => {
  //   if (group) {
  //     deleteGroup({
  //       questionsId: group.questionsId,
  //       groupId: group.id,
  //     })
  //       .then((res) => {
  //         if (res && "data" in res) {
  //           setOpenModalSuccess(true);
  //         }
  //       })
  //       .catch(() => setOpenModalError(true));
  //   }
  // }, [deleteGroup, group]);

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div className={styles.group}>
        {group.id}: {group.name}, משקולת {group.factorGroup}
        <button
          type={"button"}
          className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_edit_grey}`}
          onClick={() => {
            setGroupToEdit(group);
            setShowAddOrEditGroupForm(true);
          }}
        />
        {/*<button*/}
        {/*  type="button"*/}
        {/*  className={`${questionnaireStyles.btn} ${questionnaireStyles.btn_type_delete}`}*/}
        {/*  onClick={onDelete}*/}
        {/*/>*/}
      </div>
      {children}

      {/*{openModal && (*/}
      {/*  <Modal onClose={() => setOpenModal(false)}>*/}
      {/*    <DeleteConfirmation*/}
      {/*      onDelete={handleDelete}*/}
      {/*      text={`האם את/ה בטוח/ה שברצונך למחוק את הקבוצה ${group.name}?`}*/}
      {/*      onClose={() => {*/}
      {/*        setOpenModal(false);*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </Modal>*/}
      {/*)}*/}
      {/*{openModalSuccess && (*/}
      {/*  <Modal onClose={() => setOpenModalSuccess(false)}>*/}
      {/*    <GeneralSuccessPopup*/}
      {/*      onClose={() => {*/}
      {/*        setOpenModalSuccess(false);*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </Modal>*/}
      {/*)}*/}
      {/*{openModalError && (*/}
      {/*  <Modal onClose={() => setOpenModalError(false)}>*/}
      {/*    <ErrorPopup onClose={() => setOpenModalError(false)} />*/}
      {/*  </Modal>*/}
      {/*)}*/}
    </div>
  );
}

export default DraggableGroup;
