import { IUserResponse } from "../types/auth.types";
import { api } from "./api";
import {
  IGenderOption,
  TAddMessageRequest,
  TAllUserQuestionnairesData,
  TGetMultipleQuestionnairesByUserIdAndCycleRequest,
  TGetMultipleQuestionnairesByUserIdAndCycleResponse,
  TGetUsersByTherapistIdResponse,
  TTakeUserRequest,
  TGetAllQuestionsAndOptionsResponse,
  TInsertAllAnswersRequest,
  TAddTherapistRequest,
} from "../types/user.types";
import {
  IGetAllCombosForSearchUsersResponse,
  IGetUserResponse,
  IGetUsersByCompanyIdWithRecommendsResponse,
  TGetUsersRequest,
} from "../types/users.types";

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<IUserResponse, { skip: boolean }>({
      query: () => ({
        url: `/user`,
        method: "GET",
      }),
    }),
    getAllUsers: build.mutation<
      { Users: IGetUserResponse[] },
      { companyId: number; id?: string | number }
    >({
      query: (body) => ({
        url: `user/Users_DML/1`,
        method: "POST",
        body,
      }),
    }),
    // getAllUsersWithRecommendations: build.query<{ Users: TBaseUser[] }, number>(
    //   {
    //     query: (companyId) => ({
    //       url: `/user/GetUsersByCompanyIdWithRecommends/${companyId}`,
    //       method: "Get",
    //     }),
    //   }
    // ),
    // getUsersByTherapistId: build.query<TGetUsersByTherapistIdResponse, string>({
    //   query: (TherapistsId) => ({
    //     url: `Therapists/Therapist_GetALLUsers/${TherapistsId}`,
    //     method: "GET",
    //   }),
    // }),
    // getUsersByCompanyId: build.query<{ Users: TBaseUser[] }, number | string>({
    //   query: (companyId) => ({
    //     url: `user/GetUsersByCompanyIdWithRecommends/${companyId}`,
    //     method: "GET",
    //   }),
    // }),
    // getTherapistsByCompanyId: build.query<TBaseUser[], number | string>({
    //   query: (companyId) => ({
    //     url: `user/GetTherapistsByCompanyId/${companyId}`,
    //     method: "GET",
    //   }),
    // }),
    takeUser: build.mutation<TGetUsersByTherapistIdResponse, TTakeUserRequest>({
      query: (body) => ({
        url: `Therapists/Therapist_SetUser`,
        method: "POST",
        body,
      }),
    }),
    addTherapist: build.mutation<
      TGetUsersByTherapistIdResponse,
      { body: TAddTherapistRequest; companyId: number | string }
    >({
      query: ({ body, companyId }) => ({
        url: `Admintech/Admin_Users_DML/2/${companyId}`,
        method: "POST",
        body,
      }),
    }),
    getAllQuestionnairesByUserId: build.query<
      TAllUserQuestionnairesData,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `Questions/GetQuestionnairesByUsersId/${id}`,
        method: "GET",
      }),
    }),
    addMessageToScore: build.mutation<boolean, TAddMessageRequest>({
      query: (answers) => ({
        url: `/Score/Score_AddMessageToScore`,
        method: "POST",
        body: JSON.stringify(answers),
        responseHandler: "text",
      }),
    }),
    getMultipleQuestionnairesByUserIdAndCycle: build.query<
      TGetMultipleQuestionnairesByUserIdAndCycleResponse,
      TGetMultipleQuestionnairesByUserIdAndCycleRequest
    >({
      query: ({ UsersId, Cycle, QuestionnairesIds }) => ({
        url: `Questions/GetMultiplesReportsByUserId/${QuestionnairesIds}/${UsersId}/${Cycle}`,
        method: "GET",
      }),
    }),
    getRRMData: build.query<
      TGetMultipleQuestionnairesByUserIdAndCycleResponse,
      void
    >({
      query: () => ({
        url: `Questions/GetMultiplesReportsByUserId`,
        method: "GET",
      }),
    }),
    getReportQuestionsAndOptionsByQuestionnaireId: build.query<
      TGetAllQuestionsAndOptionsResponse,
      number
    >({
      query: (questionnaireId) => ({
        url: `Questions/GetAllQuestionsAndOptionsByQuestionnaireId/${questionnaireId}`,
        method: "GET",
      }),
    }),
    insertAllAnswersQuestions: build.mutation<
      boolean,
      {
        answers: TInsertAllAnswersRequest[];
        companyId: number;
        questionnaireCompaniesId?: number;
      }
    >({
      query: ({ answers, companyId, questionnaireCompaniesId = 1 }) => ({
        url: `/Questions/InsertAllAnswersQuestions/${companyId}`,
        method: "POST",
        body: JSON.stringify(answers),
      }),
      invalidatesTags: ["user"],
    }),
    getAllGenders: build.query<IGenderOption[], void>({
      query: () => ({
        url: `user/getAllGenders`,
        method: "GET",
      }),
    }),
    getAllCombosForSearchUsers: build.query<
      IGetAllCombosForSearchUsersResponse,
      number | string
    >({
      query: (companyId) => ({
        url: `user/GetAllCombosForSearchUsers/${companyId}`,
        method: "GET",
      }),
    }),
    getUsersByCompanyIdWithRecommends: build.query<
      IGetUsersByCompanyIdWithRecommendsResponse,
      TGetUsersRequest
    >({
      query: ({
        companyId,
        fakeCompanyId = 0,
        roleId = 0,
        recommendsId = 0,
        startDate = 0,
        endDate = 0,
      }) => ({
        url: `user/GetUsersByCompanyIdWithRecommendsNew/${companyId}/${fakeCompanyId}/${roleId}/${recommendsId}/${startDate}/${endDate}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetUserQuery,
  useGetUserQuery,
  useGetAllUsersMutation,
  // useLazyGetAllUsersWithRecommendationsQuery,
  // useLazyGetUsersByTherapistIdQuery,
  useTakeUserMutation,
  useGetAllGendersQuery,
  useLazyGetAllQuestionnairesByUserIdQuery,
  useAddMessageToScoreMutation,
  useGetAllQuestionnairesByUserIdQuery,
  useLazyGetMultipleQuestionnairesByUserIdAndCycleQuery,
  // useLazyGetUsersByCompanyIdQuery,
  // useGetTherapistsByCompanyIdQuery,
  // useLazyGetTherapistsByCompanyIdQuery,
  useGetReportQuestionsAndOptionsByQuestionnaireIdQuery,
  useInsertAllAnswersQuestionsMutation,
  useLazyGetAllCombosForSearchUsersQuery,
  useLazyGetUsersByCompanyIdWithRecommendsQuery,
  useAddTherapistMutation,
} = userApi;
