import styles from "./usersList.module.css";
import React, { useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import Modal from "../modals/modal/Modal";
import PrivacyProtectionPopup from "../modals/privacyProtectionPopup/privacyProtectionPopup";
import { IUserReport } from "../../services/types/users.types";

function UsersList({
  userList,
  usersCategory,
}: {
  userList: IUserReport[];
  usersCategory: string | undefined;
}) {
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 800px)");
  console.log(usersCategory);
  const [id, setId] = useState<number | undefined>(undefined);
  const [openPrivacyPopup, setOpenPrivacyPopup] = useState(false);

  const proceed = () => navigate(`${id}/actions`);

  return (
    <>
      <div className={`${styles.user} ${styles.usersListHeader}`}>
        {usersCategory === "client" && (
          <p className={styles.usersListTitle}>פעולות</p>
        )}
        <p className={styles.usersListTitle}>מס'</p>
        <p className={styles.usersListTitle}>שם</p>
        <p className={styles.usersListTitle}>טלפון</p>
        <p className={styles.usersListTitle}>סה"כ דוחות</p>
        <p className={styles.usersListTitle}>המלצה</p>
      </div>

      <ul className={`${styles.usersList} scroll`}>
        {userList.map((u: IUserReport, index: number) =>
          !mobile ? (
            <li
              key={index}
              className={`${styles.user} ${
                index % 2 === 0 && styles.user_colored
              }`}
            >
              {usersCategory === "client" && (
                <p className={styles.usersListCell}>
                  <button
                    type={"button"}
                    className={`${styles.btn} ${styles.btn_type_actions}`}
                    onClick={() => {
                      setId(u.Id);
                      setOpenPrivacyPopup(true);
                    }}
                  />
                </p>
              )}
              <p className={styles.usersListCell}>{u.Id}</p>
              <p className={styles.usersListCell}>
                {u.FirstName} {u.LastName || ""}
              </p>
              <p className={styles.usersListCell}>{u.Phone || "-"}</p>
              <p className={styles.usersListCell}>{u.TotalReports || "-"}</p>
              <p className={`${styles.usersListCell} ${styles.recommendation}`}>
                {u.Recommends || "-"}
              </p>
            </li>
          ) : (
            <li key={index} className={styles.user_mobile}>
              <p className={styles.userName}>
                <span className={styles.userNameLabel}> שם משתמש:</span>
                {u.FirstName} {u.LastName || ""}
              </p>
              {usersCategory === "client" && (
                <p className={styles.usersListCell}>
                  <button
                    className={`${styles.btn} ${styles.btn_type_actions}`}
                    onClick={() => {
                      setId(u.Id);
                      setOpenPrivacyPopup(true);
                    }}
                  />
                </p>
              )}
            </li>
          )
        )}
      </ul>
      {openPrivacyPopup && (
        <Modal
          onClose={() => {
            setOpenPrivacyPopup(false);
            setId(undefined);
          }}
        >
          <PrivacyProtectionPopup onClose={proceed} />
        </Modal>
      )}
    </>
  );
}

export default UsersList;
