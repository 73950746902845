import { RootState } from "../types";

export const questionnairesSelector = (state: RootState) =>
  state.utils.questionnaires;
export const questionsSelector = (state: RootState) => state.utils.questions;
export const nextQuestionOptionsSelector = (state: RootState) =>
  state.utils.nextQuestionOptions;
export const questionsSortedByQuestionnaireSelector = (state: RootState) =>
  state.utils.questionsSortedByQuestionnaire;
export const dataTypeSelector = (state: RootState) => state.utils.dataType;
export const questionOptionsSelector = (state: RootState) =>
  state.utils.questionOptions;
export const questionnaireOptionsSelector = (state: RootState) =>
  state.utils.questionnairesOptions;
export const questionOptionsGrouppedSelector = (state: RootState) =>
  state.utils.questionOptionsGroupped;
export const companyGroupsRRMHeadersSelector = (state: RootState) =>
  state.utils.companyGroupsRRMHeaders;
export const companyGroupsRRMSelector = (state: RootState) =>
  state.utils.companyGroupsRRM;
export const featuresSelector = (state: RootState) => state.utils.features;
export const groupsSelector = (state: RootState) => state.utils.groups;
export const exercisesSelector = (state: RootState) => state.utils.exercises;
export const exercisesCategoriesSelectOptionsSelector = (state: RootState) =>
  state.utils.exercisesCategoriesSelectOptions;
export const exercisesCategoriesSelector = (state: RootState) =>
  state.utils.exercisesCategories;
export const questionnaireCompaniesHeadersSelector = (state: RootState) =>
  state.utils.questionnaireCompaniesHeaders;
export const questionnaireCompaniesSelector = (state: RootState) =>
  state.utils.questionnaireCompanies;
export const questionnaireCompaniesTypesSelector = (state: RootState) =>
  state.utils.questionnaireCompaniesTypes;
export const appointmentsProgramsDataSelector = (state: RootState) =>
  state.utils.appointmentsProgramsData;
export const appointmentsProgramsTherapistSelector = (state: RootState) =>
  state.utils.appointmentsProgramsTherapist;
export const userAppointmentsProgramsSelector = (state: RootState) =>
  state.utils.userAppointmentsPrograms;
export const companyAppointmentsProgramsSelector = (state: RootState) =>
  state.utils.companyAppointmentsPrograms;
export const appointmentsProgramsScheduleSelector = (state: RootState) =>
  state.utils.appointmentsProgramsSchedule;
export const companyGeneralRRMSelector = (state: RootState) =>
  state.utils.companyGeneralRRM;
