import { ReactElement } from "react";
import { useLocation, Navigate } from "react-router-dom";

interface IProtectedRoot {
  isAuth: boolean;
  children: ReactElement;
  redirectPath?: string;
}
function ProtectedRoute({
  isAuth,
  children,
  redirectPath = "/login",
}: IProtectedRoot): ReactElement {
  const location = useLocation();
  return isAuth ? (
    children
  ) : (
    <Navigate to={redirectPath} state={{ from: location.pathname }} replace />
  );
}

export default ProtectedRoute;
